.popup {
  &--disabled {
    visibility: hidden;
  }

  &__component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1300;
    background-color: rgba($color-brand-black, 0.5);
  }

  &__container {
    background-color: $color-brand-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 3em;
    padding-left: 6em;
    width: 90%;
    max-width: px-to-em(600px);
    color: $color-black-background;
    font-family: $font-family-primary;
  }

  &__header-container {
    position: relative;
  }

  &__header {
    line-height: 1.1;
    font-size: 1.75em;
    @include font-univers-condensed-bold();
  }

  &__message {
    margin-top: 1em;
    font-size: 1.25em;

    a {
      text-decoration: underline;
      color: $color-brand-red;

      &:hover {
        color: $color-gray-steel;
      }
    }
  }

  &__close {
    position: absolute;
    right: 1em;
    top: 1em;
    color: $color-brand-red;
    transition: color $transition-timing;

    &:hover {
      color: $color-gray-steel;
    }

    @include ico-icon-before($icon-cross) {
      font-size: px-to-em(22px);
      line-height: 1;
    }
  }

  @include screen($bp-tablet) {
    &__container {
      padding: 2.5em;
      padding-left: 4em;
    }

    &__header {
      font-size: 1.75em;
    }

    &__message {
      font-size: 1em;
    }
  }

  @include screen($bp-mobile) {
    &__container {
      padding: 2em;
      padding-left: 3em;
    }

    &__header {
      font-size: 1.5em;
    }

    &__message {
      font-size: 1em;
    }
  }
}
