$select-dropdown-padding: $form-input-padding;
.select-dropdown {
  position: relative;
  // display: inline-block;
}

.select-dropdown-select {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: transparent;
  cursor: pointer;
  border: 0;

  &:not(.affinity-select) {
    &:focus {
      opacity: 1;
      & + .select-dropdown-toggle {
        color: $color-brand-white;

        &:after {
          opacity: 0;
        }
      }
    }
  }

  &.affinity-select {
    option {
      display: none !important;
    }
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
    }

    + .select-dropdown-toggle {
      &:after {
        content: unset;
      }
    } 
  }
}

.select-dropdown-toggle {
  @include font-univers-condensed-bold($form-input-font-size);

  //position: relative;
  //z-index: 2;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
  color: $color-gray-steel;
  padding: px-to-em($select-dropdown-padding, $form-input-font-size);
  padding-right: $arrow-padding * 1.25;
  border: $border-input;
  background-color: $color-brand-white;

  &.darker {
    color: $color-gray-ac;
  }

  @include transition(background-color);

  &:hover {
    color: $color-link-hover;

    &:after {
      color: $color-link-hover;
    }
  }

  &.disabled {
    background-color: $color-gray-med;
  }

  @include ico-icon-after($icon-chevron-down-alt) {
    @include center(vertical);

    font-size: px-to-em($arrow-down-icon-size, $form-input-font-size);
    right: px-to-em($arrow-listing-offset, $arrow-down-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease;
  }
}

.select-dropdown-label {
  @include font-univers-condensed-bold($form-label-font-size);

  display: inline-block;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: $element-spacing-small;
}

@include screen($bp-mobile) {
  .select-dropdown-toggle {
    font-size: $form-input-font-size-mobile;
  }
}
