﻿.standard-form {
  form.form {
    opacity: 1;
    transition: opacity $transition-timing ease-in-out;
  }

  .form-response {
    opacity: 0;
    transition: opacity $transition-timing ease-in-out;
  }

  &.form-submitted {
    .no-introduction-spacing {
      padding-top: 0;
    }

    .foundation-contact-us-intro,
    .page-layout-spacing {
      display: none;
    }

    form.form {
      opacity: 0;
      visibility: hidden;
      display: none;
    }

    .form-response {
      opacity: 1;
      text-align: center;
      font-size: 4rem;
      height: auto;
      margin: 4rem;

      @include screen($bp-mobile) {
        font-size: 2rem;
        margin: 2rem 1rem;
      }

      .form-submission-error {
        color: $color-brand-red;
      }
    }

    .job-employment-policy {
      display: none;
    }
  }

  /*
	@include ico-icon-after($icon-chevron-down-alt) {
        @include center(vertical);

        font-size: px-to-em($arrow-down-icon-size, $form-input-font-size);
        right: px-to-em($arrow-listing-offset, $arrow-down-icon-size);
        color: $color-link;

        transition: color $transition-timing $transition-ease;
    }
	*/

  .form-field {
    input,
    select,
    textarea,
    button {
      &:focus {
        box-shadow: 0 0 1px $color-brand-turquoise;
        border: 1px solid $color-brand-turquoise;
      }
    }
  }

  .file-input-container {
    &:focus {
      .form-label {
        box-shadow: 0 0 1px $color-brand-turquoise;
        border: 1px solid $color-brand-turquoise;
      }

      outline: 0;
    }
  }
}
