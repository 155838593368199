$featured-topic-img-offset: px-to-em(28px);
$featured-topic-img-height-tablet: px-to-em(330px);

.featured-topic-slide {
  //	@include font-univers-condensed-bold($font-size);

  color: $color-brand-white;
  position: static;
}

//typography
.featured-topic-slide-topic {
  $font-heading-size: 31.5px;

  @include font-univers-condensed-bold($font-heading-size);
  line-height: 1.2;
  padding-right: 2em; //padding for arrows

  @include design-mark(13px, $font-heading-size, $color-gray-alt);

  &:after {
    display: table;
  }
}

.featured-topic-slide-abstract {
  max-width: px-to-em(745px, $font-size-copy);
  line-height: 1.5;
  margin-top: 0.25em;

  &.rte a {
    color: $color-red-on-black-foreground;
  }
}

.featured-topic-slide-articles {
  margin-top: $section-spacing-small;

  > li {
    margin-top: $section-spacing-small;
  }
}

.featured-topic-slide-content {
  padding-top: $featured-topic-img-offset + $element-spacing;
}

.featured-topic-slide-article-link {
  @include font-size-entity-title;

  color: $color-link;

  &:hover {
    color: $color-text-gray-soft;
  }
}

div.featured-topic-slide-article-link {
  &:hover {
    color: $color-link;
  }
}

//structure
.featured-topic-slide-figure {
  float: left;
  max-width: 40%;
  max-height: px-to-em(525px);
  margin-right: $element-spacing;
  overflow: hidden;
  position: relative;
}

.featured-topic-slide-landscape {
  @include center(horizontal, relative);

  height: 100%;
}

.featured-topic-slide-content {
  display: table;
}

@include screen($bp-desktop) {
  .featured-topic-slide-content {
    display: block;
  }

  .featured-topic-slide-articles {
    display: inline-block;
    margin-top: 0;
  }
}

@include screen($bp-featured-carousel-tablet) {
  .featured-topic-slide {
    .page-wrapper {
      padding: 0;
    }
  }

  .featured-topic-slide-figure {
    float: none;
    max-width: none;
    height: $featured-topic-img-height-tablet;
    margin-right: 0;

    &:after {
      @include gradient($color-nero-transparent, $color-nero, 0deg, 100%);

      background-color: transparent;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 20%;
    }
  }

  .featured-topic-slide-landscape {
    @include center(both, relative);
    min-width: 100%;
    height: auto;
  }

  .featured-topic-slide-content {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }
}

@include screen($bp-mobile) {
  //typography
  .featured-topic-slide-topic {
    $font-heading-size: 32px;

    @include font-univers-condensed-bold($font-heading-size);
    @include design-mark(15px, $font-heading-size, $color-gray-alt);

    padding-right: 0; //padding for arrows
  }
}
