.flex-panel {
  margin-bottom: $section-spacing-large;
}

.flex-panel-highlight,
.flex-panel-listing-files,
.flex-panel-related-news,
.flex-panel-read-more {
  margin-bottom: $section-spacing;
}

.flex-panel-intro,
.flex-panel-copy {
  margin-bottom: $section-spacing;
}

.flex-panel-view-more {
  margin: $section-spacing auto 0;
  display: block;
}

.flex-panel-banners {
  margin-bottom: $section-spacing;

  .content-banner,
  .personnel-banner {
    & + .content-banner,
    & + .personnel-banner {
      margin-top: $section-spacing;
    }
  }
}

.flex-panel-cta-link {
  @include font-size-banner();

  position: relative;
  display: block;
  padding-right: $arrow-padding;

  &:hover {
    color: $color-link-hover;

    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    @include transition(color);

    font-size: px-to-em($arrow-icon-size, $font-size-banner);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }
}

@include screen($bp-mobile) {
  .flex-panel {
    margin-bottom: $section-spacing-large-mobile;
  }

  .flex-panel-intro,
  .flex-panel-copy {
    margin-bottom: $section-spacing-mobile;
  }

  .flex-panel-banners {
    margin-bottom: $section-spacing-mobile;

    .content-banner,
    .personnel-banner {
      & + .content-banner,
      & + .personnel-banner {
        margin-top: $section-spacing-mobile;
      }
    }
  }

  .flex-panel-highlight,
  .flex-panel-listing-files,
  .flex-panel-read-more {
    margin-bottom: $section-spacing-mobile;
  }
}
