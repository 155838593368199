.fellows-listing {
  margin-bottom: $section-spacing-large;

  .fellows-card {
    margin-bottom: $element-spacing;
  }
}

@include screen($bp-mobile) {
  .fellows-listing {
    margin-bottom: $section-spacing-large-mobile;
  }
}
