.tile-event-listing {
  $grid-spacing: px-to-em(20px);

  &__cell {
    max-width: px-to-em(340px);
    margin-left: auto;
    margin-right: auto;
    border-top: thin solid $color-site-header-border;
  }

  @include screen($bp-mobile-landscape, min) {
    display: flex;
    flex-wrap: wrap;
    margin-left: $grid-spacing * -0.5;
    margin-right: $grid-spacing * -0.5;

    &__cell {
      width: calc(50% - #{$grid-spacing});
      display: flex;
      max-width: none;
      margin-left: $grid-spacing * 0.5;
      margin-right: $grid-spacing * 0.5;

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}
