.probono-hero {
  @include bg-cover;

  background-color: $color-brand-white;
  background-position: center bottom;
  padding: $section-spacing-large 0 $section-spacing-largest;
  min-height: 85vh;

  overflow: hidden;
  position: relative;
  text-align: center;

  > .page-wrapper {
    position: relative;
    z-index: 5;
  }
}

.probono-hero-header {
  text-align: center;
}

.probono-hero-title {
  @include font-univers-condensed-light($font-size-heading);

  text-align: center;
  line-height: 1.1;
  display: inline-block;
  border-bottom: $border-dark;
  padding-bottom: px-to-em(13px, $font-size-heading);
  margin-bottom: px-to-em($section-spacing-px, $font-size-heading);
}

.probono-hero-lead {
  background-color: $color-brand-white;
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.4;

  a {
    color: $color-link;
    @include font-univers-condensed-bold();

    &:hover {
      color: $color-link-hover;
    }
  }
}

.probono-hero-cta {
  @include font-univers-condensed-bold($font-size-copy);

  line-height: 1.1;
  display: inline-block;
  background-color: $color-brand-white;
  color: $color-brand-red;
  border: 1px solid $color-gray-steel;
  min-width: px-to-em(440px);
  padding: px-to-em(13px) px-to-em(26px);
  margin-top: 10vh;
  transition: color $transition-timing $transition-ease,
    background-color $transition-timing $transition-ease;

  &:hover {
    color: $color-brand-white;
    background-color: $color-brand-red;
  }
}

.probono-hero-grass {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
}

@include screen-height(790px) {
  .probono-hero-lead {
    background-color: $color-brand-white;
  }
}

@include screen($bp-mobile) {
  .probono-hero {
    padding: $section-spacing-large-mobile 0;

    min-height: calc(100vh - #{$site-header-height-mobile});
    background-color: $color-brand-white;
    background-size: auto 70%;
  }

  .probono-hero-title {
    font-size: px-to-em($font-size-heading-mobile);
    padding-bottom: px-to-em(15px, $font-size-heading-mobile);
    margin-bottom: px-to-em($section-spacing-px, $font-size-heading-mobile);
  }

  .probono-hero-lead {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .probono-hero-cta {
    min-width: inherit;
  }
}
