.alumni-listing {
  margin-bottom: $section-spacing-large;

  .alumni-card {
    margin-bottom: $element-spacing;
  }

  ng-repeat {
    display: block;
    margin-bottom: $element-spacing;
  }
}

@include screen($bp-mobile) {
  .alumni-listing {
    margin-bottom: $section-spacing-large-mobile;
  }
}
