$border-input: 1px solid $color-border;
$font-size-form-label: 17px;
$form-input-padding: 17.5px;
$form-input-font-size: 21px;
$form-label-font-size: 16px;

$form-input-font-size-mobile: 20px;

fieldset {
  border: 0 none;
  padding: 0;
}

input,
.input,
textarea {
  border: $border-input;
}

.form-base-wrapper {
  padding-top: $section-spacing-large;
  padding-bottom: $section-spacing-large;
}

.form-base-overview {
  margin-bottom: $section-spacing;
}

.form-field {
  margin-bottom: $element-spacing;

  input,
  .input {
    @include font-univers-condensed-bold($form-input-font-size);

    line-height: 1.1;
    width: 100%;
    border: 1px solid $color-brand-turquoise;
    padding: px-to-em($form-input-padding, $form-input-font-size);
    color: $color-gray-steel;

    @include placeholder {
      color: $color-gray-steel;
      opacity: 1;
    }

    .invalid {
      border-color: red;

      @include placeholder {
        color: red;
      }
    }

    &.foundation-input {
      width: auto;
    }
  }

  .input-radio {
    width: auto;
    margin-top: 2px;
    margin-right: 4px;
    vertical-align: top;
  }
}

.form--invalid-message {
  display: block;
  @include font-univers-condensed-bold($form-label-font-size);
  color: $color-brand-red;
  text-transform: uppercase;
  margin-bottom: 1em;
  outline: none;
  &:focus {
    box-shadow: 0 0 1px #2bb3d5;
    border: 1px solid #2bb3d5;
  }
  &.more-margin {
    margin-top: 1em;
  }
}

.form-field--radio-buttons {
  input[type="radio"] {
    width: auto;
  }
}

.form-label,
.field-label {
  @include font-univers-condensed-bold($form-label-font-size);

  display: inline-block;
  line-height: 1.2;
  margin-bottom: $element-spacing-small;
  text-transform: uppercase;

  &.applied-filter-remove {
    float: right;
    color: $color-brand-black;
  }

  &.checkbox-required-label {
    margin-top: $element-spacing-small;
    display: block;
    color: red;
  }
}

.form-label--rte {
  display: inline-block;
  line-height: 1.2;
  margin-bottom: $element-spacing-small;
}

.form-label {
  &.required-field-label {
    margin-bottom: px-to-em(35px);

    &.add-section-spacing {
      margin-top: $section-spacing;
    }

    @include screen($bp-mobile) {
      margin-top: $section-spacing;
    }
  }
}

.form-label--radio {
  @include font-univers-condensed();

  margin-right: 1em;

  > input[type="radio"] {
    margin-right: 0.25em;
    vertical-align: middle;
    margin-bottom: 0.25em;
  }
}

.form-field-subheading {
  @extend .form-label;
  @extend %uc;

  display: block;
}

.form-heading-checkbox {
  color: $color-gray-steel;
  margin-bottom: $element-spacing;

  a {
    color: $color-link;

    &:hover {
      color: $color-link-hover;
    }
  }
}

/*---------- Wigley ------------*/
.form-fields-section,
.fields-group {
  @include clearfix;

  margin-bottom: $section-spacing;
}

.form-label--block {
  display: block;
}

.input--radio {
  display: inline-block;
  width: auto !important;
}

.input--tall {
  height: 5rem;
}

.input[aria-readonly="true"] {
  background-color: $color-gray-med !important;
  border-color: $color-gray-alt !important;
}

.full-width {
  width: 100%;
}

/*----------  layouts  ----------*/
.form-field--row-inline {
  display: flex;
  align-items: baseline;

  .form-field-subheading {
    margin-right: 1em;
  }
}

.form--layout-halfs {
  @include clearfix;

  padding-bottom: $section-spacing-small;
  &.no-padding-bottom {
    padding-bottom: 0;
  }

  .form-field {
    float: left;
    width: 49%;

    &:nth-child(2n + 1) {
      margin-right: 2%;
      clear: left;
    }

    &.form-field--radio-buttons {
      width: 100%;
      float: none;
    }
  }
}

.form--layout-thirds {
  @include clearfix;

  padding-bottom: $section-spacing-small;
  margin-right: -2%;

  .form-field {
    float: left;
    width: 31.3333%;
    margin-right: 2%;

    &:nth-child(3n + 1) {
      margin-right: 2%;
      clear: left;
    }

    .listbox {
      background: white;
      position: absolute;
      z-index: 1;
      border: solid 1px #ccc;
      width: 32%;

      @include screen($bp-mobile) {
        width: 100%;
      }

      div {
        text-indent: -1.65em;
        padding-left: 1.65em;
      }
    }
  }
}

@include screen($bp-mobile) {
  .form-base-wrapper {
    padding-top: $section-spacing-large-mobile;
    padding-bottom: $section-spacing-large-mobile;
  }

  .form-base-overview {
    margin-bottom: $section-spacing-mobile;
  }

  .form-field {
    input,
    .input {
      font-size: $form-input-font-size-mobile;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .form--layout-halfs,
  .form--layout-thirds {
    .form-field {
      float: none;
      width: 100%;
      width: calc(100vw - #{$site-padding-mobile * 2});
    }
  }

  .form--layout-halfs,
  .form--layout-thirds {
    .form-field {
      &:nth-child(2n + 1) {
        margin-right: 0;
      }
    }
  }

  .form-label--radio {
    display: block;
  }
}

.form-fields--inline {
  @include clearfix;
}

[class*="form-field--"] {
  width: 100%;

  @include screen($bp-mobile-landscape, min) {
    float: left;
    padding-left: 1rem;
  }

  &:first-child {
    @include screen($bp-mobile-landscape, min) {
      padding-left: 0;
    }
  }
}

.form-field--10 {
  @include screen($bp-mobile-landscape, min) {
    width: 10%;
  }
}

.form-field--15 {
  @include screen($bp-mobile-landscape, min) {
    width: 15%;
  }
}

.form-field--20 {
  @include screen($bp-mobile-landscape, min) {
    width: 20%;
  }
}

.form-field--25 {
  @include screen($bp-mobile-landscape, min) {
    width: 25%;
  }
}

.form-field--30 {
  @include screen($bp-mobile-landscape, min) {
    width: 30%;
  }
}

.form-field--33 {
  @include screen($bp-mobile-landscape, min) {
    width: 33.3333%;
  }
}

.form-field--40 {
  @include screen($bp-mobile-landscape, min) {
    width: 40%;
  }
}

.form-field--50 {
  @include screen($bp-mobile-landscape, min) {
    width: 50%;
  }
}

.form-field--60 {
  @include screen($bp-mobile-landscape, min) {
    width: 60%;
  }
}

.form-field--66 {
  @include screen($bp-mobile-landscape, min) {
    width: 66.6667%;
  }
}

.form-field--70 {
  @include screen($bp-mobile-landscape, min) {
    width: 70%;
  }
}

.form-field--75 {
  @include screen($bp-mobile-landscape, min) {
    width: 75%;
  }
}

.form-field--100 {
  padding-left: 0;

  @include screen($bp-mobile-landscape, min) {
    width: 100%;
  }
}

.form-fields {
  .rte {
    margin-bottom: 1.25em;
  }
}

.form-label--radio-block {
  text-transform: none;
  display: block;
}

.form-field input.input--checkbox-block {
  // display: block;
  width: auto;
  padding: 20px;
}

.form-link-external {
  @include ico-icon-after($icon-upward-arrow) {
    font-size: 0.5em;
    color: $color-text-red;
    margin-left: 0.5em;
  }
}
