$promo-card-path-max-width: 430px;

.promo-card-path {
  @include bg-cover;
  display: block;
  background-color: $color-charcoal;
  color: $color-brand-white;
  padding: 5%;
  padding: 7vw 5%; //need viewport units to comply with firefox
  position: relative;

  //transparent gradient
  &:before {
    @include absolute-full;
    @include gradient($color-nero, $color-nero-30, 0deg);
    @include transition(opacity);

    content: "";
    opacity: 1;
  }

  &:after {
    @include absolute-full;
    @include transition(opacity);
    background-color: $color-opaque-red;

    content: "";
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }

    //icon
    .promo-card-path-abstract {
      &:after {
        transform: translateX(100%);
      }
    }

    .promo-card-path-heading {
      &:after {
        border-color: $color-brand-black;
      }
    }
  }
}

.promo-card-path--external {
  .promo-card-path-abstract {
    &:after {
      content: $icon-upward-arrow;
    }
  }
}

.promo-card-path-heading {
  @include font-size-subheading();
  @include design-mark(10.5px, $font-size-subheading);
}

.promo-card-path-heading,
.promo-card-path-abstract {
  position: relative;
  z-index: 5;
}

.promo-card-path-abstract {
  @include ico-icon-after($icon-chevron-right) {
    @include transition(transform);

    font-size: px-to-em($arrow-icon-size, $font-size-copy);
    position: relative;
    z-index: 5;
    margin-top: $section-spacing-small;
  }
}

@include screen($bp-mobile) {
  .promo-card-path {
    padding: $site-padding-mobile;
  }

  .promo-card-path-heading {
    &:after {
      width: px-to-em($global-line-mark, $font-size-subheading);
    }
  }
}
