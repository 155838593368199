.loading-screen {
  $transition-out-timing: 1s;
  position: fixed;
  z-index: $zindex-loading-screen;
  background-color: $color-gainsboro-65;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: opacity $transition-out-timing $transition-ease,
    visibility $transition-timing $transition-ease $transition-out-timing,
    display $transition-timing $transition-ease $transition-out-timing;

  &.show-loading-screen {
    opacity: 1;
    visibility: visible;
    display: block;
    transition: opacity $transition-timing $transition-ease,
      visibility 0.1s $transition-ease, display 0.1s $transition-ease;
  }
}

.loading-screen-body {
  @include center(both);
}

.loading-screen-helper {
  @include font-size-entity-title();

  margin-top: 0.5em;
}

.loading-screen-icon {
  width: px-to-em(10px);
  height: px-to-em(48px);
  background: $color-link;
  position: relative;
  animation: animation-bars 1s ease-in-out infinite;
  animation-delay: 0.4s;
  top: 50%;
  margin: -28px auto 0 auto;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 48px;
    background: $color-link;
    animation: animation-bars 1s ease-in-out infinite;
  }

  &:before {
    right: px-to-em(18px);
    animation-delay: 0.2s;
  }

  &:after {
    left: px-to-em(18px);
    animation-delay: 0.6s;
  }
}

@keyframes animation-bars {
  0%,
  100% {
    box-shadow: 0 0 0 $color-link, 0 0 0 $color-link;
  }
  50% {
    box-shadow: 0 -8px 0 $color-link, 0 8px 0 $color-link;
  }
}
