.page-header-flex {
  background-color: $color-black-background;
  background-size: cover;
  padding: $header-padding 0;
  text-align: center;
  position: relative;
  z-index: 5;

  .page-wrapper {
    position: relative;
    z-index: 10;
  }
}

.page-header-flex-wrapper {
  @include page-wrapper;

  position: relative;
  padding-left: $site-padding * 1.5;
  padding-right: $site-padding * 1.5;
}

.page-header-flex-title {
  @include font-univers-condensed-bold($header-font-size);

  line-height: 1.1;
  color: $color-brand-white;
}

.page-header-flex-section-title {
  @include font-size-entity-title();
  @extend %uc;

  color: $color-red-on-black-foreground;
  display: inline-block;
  padding-bottom: px-to-em(13px, $font-size-entity-title);
  margin-bottom: px-to-em(22px, $font-size-entity-title);
  border-bottom: 1px solid $color-gray-alt;
}

//social media
.social-links {
  .page-header-flex & {
    $spacing: 26px;

    position: absolute;
    top: 0;
    right: $site-padding;

    .social-links-item {
      display: block;
      line-height: 1;
      margin-bottom: 1em;
    }

    .social-links-icon {
      font-size: px-to-em(24px);
    }

    .ico-pdf {
      font-size: px-to-em(28px);
    }

    .ico-share {
      font-size: px-to-em(23px);
    }
  }
}

@include screen($bp-scrollspy) {
  .page-header-flex {
    z-index: $zindex-scrollspy-nav + 5;
  }
}

@include screen($bp-mobile) {
  .page-header-flex {
    padding: $header-padding-mobile 0;
  }

  .page-header-flex-title {
    font-size: px-to-em($header-font-size-mobile);
    text-align: center;
  }

  .social-links {
    .page-header-flex & {
      $spacing: 30px;

      position: relative;
      top: auto;
      right: auto;
      display: inline-block;
      margin-top: 1em;
      vertical-align: middle;

      .social-links-item {
        display: inline-block;
        margin-bottom: 0;
        width: px-to-em(45px);
        text-align: left;
      }

      .ico-pdf {
        font-size: px-to-em(20px);
      }

      .ico-share {
        font-size: px-to-em(20px);
      }
    }
  }
}
