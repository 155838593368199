﻿.site-footer {
  background-color: $color-charcoal;
  padding: $section-spacing 0;
  color: $color-brand-white;

  .social-links {
    float: right;
  }

  .social-links-icon {
    font-size: px-to-em(22px);
    margin-left: 0.5em;
  }
}

.site-footer-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.site-footer-menu-link {
  @extend %uc;

  @include font-univers-condensed-bold(16px);
  display: inline-block;
  line-height: 1.2;
  margin-right: 0.25em;
}

.site-footer-menu-link,
.site-footer-utility-link,
.site-footer .social-links-icon {
  &:hover {
    color: $color-text-gray-soft;
  }
}

.site-footer-additional {
  $spacing: px-to-em(22px);

  padding-top: $spacing;
  margin-top: $spacing;
  border-top: 1px solid $color-brand-white;
  overflow: hidden;
}

.site-footer-utility {
  float: left;

  .ico {
    margin-left: 0.5em;
    font-size: 0.8em;
  }
}

.site-footer-utility-item {
  display: inline;
  margin-right: 1.75em;
}

.site-footer-utility-link {
  @extend %uc;

  @include font-univers-condensed-bold(21px);

  line-height: 1.2;
  vertical-align: bottom;
}

.site-footer-logo {
  width: px-to-em(184px);
  display: block;
}

.site-footer-logo-img {
  width: 100%;
}

.site-footer-copyright {
  background-color: $color-black-background;
  padding: $section-spacing-small 0;
}

.site-footer-copyright-info,
.site-footer-copyright-main-site {
  font-size: px-to-em(15px);
}

.site-footer-copyright-info {
  margin-left: 0.25em;

  &:before {
    content: "©";
    display: inline-block;
    margin-right: 0.25em;
  }
}

.site-footer-copyright-main-site {
  display: inline-block;
  margin-right: 0.25em;
  padding-right: 0.75em;
  position: relative;

  &:hover {
    color: $color-brand-white;
    text-decoration: underline;
  }

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: $color-brand-white;
  }
}

.site-footer--main {
  .site-footer-additional {
    padding-bottom: px-to-em(22px);
  }
}

@include screen($bp-tablet) {
  .site-footer-menu-link {
    font-size: px-to-em(18px);
  }

  .site-footer-utility-link {
    font-size: px-to-em(20px);
    display: inline-block;
  }
}

@include screen($bp-mobile) {
  $footer-spacing: px-to-em(24px);

  .site-footer {
    text-align: center;
    padding: $section-spacing-mobile 0;

    .social-links {
      float: none;
      padding-top: $footer-spacing;
      margin-top: $footer-spacing;
      border-top: 1px solid $color-brand-white;
      overflow: hidden;
    }

    .social-links-icon {
      margin: 0 0.5em;
    }
  }

  .site-footer-menu {
    display: block;
  }

  .site-footer-menu-item {
    margin-bottom: $footer-spacing;
  }

  .site-footer-menu-link {
    font-size: px-to-em(20px);
  }

  .site-footer-utility {
    float: none;
  }

  .site-footer-utility-item {
    display: block;
    margin-bottom: $footer-spacing;
  }

  .site-footer-utility-link {
    font-size: px-to-em(20px);
    display: inline-block;
  }
}

@include screen($bp-mobile-landscape) {
  .site-footer {
    .social-links {
      float: none;
    }

    .social-links-icon {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }
  .site-footer-menu {
    flex-wrap: wrap;
    justify-content: center;
  }

  .site-footer-menu-link {
    display: inline-block;
    padding: 0 1em 1em;
  }

  .site-footer-utility {
    float: none;
  }

  .site-footer-utility-item {
    display: block;
    margin-right: 0;
    margin-bottom: 1em;
  }

  .site-footer-logo {
    width: px-to-em(160px);
  }

  .site-footer-copyright {
    text-align: center;
  }

  .site-footer-copyright-info {
    margin-left: 0;
    display: block;
  }

  .site-footer-copyright-main-site {
    @include design-mark($spacing: 10px, $border-color: $color-brand-white);

    &:after {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $element-spacing-small;
      padding-top: 0;
    }
  }
}
