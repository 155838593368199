.page-foundation-fellows-directory {
  &.page-award-recipients {
    padding-top: $section-spacing-large;

    .form--layout-thirds {
      padding-bottom: 0;
    }
  }

  .search-landing-results {
    padding-top: $section-spacing-large;
    margin-bottom: $section-spacing-largest;
  }

  .keyword-search-helper-text {
    margin-bottom: $section-spacing;
  }

  .exposed-search-filters {
    margin-bottom: 0;
  }

  @include screen($bp-mobile) {
    .keyword-search-helper-text {
      margin-bottom: $section-spacing-mobile;
    }

    .search-landing-results {
      padding-top: $section-spacing-large-mobile;
      margin-bottom: $section-spacing-large-mobile;

      &.page-award-recipients {
        padding-top: $section-spacing-large-mobile;
      }
    }
  }
}
