.page-lawyer-application {
  background-color: $color-gray-med;

  .input-upload {
    background-color: $color-brand-white;
  }

  .survey-button {
    margin-bottom: 1.5em;
    font-size: 1.2em;
  }
}
