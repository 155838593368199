.page-foundation-news-stories {
  .search-landing-panel-content {
    padding-top: $section-spacing-large;
    margin-bottom: $section-spacing-largest;
  }

  .personnel-highlight-wrapper {
    @include content-wrapper;
  }

  .search-criteria {
    margin-bottom: $section-spacing-large;
  }

  .search-criteria-wrapper {
    padding-left: 0;
    padding-right: 0;

    &:after {
      left: 0;
      right: 0;
    }
  }

  @include screen($bp-mobile) {
    .search-criteria {
      margin-bottom: $section-spacing-mobile;
    }

    .search-landing-panel-content {
      padding-top: $section-spacing-large-mobile;
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
