.recent-publications {
  margin-bottom: $section-spacing-large;
}

.recent-publications-all {
  text-align: center;
  margin-top: $section-spacing;
}

@include screen($bp-mobile) {
  .recent-publications {
    margin-bottom: $section-spacing-large-mobile;
  }
}
