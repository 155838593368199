//panels
.search-landing-panels {
  position: relative;
}

.search-landing-panel {
  display: none;

  .search-landing-filters {
    margin-top: $section-spacing-large;
  }

  &.is-panel-active {
    display: block;
  }
}

.search-landing-panel-content {
  .search-landing-listing-wrapper {
    @include content-wrapper;
  }
}

@include screen($bp-mobile) {
  .search-landing-panel {
    .search-landing-filters {
      margin-top: 0;
    }
  }
}
