.page-alumni-homepage {
  .featured-carousel {
    border-top-color: lighten($color-brand-gray, 10%);
    border-top-width: $section-spacing;
  }

  .alumni-homepage-body {
    @include content-wrapper;

    padding-top: $section-spacing-large;
    margin-bottom: $section-spacing-largest;
  }

  @include screen($bp-mobile) {
    .alumni-homepage-body {
      padding-top: $section-spacing-large-mobile;
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
