.home-explore {
  @include bg-cover;

  width: 100%;
  height: 100vh;
  position: relative;
  background-color: $color-black-background;
  min-height: px-to-em(400px);

  .mobile-toggle,
  .site-search-toggle {
    display: block;
  }

  //faded out gradiant
  &:before {
    @include absolute-full;

    content: "";
    background-image: linear-gradient(
      0deg,
      $color-nero 0.5em,
      $color-nero-10 16%
    );
    display: block;
    top: 0;
    bottom: -0.1em; ///ie fix to prevent flickering as you scroll page SK - 355
    z-index: 5;
  }

  &.home-explore--video {
    &:before {
      display: none;
    }
  }
}

.home-explore-tools {
  @include center(vertical, relative);

  top: 45%;
  margin: 0 auto;
  width: px-to-em(487px);
  background-color: $color-brand-red;
  z-index: 10;
}

.home-explore-logo-wrapper {
  float: left;
  display: block;
  width: px-to-em(348px);
}

.home-explore-logo {
  width: 100%;
  display: block;
}

.home-explore-cta {
  @include center(horizontal);
  @include font-univers-condensed-bold(19px);

  bottom: 5%;
  color: $color-brand-white;
  text-align: center;
  text-transform: uppercase;
  z-index: 5;
  white-space: nowrap;
  transform: translate3d(-50%, 0, 0);

  //targeting iphone/ios devices to move video slightly higher
  html.touch & {
    bottom: 15%;
  }

  @include ico-icon-after($icon-chevron-bottom) {
    display: block;
    margin: 0 auto;
    font-size: 1.8em;
    margin-top: 2vh;
  }
}

.home-explore-video {
  @include absolute-full();

  z-index: 4;
  overflow: hidden;

  video {
    @include center(both);
    @include transition(opacity);

    opacity: 0;

    &.is-video-ready {
      opacity: 1;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .home-explore-tools {
    width: px-to-em(300px);
    top: 40%;
  }

  .home-explore-logo-wrapper {
    width: 100%;
    float: none;
  }
  .home-explore {
    .site-search-toggle,
    .mobile-toggle {
      display: block;
      float: left;
      width: 50%;
    }
  }
}

@include screen-landscape($bp-mobile-small) {
  .home-explore-cta {
    bottom: 5%;

    //targeting iphone/ios devices to move video slightly higher
    html.touch & {
      bottom: 15%;
    }
  }
}
