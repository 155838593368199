#people-detail {
  .office-override {
    float: left;
  }

  .offices-related-phone-label {
    width: auto;
    padding-right: 0.25em;
  }
}
