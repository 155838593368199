@use "sass:math";

$cross-link-wrapper-padding: 45px;

.cross-link-panel {
  @include bg-cover;

  background-position: center top;

  margin-bottom: $section-spacing-large;
  padding: $section-spacing;
}

.cross-link-panel-wrapper {
  $cross-link-wrapper-padding-em: px-to-em(45px);

  background-color: $color-nero-90;
  color: $color-brand-white;
  padding: $cross-link-wrapper-padding-em $cross-link-wrapper-padding-em
    math.div($cross-link-wrapper-padding-em, 2);
  text-align: center;
}

.cross-link-panel-heading {
  .section-heading {
    display: inline-block;
    border-bottom: 1px solid $color-brand-white;

    padding: 0 0.25em px-to-em($section-spacing-small-px, $section-heading-size);
    margin-bottom: 0;
  }

  margin-bottom: $section-spacing-small;
}

.cross-link-panel-abstract {
  max-width: px-to-em(600px, $font-size-copy);
  margin: 0 auto px-to-em($cross-link-wrapper-padding, $font-size-copy);
}

.cross-link-panel-cta-links {
  margin-bottom: px-to-em(math.div($cross-link-wrapper-padding, 2));
}
.cross-link-panel-cta {
  color: $color-brand-white;
  display: inline-block;

  &:hover {
    color: $color-text-gray-soft;
  }
}

.cross-link-panel-cta-linkedin {
  display: inline-block;
  margin-right: 0.5em;
  padding-right: 0.75em;
  border-right: 1px solid $color-gray-steel;

  &:before {
    font-size: px-to-em($font-size-entity-title);
  }

  &:hover {
    color: $color-link;
  }
}

@include screen($bp-tablet) {
  $tablet-padding: $element-spacing * 1.1;

  .cross-link-panel,
  .cross-link-panel-wrapper {
    padding: $tablet-padding * 1.25;
  }

  .cross-link-panel-wrapper {
    padding-bottom: math.div($tablet-padding, 2);
  }
}

@include screen($bp-mobile) {
  .cross-link-panel,
  .cross-link-panel-wrapper {
    padding: $element-spacing;
  }

  .cross-link-panel-wrapper {
    padding-bottom: math.div($element-spacing, 2);
  }

  .cross-link-panel-cta-linkedin {
    &:before {
      font-size: px-to-em($font-size-entity-title-mobile);
    }
  }
}

@include screen($bp-mobile) {
  .cross-link-panel-cta-linkedin {
    border-right: 0 none;
    margin-right: 0;
    margin-bottom: 1em;
    padding-right: 0;
  }

  .cross-link-panel-cta {
    display: block;
  }
}
