﻿.governance-listing {
  .governance-listing-cards {
    opacity: 0;
    height: 0;
    margin-top: 0;

    .professional-card {
      display: none;
    }

    transition: opacity $transition-timing ease-in-out,
      height $transition-timing ease-in-out;

    &.is-active-panel {
      opacity: 1;
      height: auto;

      .professional-card {
        display: table;
      }
    }
  }
}
