.back-to-top {
  position: relative;
  padding-top: $section-spacing-large;
  margin-bottom: $section-spacing-largest;
}

.back-to-top-sections,
.back-to-top-offset {
  margin-left: $back-to-top-content-offset;
}

@include screen($bp-back-to-top) {
  .back-to-top-sections,
  .back-to-top-offset {
    margin-left: 0;
  }
}

@include screen($bp-mobile) {
  .back-to-top {
    padding-top: $section-spacing-large-mobile;
    margin-bottom: $section-spacing-large-mobile;
  }
}
