.languages-item {
  $spacing: 0.4em;

  display: inline;
  border-right: 1px solid $color-brand-white;

  padding-right: $spacing;
  margin-right: $spacing * 1.5;

  &:last-of-type {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
  }
}

.languages-name {
  @include font-size-entity-title;

  color: $color-link;
  vertical-align: middle;

  &:hover {
    color: $color-text-gray-soft;
  }

  &.is-selected,
  &.is-active {
    color: $color-brand-white !important;
  }
}
