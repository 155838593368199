.entity-report {
  margin-bottom: $section-spacing-large;
}

.entity-report-highlight {
  margin-bottom: $section-spacing;
}

@include screen($bp-mobile) {
  .entity-report {
    margin-bottom: $section-spacing-large-mobile;
  }

  .entity-report-highlight {
    margin-bottom: $section-spacing-mobile;
  }
}
