.read-more-full {
  display: none;
  overflow: hidden;
}

.read-more-toggle {
  position: relative;
  margin: 0 auto;
  display: block;
  margin-top: px-to-em($section-spacing-px, $font-size-copy);
  .rte & {
    font-size: px-to-em($font-size-buttons, $font-size-copy);

    @include screen($bp-mobile) {
      font-size: px-to-em($font-size-buttons-mobile, $font-size-copy-mobile);
    }
  }

  @include ico-icon-after($icon-plus) {
    @include transition(transform);
    font-size: 0.65em;
    margin-left: 0.5em;
    margin-top: -0.25em;
    vertical-align: middle;
    line-height: 1; // 1 + (1 - font-size) to keep it centered
  }

  &.is-expanded {
    color: $color-text-body;

    &:after {
      transform: rotate(135deg);
    }
  }
}

#read-more-overview {
  display: none;
}
