@use "sass:math";

//will resort to flex box instead of using display table  in the situation where an image is not present
.professional-result-card {
  @include clearfix;

  position: relative;
  min-height: px-to-em(220px);
  padding-left: px-to-em($professional-thumbnail-width);
  background-color: $color-gray-light;
}

/*----------  image related  ----------*/

.professional-result-card-figure {
  width: px-to-em($professional-thumbnail-width);
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;

  img {
    @include center(horizontal, relative);

    display: block;
    min-width: 100%;
  }

  &:hover {
    & + .professional-result-card-details {
      .professional-result-card-name {
        color: $color-gray-steel;
      }
    }
  }
}

/*----------  name and partner related info  ----------*/
.professional-result-card-details,
.professional-result-card-contact {
  float: left;
  width: 55%;
}

.professional-result-card-details {
  padding: $element-spacing 0 math.div($element-spacing, 2) 5%;

  @include design-mark(17.5px);
  width: 45%;

  &:after {
    padding-top: 0;
  }
}

.professional-result-card-name {
  @include transition(color);
  @include font-size-banner();
}

.professional-result-card-link {
  display: block;

  &:hover {
    .professional-result-card-name {
      color: $color-gray-steel;
    }
  }
}

.professional-result-card-position {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
}

/*---------- Contact Related information, office, phone, email  ----------*/

$icon-width: 23px;

.professional-result-card-contact {
  padding: $element-spacing 3% $element-spacing 5%;
}

.prc-option {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
  position: relative;
  padding-left: px-to-em($icon-width, $font-size-entity-title) + 0.5em;

  & + & {
    margin-top: 0.5em;
  }
}

.prc-contact-office-link,
.prc-contact-email-link,
.prc-contact-phone-link {
  color: $color-link;

  @include ico-icon-before($icon-envelope) {
    display: inline-block;
    color: $color-text-body;
    position: absolute;
    left: 0;
    font-size: 1.25em;
  }

  &:hover {
    color: $color-link-hover;
  }
}

.prc-contact-office-link {
  &:before {
    content: $icon-location;
    font-size: 0.9em;
    left: 0.25em;
  }
}

.prc-contact-office-nolink {
  @include ico-icon-before($icon-location) {
    display: inline-block;
    color: $color-text-body;
    position: absolute;
    left: 0.25em;
    font-size: 0.9em;
  }
}

.prc-contact-phone-link {
  color: $color-text-body;

  &:hover {
    color: $color-text-body;
  }

  &:before {
    content: $icon-phone;
    font-size: 1em;
  }
}

@include screen($bp-tablet) {
  //will resort to flex box instead of using display table  in the situation where an image is not present
  .professional-result-card {
    padding-left: 0;
  }

  .professional-result-card-details,
  .professional-result-card-contact {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }

  .professional-result-card-figure {
    display: none;
  }
}

@include screen($bp-mobile) {
  .professional-result-card-name {
    margin-bottom: 0.25em;
  }

  .professional-result-card-position,
  .prc-option {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .prc-contact-phone-link {
    color: $color-link;

    &:hover {
      color: $color-link;
    }

    &:before {
      color: $color-text-body;
    }
  }
}

@include screen($bp-mobile-landscape) {
  $professional-result-card-width-mobile: px-to-em(280px);
  $professional-result-card-spacing: 1.25em;

  .professional-result-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: $professional-result-card-width-mobile;
  }

  .professional-result-card-details,
  .professional-result-card-contact {
    padding: $professional-result-card-spacing;
  }

  .professional-result-card-details,
  .professional-result-card-contact {
    width: 100%;
    float: none;
  }

  .professional-result-card-contact {
    padding-top: 0;
  }

  .prc-department {
    padding-left: 0;
  }

  //SK-590 -need to hide image since it looks distorted
  // .professional-result-card-figure {
  // 	display: block;
  // 	width: 100%;
  // 	position: relative;
  // 	height: $professional-result-card-width-mobile;

  // 	img {
  // 		min-height: inherit;
  // 	}
  // }
}
