$font-size-insight-tile-title: 40px;

.insight-tile {
  $insight-padding: px-to-em(35px);
  display: block;
  padding: $insight-padding;
  background-color: $color-nero-90;
  position: relative;
  color: $color-brand-white;

  @include transition(background-color);

  &:focus {
    outline-color: $color-brand-turquoise;
    outline-offset: -1px;
  }

  &:hover {
    background-color: $color-opaque-red;

    .insight-tile-tag {
      &:after {
        border-top-color: $color-brand-white;
      }
    }
  }
}

div.insight-tile {
  &:hover {
    background-color: $color-nero-90;
  }
}

.insight-tile-title {
  @include font-size-subheading();

  line-height: 1.2em;
  display: block;
}

.insight-tile-tag,
.insight-tile-date {
  font-size: px-to-em($font-size-entity-title);

  line-height: 1.1;
}

.insight-tile-tag {
  @include design-mark(17.5px, $font-size-entity-title);
}

.insight-tile-date {
  margin-top: px-to-em(17.5px, $font-size-entity-title);
  display: block;
}

@include screen($bp-mobile) {
  $insight-padding: 1.31em;

  .insight-tile {
    padding: $insight-padding;
  }

  .insight-tile-tag,
  .insight-tile-date {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .insight-tile-title {
    font-size: px-to-em($font-size-subheading-mobile);

    &:after {
      width: px-to-em($global-line-mark, $font-size-subheading-mobile);
    }
  }
}
