#logout_form {
  flex-shrink: 0;
  height: 100%;
}

.site-user-login {
  width: px-to-em($site-header-fellowshipt-height);
  height: 100%;
  display: inline-block;
  background-color: $color-gray-med;
  text-align: center;
  color: $color-brand-black;
  border-color: $color-gray-alt;
  position: relative;
  transition: $transition-default;
  cursor: pointer;

  &:hover {
    color: $color-brand-black;
    background-color: $color-gray-alt;
  }
}

.site-user-login-label {
  $label-size: 14px;

  @extend %uc;
  @include center(vertical, relative);

  letter-spacing: 0.1em;
  display: inline-block;
  font-size: px-to-em($label-size);

  @include ico-icon-before($icon-user) {
    font-size: px-to-em(26px, $label-size);
    display: block;
    margin-bottom: 0.1em;
  }
}

@include screen($bp-mobile-landscape) {
  .site-user-login {
    width: px-to-em($site-header-ui-width-mobile);
  }
  .site-user-login-label {
    font-size: px-to-em(14px);

    &:before {
      font-size: px-to-em(26px);
    }
  }
}
