.recent-accolades {
  margin-bottom: $section-spacing-large;
}

.recent-accolades-banners {
  margin-bottom: $section-spacing;

  .content-banner {
    & + .content-banner {
      margin-top: $section-spacing;
    }
  }
}

.recent-accolades-view-more {
  display: block;
  margin: $section-spacing auto 0;
  display: block;
}

@include screen($bp-mobile) {
  .recent-accolades {
    margin-bottom: $section-spacing-large-mobile;
  }

  .flex-panel-intro,
  .flex-panel-copy {
    margin-bottom: $section-spacing-mobile;
  }

  .recent-accolades-banners {
    margin-bottom: $section-spacing-mobile;

    .content-banner {
      & + .content-banner {
        margin-top: $section-spacing-mobile;
      }
    }
  }

  .recent-accolades-view-more {
    margin-top: $section-spacing-mobile;
  }
}
