$section-height: calc(100vh - $site-header-fellowshipt-height);
$carousel-height: calc(75vh - $site-header-fellowshipt-height);
$section-height-mobile: calc(100vh - 59px);

@mixin modal {
  position: fixed;
  z-index: $zindex-modal-bg;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.modal-intro {
    opacity: 1;
  }
  &.modal-outro {
    opacity: 0;
  }
}

@mixin modal-container {
  width: rem(1308px);
  max-width: 100vw;
  height: rem(785px);
  max-height: 100vh;
  background-color: $color-brand-white;
  box-shadow: 10px 10px 20px rgba(14, 14, 14, 0.5);
  position: relative;
}

@mixin category {
  background-color: $color-alizarin-dark;
  color: $color-brand-white;
  padding: 0px 7px;
  font-weight: 700;
  margin-bottom: 20px;
  align-self: flex-start;
}

@mixin title {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 14px;
  max-width: rem(490px);

  @include screen($bp-mobile) {
    font-size: rem(12px);
  }
}
@mixin name {
  font-size: rem(60px);

  @include screen($bp-mobile) {
    font-size: rem(37.8px);
  }
}

@mixin content {
  max-width: rem(637px);
  font-size: rem(18px);
  font-weight: 300;
  line-height: 125%;

  @include screen($bp-mobile) {
    font-size: rem(18px);
  }
}

@mixin red-button {
  border-radius: 50%;
  background-color: $color-alizarin-dark;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin close-button {
  @include red-button();
  height: rem(47px);
  width: rem(47px);
  position: absolute;
  right: -1.5rem;
  bottom: -1.5rem;

  @include screen($bp-site-header-fellowship-tablet) {
    top: rem(24px);
    right: 0px;
  }
}
@mixin with-quotes {
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    content: '“';
    left: -8px;
  }
  &::after {
    content: '”';
  }
}

@mixin play-button {
  @include red-button();
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: rem(83px);
  width: rem(83px);
}
@mixin video-button {
  display: flex;
  align-items: center;
  gap: rem(16px);
  width: fit-content;
  &--icon {
    height: rem(47px);
    width: rem(47px);
    background-color: $color-alizarin-dark;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: rem(10.5px);
      width: rem(8px);
    }
  }
}

@mixin audio-controls {
  &--controls {
    width: rem(251px);
    height: rem(46px);
    padding: rem(12px) rem(20px);
    background-color: $color-brand-white;
    border: 1.5px solid $color-brand-gray;
    border-radius: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(13px);
  }
  &--button {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: rem(16px) 0px;
    border: none;

    &.paused {
      .foundation-homepage-fellow-modal__audio--play-icon {
        opacity: 1;
      }
      .foundation-homepage-fellow-modal__audio--pause-icon {
        opacity: 0;
      }
    }
  }
  &--play-icon,
  &--pause-icon {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;

    svg {
      flex-grow: 0;
      height: rem(10.5px);
      width: rem(8px);
    }
  }
  &--play-icon {
    opacity: 0;
  }
  &--pause-icon {
    opacity: 1;
  }
  &--progress-outer {
    flex-grow: 1;
    height: rem(2px);
    background-color: $color-brand-gray;
    overflow: hidden;
  }
  &--progress-inner {
    height: 100%;
    width: 100%;
    background-color: $color-alizarin-dark;
  }
  &--menu {
    opacity: 1;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $color-brand-white;
    padding: rem(14px);
    border: 1px solid $color-brand-gray;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: rem(10px);
    visibility: visible;
    list-style: none;

    li {
      white-space: pre;
    }
    &[data-expanded='false'] {
      opacity: 0;
      visibility: hidden;
    }
  }
  &--menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &--menu-button {
    height: 100%;
    width: rem(16px);
    display: flex;
    flex-direction: column;
    gap: rem(4px);
    justify-content: center;
    align-items: center;
    .audio-menu-dot {
      height: rem(4px);
      width: rem(4px);
      border-radius: 50%;
      background-color: $color-gray-ac;
    }
  }
}

.foundation-homepage-landing {
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  ul,
  ol {
    padding-left: 2rem;
  }
}

.page-foundation-homepage {
  &:before {
    display: none;
  }

  .foundation-explore-tools {
    top: 60%;
  }

  .site-header {
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
    transition: visibility $transition-timing $transition-ease
        $transition-timing,
      transform $transition-timing $transition-ease;
    transition-duration: 0.45s;

    &.is-inview-header {
      @include transition(transform);
      transform: translate3d(0, 0, 0);
      visibility: visible;

      & + .site-nav {
        .site-nav-close {
          top: rem($site-header-height);
          margin-top: 16%;
        }
      }

      & ~ .site-search-panel {
        .site-search-panel-close {
          top: rem($site-header-height);
          margin-top: 16%;
        }
      }
    }
  }

  .foundation-homepage-body {
    @include content-wrapper;

    section.video {
      margin-bottom: 1.625em;

      .section-heading {
        font-family: $font-family-univers-condensed;
      }
    }

    padding-top: $section-spacing-large;
  }

  .site-search-panel-close,
  .site-nav-close {
    top: 0;
  }

  .foundation-homepage-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(16px);
    padding: rem(68px) rem(50px) rem(86px);
    text-align: center;

    &__panel--left {
      flex-grow: 0;
      h1 {
        font-size: rem(60px);
        line-height: 1.2;
        max-width: rem(757px);
      }
    }

    &__panel--right {
      max-width: rem(607px);
      p {
        color: $color-gray-ac;
        font-size: rem(18px);
        margin: 0px;
      }
    }
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    font-size: 1px;
  }

  .foundation-homepage-carousel {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
    margin-bottom: rem(86px);

    &__nav {
      height: rem(16px);
      padding-bottom: rem(6px);
      margin-top: rem(30px);
      display: flex;
      align-items: flex-end;
      gap: rem(4px);

      &--left {
        margin-right: rem(6px);
      }
      &--right {
        margin-left: rem(6px);
      }
      &--left,
      &--right {
        transform: translateY(50%);
        height: rem(16px);
        width: rem(10px);
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--btn {
        height: rem(4px);
        width: 100%;
        flex: 0 1 rem(85px);
        background-color: $color-brand-gray;
        transition: height 0.3s ease-in-out;

        &:hover {
          height: rem(11px);
        }

        &.active-nav {
          height: rem(11px);
          background-color: $color-alizarin-dark;
        }
      }
    }

    &__item {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      // height: $section-height;
      display: flex;
      justify-content: space-between;
      position: relative;
      opacity: 0;
      visibility: hidden;
      z-index: auto;
      transition: all 0.4s ease-out;
      will-change: opacity;
      margin-left: auto;
      margin-right: auto;

      &.active-item {
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }

      &--about {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: $color-brand-white;
      }
      &--flex-container {
        flex: 0 1 rem(637px);
        // max-height: 95%;
        height: $carousel-height;
        padding: rem(0px) rem(0px) rem(0px) rem(50px);
        // margin: 0px auto;
        background-color: $color-brand-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        &.can-scroll {
          &::after {
            position: absolute;
            bottom: rem(46px);
            content: '';
            width: 100%;
            height: rem(50px);
            background: linear-gradient(
              360deg,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            );
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
          }
          &.can-scroll.at-bottom {
            &::after {
              opacity: 0;
            }
          }
        }
      }
      &--body {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: rem(23px);
        padding-left: rem(8px);
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
      }
      &--copy {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: rem(24px);
      }
      &--category {
        @include category();
      }
      &--title {
        @include title();
      }
      &--name {
        @include name();
      }
      &--content {
        @include content();
      }
      &--video-button {
        @include video-button();
      }
      &--audio {
        @include audio-controls();

        &--button {
          &.paused {
            .foundation-homepage-carousel__item--audio--play-icon {
              opacity: 1;
            }
            .foundation-homepage-carousel__item--audio--pause-icon {
              opacity: 0;
            }
          }
        }
      }
      &--quote {
        max-width: rem(622px);
        font-size: rem(22px);
        font-weight: 300;
        line-height: 150%;
        & > span {
          @include with-quotes();
        }
      }
      &--image-container {
        width: fit-content;
        height: $carousel-height;
        position: relative;
      }
      &--image {
        display: block;
        object-fit: cover;
        object-position: top;
        max-width: calc($carousel-height * 0.74);
        height: 100%;
        max-height: $carousel-height;
        width: calc($carousel-height * 0.75);
      }
      &--image-caption {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 17vh;
        padding-bottom: rem(24px);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        color: $color-brand-white;
        font-style: italic;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        span {
          display: block;
          color: $color-brand-white;
          font-style: italic;
        }
      }
    }
  }

  .foundation-homepage-timeline {
    &__heading {
      font-size: rem(60px);
      line-height: 1.1;
      letter-spacing: -0.03em;
      text-align: center;
      max-width: rem(938px);
      padding: 0 rem(24px);
      margin: 0 auto 3rem;
    }

    &__container {
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: none;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-auto-flow: dense column;
      gap: rem(65px) rem(20px);
      padding-bottom: rem(28px);

      &:hover {
        cursor: grab;
      }
      &.dragging {
        cursor: grabbing;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__group {
      display: contents;
    }

    &__card {
      min-width: rem(370px);
      padding: 1rem 0;

      &.top {
        grid-row: 1;
      }
      &.bottom {
        grid-row-start: 2;
        transform: translateX(50%);
      }
      &--left {
        height: 100%;
      }
      &--right {
        height: rem(26px);
        position: relative;
        span {
          user-select: none;
        }
      }
      &--image-container {
        height: rem(121px);
        width: rem(121px);
        border-radius: 50%;
        overflow: hidden;
      }
      &--image {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
        user-select: none;
        pointer-events: none;
      }
      &--button {
        display: flex;
        gap: rem(21px);

        &.dragging {
          cursor: grabbing;
        }

        &:hover {
          .foundation-homepage-timeline__card--year::before {
            width: rem(75px);
          }
        }
      }
      &--year {
        font-size: rem(20px);
        font-weight: 700;
        user-select: none;
        position: absolute;
        display: flex;
        gap: rem(13px);
        &::before {
          content: '';
          display: block;
          align-self: center;
          width: rem(53px);
          height: rem(2px);
          background-color: $color-alizarin-dark;
          transition: width 0.3s ease-in-out;
          pointer-events: none;
        }
      }
      &--name {
        font-size: rem(38px);
        font-weight: 700;
        margin-top: rem(26px);
        display: block;
        text-align: start;
        white-space: nowrap;
      }
    }

    &__nav {
      padding: rem(40px) rem(100px);
      width: 100%;
      max-width: rem(1310px);
      margin: rem(32px) auto rem(28px);

      &--button {
        position: relative;
        z-index: 1;
        height: rem(14px);
        width: rem(14px);
        border-radius: 50%;
        border: 2px solid $color-gray-timeline;
        background-color: $color-brand-white;
        transition: border 0.3s ease-in-out;

        &.active-timeline-button {
          border: 2px solid $color-alizarin-dark;
        }

        &::after {
          position: absolute;
          content: '';
          height: rem(78px);
          width: rem(78px);
          border-radius: 50%;
          border: 1px solid $color-gray-timeline;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &--text {
          position: absolute;
          top: -24px;
          right: -78px;
        }

        &::after,
        &--text {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        &:hover,
        &.active-timeline-button {
          .foundation-homepage-timeline__nav--button--text,
          &::after {
            opacity: 1;
          }
        }
      }

      &--list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        list-style: none;
        padding: 0px;

        li {
          display: flex;
          align-items: center;
        }

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: rem(2px);
          background-color: $color-gray-timeline;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
  .foundation-homepage-fellow-modal {
    @include modal();

    &__container {
      @include modal-container();
      overflow: auto;
      padding: 0px rem(88px);
      display: grid;
      column-gap: rem(58px);
      row-gap: rem(33px);
      grid-template-columns: 1fr 2fr;
      grid-template-rows: min-content min-content min-content;
      grid-template-areas:
        'close close'
        'image body'
        '. body';
    }

    &__close-button-container {
      grid-area: close;
      position: sticky;
      top: 0px;
      width: 100%;
      height: rem(72px);
      pointer-events: none;
    }

    &__close-button {
      @include close-button();
      right: -1.5rem;
      bottom: -1.5rem;
      pointer-events: all;
    }

    &__body {
      grid-area: body;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    &__assets {
      flex-grow: 1;
      display: flex;
      gap: rem(24px);
      flex-direction: column;
      justify-content: space-around;
      padding-bottom: rem(48px);
    }
    &__image-container {
      grid-area: image;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(15px);
      max-height: rem(445px);
    }
    &__image {
      height: 100%;
      max-width: 100%;
      width: auto;
    }
    &__image-caption {
      font-style: italic;
    }
    &__quote {
      @include with-quotes();
      font-size: rem(22px);
      font-weight: 300;
      line-height: 150%;
      p:last-child {
        display: inline;
      }
    }
    &__category {
      @include category();
    }
    &__title {
      @include title();
    }
    &__name {
      @include name();
      margin-bottom: rem(14px);
    }
    &__divider {
      height: rem(2px);
      width: rem(86px);
      background-color: $color-alizarin-dark;
      margin-bottom: rem(30px);
    }
    &__content {
      @include content();
      line-height: 125%;
    }

    &__video-button {
      @include video-button();
    }
    &__audio {
      @include audio-controls();
    }
  }
  .foundation-homepage-video-modal {
    @include modal();

    &__container {
      @include modal-container();
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100vw;
      min-width: none;
    }
    &__body {
      max-width: calc(100vw - rem(35px));
      margin: 0 rem(35px);

      iframe {
        max-width: calc(100vw - rem(35px));

        video {
          object-fit: contain;
          width: 100%;
          height: auto;
        }
      }
    }
    &__close-button-container {
      height: rem(96px);
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: absolute;
      top: 0;
    }
    &__close-button {
      @include close-button();
      position: static;
      margin-right: rem(64px);
    }
    &__play-button {
      @include play-button();
      &--icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@include screen($bp-fellowship-carousel-tablet) {
  .page-foundation-homepage {
    .foundation-homepage-carousel {
      &__item {
        &--image {
          width: 100%;
        }
        &--image-caption {
          padding: rem(24px);
        }
        &--category {
          font-size: rem(14px);
        }
        &--title {
          font-size: rem(12px);
        }
        &--name {
          font-size: rem(37.7968px);
        }
        &--content {
          font-size: rem(18px);
        }
        &--quote {
          font-size: rem(16px);
        }
      }
    }
  }
}

@include screen($bp-site-header-fellowship-tablet) {
  .page-foundation-homepage {
    .foundation-homepage-intro {
      &__panel--left {
        h1 {
          font-size: rem(38px);
        }
      }
    }
    .foundation-homepage-fellow-modal {
      &__container {
        padding: 0px rem(20px);
        gap: rem(16px);
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content auto min-content;
        grid-template-areas:
          'close'
          'image'
          'body'
          'quote';
      }
      &__image {
        align-self: flex-start;
        height: 100%;
        max-height: rem(142px);
        width: auto;
      }
      &__assets {
        padding-bottom: 0px;
      }
      &__quote {
        padding-bottom: rem(20px);
      }
      &__close-button-container {
        height: rem(20px);
      }
    }
    .foundation-homepage-video-modal {
      &__close-button-container {
        height: rem(71px);
      }
      &__close-button {
        margin-right: 20px;
      }
    }
  }
}

@include screen($bp-mobile) {
  .page-foundation-homepage {
    .foundation-homepage-body {
      padding-top: $section-spacing-large-mobile;
    }
    .foundation-homepage-intro {
      padding: rem(68px) rem(20px) rem(44px);
      flex-direction: column;
      gap: rem(24px);
      &__panel--right {
        width: 100%;
        max-width: none;
      }
    }
    .foundation-homepage-carousel {
      height: $section-height-mobile;
      max-height: $section-height-mobile;
      margin-bottom: rem(42px);

      &::after {
        display: none;
      }
      &__nav {
        width: 100%;
        padding-bottom: rem(16px);
        margin-top: rem(40px);
      }
      &__item {
        height: $section-height-mobile;
        max-height: $section-height-mobile;
        flex-direction: column;
        width: 100%;
        padding: rem(24px);

        &--flex-container {
          padding: 0px;
          margin: 0px;
          flex: 0 1 100%;
          min-height: 0;
        }
        &--body {
          padding: 0px 0px 0px rem(8px);
          margin: 0px;
          gap: rem(20px);
          justify-content: flex-start;
        }
        &--about {
          padding: 0px;
        }
        &--copy {
          justify-content: center;
          flex-grow: 0;
        }
        &--category {
          margin-bottom: rem(8px);
        }
        &--title {
          margin-bottom: rem(9px);
        }
        &--image-container {
          width: fit-content;
          height: fit-content;
          margin-bottom: rem(20px);
          padding-left: rem(8px);
          display: flex;
          flex-direction: column;
        }
        &--image {
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
          max-height: calc($section-height-mobile / 2);
          max-width: rem(400px);
          margin-bottom: rem(8px);
        }
        &--image-caption {
          background: none;
          height: auto;
          position: static;
          padding: 0px;
          span {
            color: $color-brand-dark;
          }
        }
        &--audio {
          &--menu {
            transform: translateX(-50%);
          }
        }
      }
    }
    .foundation-homepage-timeline {
      &__container {
        gap: rem(28px) rem(20px);
      }

      &__heading {
        font-size: rem(38px);
        margin-bottom: 2rem;
      }
      &__nav {
        margin-top: 2.5rem;
        padding: rem(40px);

        ul {
          width: 100%;
        }
        &--button {
          &--text {
            right: 50%;
            top: calc(100% + 1rem);
            transform: translateX(50%);
          }
          &::after {
            display: none;
          }
        }
      }
    }
    .foundation-homepage-fellow-modal {
      &__container {
        max-height: 100vh;
      }
    }

    .site-header {
      &.is-inview-header {
        & + .site-nav {
          .site-nav-close {
            top: 0;
          }
        }

        & ~ .site-search-panel {
          .site-search-panel-close {
            top: 0;
          }
        }
      }
    }
  }
}

@include screen($bp-mobile-small) {
  .page-foundation-homepage {
    .foundation-homepage-carousel {
      &__item {
        &--image {
          max-width: rem(105px);
        }
      }
    }
  }
}
