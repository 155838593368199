.page-alumni-profile-edit {
  padding-top: $section-spacing;
  background-color: $color-gray-med;

  .form-response {
    overflow: hidden;
  }

  &.form-submitted {
    .alumni-profile-edit-form {
      display: none;
    }

    .form-response {
      margin: 0 !important;
      padding: 0 4rem 4rem 4rem;

      @include screen($bp-mobile) {
        padding: 0 0 2rem 0;
      }
    }
  }

  .alumni-page-content-tabs {
    padding: $section-spacing-large 0;
    margin-top: 0;
  }
}

.alumni-page-content-tabs {
  margin-top: $section-spacing;

  .page-header-user-actions {
    margin-top: 0;
  }
}

.alumni-profile-edit-body {
  background-color: $color-gray-med;
}

.fellow-profile-edit-intro {
  margin-top: $section-spacing-small;
}

.alumni-profile-edit-form {
  margin-top: $section-spacing;
  padding-bottom: $section-spacing;
}

@include screen($bp-mobile) {
  .page-alumni-profile-edit {
    .alumni-page-content-tabs {
      padding: $section-spacing-large-mobile 0;
    }
  }
}
