.capabilities-children {
  margin-bottom: $section-spacing-large;
}

.capabilities-children-related-entity {
  margin-top: px-to-em($element-spacing-px, $font-size-buttons);

  &.reduced-margin {
    margin-top: px-to-em(8px);
  }
}

@include screen($bp-mobile) {
  .capabilities-children {
    margin-bottom: $section-spacing-large-mobile;
  }

  .capabilities-children-related-entity {
    &.reduced-margin {
      margin-top: px-to-em($element-spacing-px, $font-size-buttons);
    }
  }
}
