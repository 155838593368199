@use "sass:math";

.page-alumni-contact-faqs {
  background-color: $color-lightgray-background;
}

.form-overview {
  margin-bottom: math.div($section-spacing, 2);
}

.faqs {
  margin-bottom: $section-spacing;
}

.faq {
  margin-bottom: math.div($section-spacing, 2);
}

.faqs-more-info {
  margin-bottom: math.div($section-spacing, 2);
}

.alumni-faqs-links {
  .btn {
    margin-right: 20px;
  }
}
