$font-size-section-nav: 20px;
$section-nav-link-padding: 13px;
$section-nav-height: 72px;
$font-size-section-nav-toggle: 19px;
$section-nav-toggle-height: 70px;

.section-nav {
  background-color: $color-red-background;
  text-align: center;
  position: relative;
}

.section-nav-menu {
  display: table;
  border-left: 1px solid $color-white-background;
  margin: 0 auto;
}

.section-nav-menu-item {
  @include transition(border-color);

  border-right: 1px solid $color-white-background;
  display: table-cell;
  vertical-align: middle;

  &:hover {
    border-color: $color-white-background;
  }
}

//main menu
.section-nav-menu-link {
  @extend %uc;
  @include font-univers-condensed-bold($font-size-section-nav);

  line-height: 1.1;
  color: $color-white-on-red-foreground;
  display: table;
  height: px-to-em($section-nav-height, $font-size-section-nav);
  padding: 0 px-to-em($section-nav-link-padding, $font-size-section-nav);
  transition: $transition-default;

  &:hover,
  .is-menu-active & {
    background-color: $color-white-background;
    color: $color-red-on-white-foreground;
  }
}

.section-nav-menu-link-label {
  display: table-cell;
  vertical-align: middle;
}

.section-nav-toggle {
  @include font-univers-condensed-bold($font-size-section-nav-toggle);

  display: none;
  line-height: 1.2;
  width: 100%;
  height: px-to-em($section-nav-toggle-height, $font-size-section-nav-toggle);
  text-transform: uppercase;
  color: $color-link;
  transition: $transition-default;
  padding: 1.1em 1em 0.95em;
  position: relative;
  z-index: 10;
  background-color: $color-brand-white;
  border-bottom: 1px solid $color-gray-alt;

  &.show-full-menu {
    &:after {
      transform: rotateX(-180deg);
    }
  }

  @include ico-icon-after($icon-chevron-down-alt) {
    @include transition(transform);

    font-size: 0.5em;
    margin-top: 0.45em;
    margin-left: 1em;
    vertical-align: top;
  }

  &:hover {
    color: $color-text-gray-soft;
  }
}

@include screen($bp-mobile) {
  .section-nav {
    z-index: $zindex-scrollspy-nav;

    & + main .impactful-lead,
    & + .impactful-lead {
      z-index: $zindex-scrollspy-nav - 1;
    }

    &.show-full-menu {
      & .section-nav-menu {
        transform: translateY(0);
      }
    }
  }

  .section-nav-toggle {
    display: inline-block;
  }

  .section-nav-menu {
    @include transition(transform);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    display: block;
    border-left: 0;
  }

  .section-nav-menu-item {
    border-right: 0 none;
    display: block;

    &.is-menu-active {
      //display: none;
      //position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;

      .section-nav-menu-link {
        background-color: $color-red-background;

        .section-nav-menu-link-label {
          color: $color-brand-white;
        }

        &:hover {
          background-color: $color-white-background;
          color: $color-red-on-white-foreground;

          .section-nav-menu-link-label {
            color: $color-red-on-white-foreground;
          }
        }
      }
    }

    & + & {
      border-top: 1px solid $color-white-background;
    }
  }

  .section-nav-menu-link {
    /*Update Branding Color Enhancements Could fix 10 instance of an issue*/
    background-color: $color-red-background;
    width: 100%;
    font-size: px-to-em($font-size-section-nav-toggle);
    height: auto;
    min-height: px-to-em(
      $section-nav-toggle-height,
      $font-size-section-nav-toggle
    );
  }
}

#section-nav-anchor {
  height: 0px;
  top: -107px;
  position: relative;
}
