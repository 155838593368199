.page-homepage {
  &:before {
    display: none;
  }

  .site-header {
    transform: translate3d(0, -100%, 0);

    visibility: hidden;

    transition: visibility $transition-timing $transition-ease
        $transition-timing,
      transform $transition-timing $transition-ease;

    transition-duration: 0.45s;

    &.is-inview-header {
      @include transition(transform);

      transform: translate3d(0, 0, 0);
      visibility: visible;

      & ~ .site-nav {
        .site-nav-close {
          top: px-to-em($site-header-height);
        }
      }

      & ~ .site-search-panel {
        .site-search-panel-close {
          top: px-to-em($site-header-height);
        }
      }
    }

    .site-nav-close {
      @include transition(top);
    }
  }

  .main-content {
    padding-top: 0;

    .hero-complex__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .site-search-panel-close,
  .site-nav-close {
    top: 0;
  }

  &__section-module {
    background-color: $color-brand-white;

    padding-bottom: $section-spacing-large;
    padding-top: $section-spacing-largest;
    margin-bottom: 0;
  }
}

@include screen($bp-mobile) {
  .page-homepage {
    .main-content {
      padding-top: 0;
    }

    .site-header {
      &.is-inview-header {
        & + .site-nav {
          .site-nav-close {
            top: 0;
          }
        }

        & ~ .site-search-panel {
          .site-search-panel-close {
            top: 0;
          }
        }
      }
    }

    &__section-module {
      padding-bottom: $section-spacing-large-mobile;
      padding-top: $section-spacing-large-mobile;
    }
  }
}
