.js-lawyer-careers-mobile-nav,
.lawyer-careers-mobile-nav {
    // transform: translateY(-100%);
    // visibility: hidden;
    transition: transform .35s cubic-bezier(0.86, 0, 0.07, 1);

  &.show-menu {
    transform: none;
    // visibility: visible;
    transform: none !important;
    height: auto;
  }
}
