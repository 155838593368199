@use "sass:math";

$highlight-card-img-width: 38%;

.highlight-card {
  position: relative;
  background-color: $color-charcoal;
  padding-right: $highlight-card-img-width;
  width: 100%;
  height: px-to-em(300px);
  display: table;
  overflow: hidden;

  &.no-image {
    padding-right: 0;
  }
}

.highlight-card--full {
  padding-right: 0;

  .highlight-card-figure {
    max-width: px-to-em(430px);
    max-height: 100%;
  }
  .highlight-card-title,
  .highlight-card-abstract {
    padding-right: $highlight-card-img-width;
  }

  .highlight-card-link {
    padding: px-to-em($section-spacing-px) 0;
  }

  .highlight-card-title {
    @extend .section-heading;

    @include design-mark(
      $spacing: 10px,
      $font-size: $section-heading-size,
      $placement: before
    );

    em,
    i {
      font-family: $font-family-univers-condensed-light-oblique;
    }
  }
}

.highlight-card-wrapper {
  @include content-wrapper;
}

.highlight-card-figure {
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: $highlight-card-img-width;
}

.highlight-card-img {
  @include center(horizontal);

  bottom: 0;
  max-width: px-to-em(600px);
  max-height: px-to-em(400px);
  min-height: 100%;
  min-width: 100.05%;
}

.highlight-card-title {
  @include font-size-banner();

  padding-bottom: px-to-em($element-spacing-px, $font-size-banner);

  //red mark
  @include design-mark(
    $spacing: math.div($element-spacing-px, 2),
    $font-size: $font-size-banner,
    $placement: before
  );

  &:before {
    position: absolute;
    bottom: 0;
  }

  //outbound link
  .highlight-card--external & {
    @include ico-icon-after($icon-upward-arrow) {
      font-size: px-to-em(20px, $font-size-banner);
      margin-left: 0.5em;
    }
  }

  //download link
  .highlight-card--download & {
    @include ico-icon-after($icon-download) {
      font-size: px-to-em(24px, $font-size-banner);
      margin-left: 0.5em;
    }
  }
}

.highlight-card-link {
  $icon-size: 0.75;

  @include transition(background-color);

  display: block;
  color: $color-brand-white;
  height: 100%;
  width: 100%;
  padding: $element-spacing;
}

a.highlight-card-link {
  &:hover {
    background-color: $color-brand-red;
    cursor: pointer;

    .highlight-card-title {
      &:before {
        border-color: $color-brand-black;
      }
    }
  }
}

@include screen($bp-mobile) {
  .highlight-card {
    display: block;
    height: auto;
  }
  .highlight-card-title {
    padding-bottom: px-to-em($element-spacing-px, $font-size-banner-mobile);

    //adjust borde size and position
    &:before {
      width: px-to-em($global-line-mark, $font-size-banner-mobile);
      padding-top: px-to-em(
        math.div($element-spacing-px, 2),
        $font-size-banner-mobile
      );
    }
  }

  .highlight-card-link {
    padding: $section-spacing-small;
    display: block;
  }
}

@include screen($bp-mobile-landscape) {
  $highlight-card-max-width: px-to-em(320px);
  $highlight-card-max-height: px-to-em(275px);

  .highlight-card {
    padding-right: 0;
    max-width: $highlight-card-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  .highlight-card-figure {
    position: relative;
    right: auto;
    top: auto;
    height: auto;
    max-height: $highlight-card-max-height;
    width: 100%;
  }

  .highlight-card-img {
    position: relative;
    height: auto;
  }

  .highlight-card--full {
    .highlight-card-figure {
      max-width: none;
      margin-right: 0;
      display: inherit;
    }

    .highlight-card-link {
      padding-top: $section-spacing-mobile;
      padding-bottom: $section-spacing-mobile;
    }

    .highlight-card-title,
    .highlight-card-abstract {
      padding-right: 0;
    }
  }
}
