.job-header-compact {
  @include design-mark($border-color: $color-gray-steel);
}

.job-header-compact-title {
  @extend .section-heading;

  margin-bottom: px-to-em($element-spacing-small-px, $section-heading-size);
}

.job-header-compact-authors {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
  margin-top: 0.5em;
}

.job-header-compact-author {
  @include font-univers-condensed-bold();

  display: inline-block;
  color: $color-text-gray-soft;

  &:after {
    @include font-univers-condensed();
    content: ", ";
    color: $color-brand-black;
  }

  &:last-child {
    &:after {
      content: "";
    }
  }
}

.job-header-compact-author--contributors {
  display: inline;

  a {
    color: $color-link;

    &:hover {
      color: $color-link-hover;
    }
  }
}

a.job-header-compact-author {
  color: $color-brand-red;

  &:hover {
    color: $color-link-hover;
  }
}

.job-header-compact-meta {
  @include font-univers-condensed-light($font-size-subheading);

  line-height: $profile-line-height;
  color: $color-gray-steel;

  .meta-item {
    & + .meta-item {
      &:before {
        content: "//";
        display: inline-block;
        margin: 0 0.25em;
      }
    }
  }
}

.job-header-compact-related-item {
  @include font-size-entity-title;

  font-family: $font-family-univers-condensed;

  margin-top: $element-spacing-small;
}

.job-header-compact-related-label {
  color: $color-gray-steel;

  &:after {
    content: ":";
    margin-right: 0.5em;
  }
}

.job-header-compact {
  & ~ .social-links {
    margin-bottom: $section-spacing;

    .share-tools {
      display: inline-block;

      &:after {
        background-color: $color-brand-white;
      }
    }

    .share-tools-trigger {
      color: $color-brand-black;
    }

    .share-tools-link-icon {
      color: $color-brand-black;
    }

    //social links
    .social-links {
      margin-top: $section-spacing;

      @include design-mark(21px, 16px, $color-gray-steel, before);
    }

    .social-links-item {
      margin-right: 1em;
      margin-bottom: 1em;

      &:last-child {
        margin-right: 0;
      }
    }

    .social-links-icon {
      color: $color-brand-black;

      &:hover {
        color: $color-gray-steel;
      }
    }

    .ico-pdf {
      font-size: px-to-em(28px);
    }
  }
}

@include screen($bp-mobile) {
  .job-header-compact {
    text-align: center;

    &:after {
      margin-left: auto;
      margin-right: auto;
    }

    & ~ .social-links {
      margin-bottom: $section-spacing-mobile;
      text-align: center;
    }
  }

  .job-header-compact-meta {
    font-size: px-to-em($font-size-subheading-mobile);
  }

  .job-header-compact-authors {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}
