.job-body {
  margin-bottom: $section-spacing-large;
}

.job-body-detail-copy {
  & + & {
    margin-top: $section-spacing;
  }
}

.job-body-related-video {
  margin-top: $section-spacing;
}

.job-body-related-video-helper {
  margin-top: $element-spacing-small;
}

.job-body-apply {
  margin: px-to-em($section-spacing-px, $font-size-buttons) 0;

  @include ico-icon-after($icon-chevron-right) {
    margin-left: 0.75em;
    font-size: 0.65em;
  }
}

@include screen($bp-mobile) {
  .job-body {
    margin-bottom: $section-spacing-large-mobile;
  }

  .job-body-detail-copy {
    & + & {
      margin-top: $section-spacing-mobile;
    }
  }

  .job-body-related-video {
    margin-top: $section-spacing-mobile;
  }

  .job-body-apply {
    margin-top: px-to-em($section-spacing-mobile-px, $font-size-buttons-mobile);
  }
}
