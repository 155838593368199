//fellowship specific styles

.site-header--fellowship {
  height: px-to-em($site-header-fellowshipt-height);

  .site-logo {
    width: px-to-em(192px);
    padding: px-to-em(15px) px-to-em(22px) 0;
  }

  .mobile-toggle {
    display: none;
  }

  .site-header-nav {
    padding: 0;

    .site-header-nav-item {
      //padding: 0.5em 1em 0.5em 0.5em;

      &.is-active {
        background-color: $color-brand-red;

        .site-header-nav-title {
          color: $color-gray-light;
          &:hover {
            color: $color-brand-black;
          }
        }
      }
    }
  }
}

.site-header-secured-link {
  @include font-univers-condensed-bold($font-size-site-header-utility);

  @extend %uc;

  display: flex;
  align-items: center;
  color: $color-gray-steel;
  background-color: $color-gray-med;
  border-right: 1px solid $color-site-header-border;
  height: 100%;
  padding: 0 1.25em;

  &:hover {
    color: $color-brand-black;
  }

  &.is-active {
    background-color: $color-brand-red;
    color: $color-gray-light !important;
    &:hover {
      color: $color-brand-black !important;
    }
  }

  &.is-current {
    color: $color-brand-black;
  }
}

@include screen($bp-site-header-fellowship-tablet) {
  .site-header--fellowship {
    background-color: $color-brand-gray;

    .mobile-toggle {
      display: inline-block;
      background-color: $color-brand-white;
      border-color: $color-brand-white;
      width: px-to-em($site-header-fellowshipt-height);
      padding-top: 0.25em;
    }

    .mobile-toggle-bars {
      $bars-height: px-to-em(3px);
      $bars-width: px-to-em(35px);
      $bars-spacing: px-to-em(5px);

      background-color: $color-black-background;
      width: $bars-width;
      height: $bars-height;

      &:before,
      &:after {
        background-color: $color-black-background;
      }

      &:before {
        margin-bottom: $bars-spacing;
      }

      &:after {
        margin-bottom: $bars-spacing * 2 + $bars-height;
      }
    }

    .mobile-toggle-label {
      display: block;
      margin-left: 0.1em;
      margin-top: 0.2em;
      color: $color-brand-black;
      font-size: px-to-em(14px);
    }

    .site-header-nav,
    .site-header-secured-link {
      display: none;
    }
  }
}

@include screen($bp-mobile) {
  .site-header--fellowship {
    .mobile-toggle {
      padding-top: 0;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .site-header--fellowship {
    display: flex;
    height: px-to-em($site-header-height-mobile);

    .site-header-utility {
      display: block;
    }

    .mobile-toggle {
      width: px-to-em($site-header-ui-width-mobile);
      float: none;
    }

    .mobile-toggle-bars {
      $bars-width: px-to-em(32px);
      $bars-height: px-to-em(3px);
      $bars-spacing: px-to-em(6px);

      width: $bars-width;
      height: $bars-height;
      margin-top: 1.25em;

      &:before,
      &:after {
        height: $bars-height;
      }

      &:before {
        margin-bottom: $bars-spacing;
      }

      &:after {
        margin-bottom: $bars-spacing * 2 + $bars-height;
      }
    }

    .site-logo {
      width: px-to-em(150px);
      padding: px-to-em(8px) px-to-em(18px) 0;
    }
  }
}
