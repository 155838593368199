.exposed-search-filters {
  position: relative;
  margin-top: $section-spacing;

  .form--layout-thirds {
    .form-field {
      &:first-child:last-child {
        width: 40%;
        float: none;
        margin-right: 0;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  @include screen($bp-mobile) {
    .form--layout-thirds {
      .form-field {
        &:first-child:last-child {
          width: 80%;
        }
      }
    }
  }
}
