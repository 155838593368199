$office-listing-grid-spacing: 2px;
$office-listing-min-height: 300px;

.locations-listing-group {
  margin-bottom: $section-spacing-large;
}

.locations-listing-group-header {
  @include bg-cover;

  margin-bottom: $office-listing-grid-spacing;
  // min-height: $office-listing-min-height;
  padding: 23% px-to-em($office-tile-padding) px-to-em($office-tile-padding)
    px-to-em($office-tile-padding);
  position: relative;
  color: $color-brand-white;

  &:before {
    @include absolute-full;
    @include gradient($color-nero-25, $color-nero, 180deg, 200%);
    content: "";
  }

  .section-heading {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    margin-top: px-to-em($section-heading-spacing, $section-heading-size);
  }
}

.locations-listing-group-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-right: $office-listing-grid-spacing * -1;

  .office-tile {
    width: 50%;
    border-right: $office-listing-grid-spacing solid $color-brand-white;
    border-bottom: $office-listing-grid-spacing solid $color-brand-white;
  }
}

@include screen($bp-mobile) {
  .locations-listing-group {
    margin-bottom: $section-spacing-large-mobile;
  }
}

@include screen($bp-mobile-landscape) {
  .locations-listing-group-header {
    padding: 25% px-to-em($office-tile-padding-mobile)
      px-to-em($office-tile-padding-mobile)
      px-to-em($office-tile-padding-mobile);
  }

  .locations-listing-group-grid {
    display: block;
    margin-right: 0;

    .office-tile {
      width: 100%;
      border-right: 0;
    }
  }
}
