$credentials-grid-spacing: 2px;

.credentials {
  margin-bottom: $section-spacing-large;
}

.credentials-heading {
  margin-bottom: px-to-em($section-spacing-px, $section-heading-size);
}

.credentials-grid {
  columns: 2 auto;
  column-gap: $credentials-grid-spacing;

  .credential-item {
    break-inside: avoid;
    overflow: hidden;
    border-bottom: 2px solid $color-brand-white;
    display: block;
    width: 100%;
  }
}

.credentials-grid-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

@include screen($bp-tablet) {
  .credentials-grid {
    columns: auto;
  }
}

@include screen($bp-mobile) {
  .credentials {
    margin-bottom: $section-spacing-large-mobile;
  }

  .credentials-heading {
    margin-bottom: px-to-em(
      $section-spacing-mobile-px,
      $section-heading-mobile-size
    );
  }
}

@include screen($bp-mobile-landscape) {
  .credentials-grid {
    columns: auto;
  }
}
