.capabilities-list-capability {
  display: inline;
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.4;

  &:after {
    content: " // ";
    display: inline-block;
    margin: 0 0.2em;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

.capabilities-list-link {
  color: $color-red-on-white-foreground;
  display: inline-block;

  &:hover {
    color: $color-link-hover;
  }
}

@include screen($bp-mobile) {
  .capabilities-list-capability {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .capabilities-list-link {
    display: inline;
  }
}

.section-capabilities {
  .filters {
    margin-bottom: $section-spacing;
    padding-bottom: 3px;
    border-bottom: 1px solid $color-charcoal;
  }

  .filters__title {
    $font-size: 17px;
    @include font-univers-condensed-bold($font-size);

    margin-right: 0.25em;
    text-transform: uppercase;
  }

  .capability-list {
    opacity: 0;
    height: 0;
    margin-top: 0;

    .capability-list-item {
      display: none;
    }

    transition: opacity $transition-timing ease-in-out,
      height $transition-timing ease-in-out;

    &.is-active {
      opacity: 1;
      height: auto;

      .capability-list-item {
        display: list-item;
      }
    }
  }

  .filters__option {
    display: inline-block;
    color: $color-brand-black;
    position: relative;
    margin-left: 0.5em;
    text-align: center;
    line-height: 1.2;

    @include font-univers-condensed-light($font-size-entity-title);

    &:first-child {
      margin-left: 0;
    }

    &:before {
      @include transition(opacity);

      content: "";
      display: block;
      position: absolute;
      top: 100%;
      margin-top: px-to-em(5px, $font-size-entity-title);
      width: 100%;
      opacity: 0;
      border-bottom: $global-line-thickness solid $color-brand-red;
    }

    &:hover,
    &.is-selected {
      &:before {
        opacity: 1;
      }
    }

    &:hover {
      color: $color-link-hover;
    }
  }
}
