.listing-files-option {
  display: inline;
}

.listing-files-title {
  @include font-size-entity-title();

  display: inline-block;
  padding: 0.8em 1em 0.65em;
  margin-right: 0.25em;
  margin-bottom: 0.4em;
  color: $color-red-on-white-foreground;
  border: 1px solid $color-gray-steel;
  transition: $transition-default;
  background-color: $color-brand-white;

  &:hover {
    background-color: $color-gray-light;
    color: $color-link-hover;
  }

  @include ico-icon-after($icon-download) {
    font-size: 0.8em;
    margin-left: 0.75em;
  }
}

@include screen($bp-mobile-landscape) {
  .listing-files-option {
    display: block;
  }

  .listing-files-title {
    width: 100%;
    text-align: center;
  }
}
