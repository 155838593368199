.job-board {
  margin-bottom: $section-spacing-large;
}

.job-board-filter-by {
  margin-bottom: $section-spacing;

  .select-dropdown {
    vertical-align: middle;
    display: inline-block;
    max-width: px-to-em(280px);
    width: 100%;
  }

  .select-dropdown-toggle {
    border-width: 0 0 1px;
    padding: $element-spacing-small 0;

    &:after {
      right: 0;
    }
  }

  .select-dropdown-label {
    &:after {
      content: ":";
      margin-right: 1em;
    }
  }
}

.job-board-view-more {
  display: block;
  margin: $section-spacing auto 0;
}

@include screen($bp-mobile) {
  .job-board {
    margin-bottom: $section-spacing-large-mobile;
  }

  .job-board-view-more {
    margin-top: $section-spacing-mobile;
  }

  .job-board-filter-by {
    max-width: px-to-em(320px);
    margin-bottom: $section-spacing-mobile;
  }

  .job-board-filter-button {
    max-width: none;
    width: 100%;
  }
}

.job-board-filter-button-wrapper {
  display: inline-block;
  margin-left: 2em;
  @include screen($bp-mobile) {
    display: block;
    margin-top: 1em;
    margin-left: 0;
    width: auto;
  }
}

.lawyer-careers-job-board {
  .job-board-filter-by,
  .select-dropdown {
    @include screen($bp-mobile) {
      max-width: none;
    }
  }

  .select-dropdown {
    cursor: pointer;

    .select-dropdown-toggle {
      text-align: left;
      @include transition();
    }

    &:hover {
      .select-dropdown-toggle,
      .select-dropdown-toggle:after {
        color: $color-brand-red;
      }
    }
  }

  .job-board-filter-by {
    margin-bottom: 2em;
    padding-right: $arrow-padding * 2;
  }

  .filter-label {
    text-transform: uppercase;
  }

  .applied-filters-list {
    display: block;
    margin-top: 1em;
    box-sizing: border-box;
    font-size: 1.2em;
    // border-bottom: $border-dark;
    padding-bottom: px-to-em(5px);
  }

  .applied-filters-list-region {
    display: inline;
  }
}

label.region-filter {
  font-weight: bold;
}
