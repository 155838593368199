button,
.btn {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0;
  margin: 0;
  transition: $transition-default;
}

.btn-primary {
  @include font-size-buttons();
  color: $color-brand-white;
  background-color: $color-brand-red;
  border-color: $color-brand-red;
  border-radius: 0;
  padding: 0.8em 3em;

  &:hover {
    background-color: $color-black-background;
    border-color: $color-brand-black;
  }
}

.btn-text-link {
  @include font-size-buttons();

  text-transform: uppercase;
  color: $color-brand-red;

  &:hover {
    color: $color-link-hover;
  }
}

.btn-text-link-arrow-unstyled {
  @include transition(color);
  color: $color-link;
  padding-right: 1em;
  &:hover {
    color: $color-link-hover;

    &:after {
      transform: translateX(100%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include transition(transform);
    color: inherit;
    font-size: 0.7em;
    position: absolute;
    margin-top: 0.6em;
    margin-left: 0.7em;
    vertical-align: top;
  }
}

.btn-text-link-arrow {
  @include font-size-buttons();

  @include transition(color);

  display: inline-block;
  color: $color-red-on-white-foreground;
  text-transform: uppercase;
  padding-right: 1em;

  &:hover {
    color: $color-link-hover;

    &:after {
      transform: translateX(100%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include transition(transform);

    color: inherit;
    font-size: 0.7em;
    position: absolute;
    margin-top: 0.3em;
    margin-left: 1em;
    vertical-align: top;
  }

  &.smaller {
    font-size: 1em;
  }
}

.btn-text-link-arrow-left {
  @include font-size-buttons();
  @include transition(color);

  display: inline-block;
  color: $color-link;
  text-transform: uppercase;
  padding-left: 1em;

  &:hover {
    color: $color-link-hover;

    &:before {
      transform: translateX(-50%);
    }
  }

  @include ico-icon-before($icon-chevron-left) {
    @include transition(transform);

    color: inherit;
    font-size: 0.7em;
    position: absolute;
    margin-top: 0.25em;
    margin-left: -1.4em;
    vertical-align: middle;
    line-height: normal;
  }
}

.btn-text-link-arrow-left {
  @include font-size-buttons();
  @include transition(color);

  display: inline-block;
  color: $color-link;
  text-transform: uppercase;
  padding-left: 1em;

  &:hover {
    color: $color-link-hover;

    &:before {
      transform: translateX(-50%);
    }
  }

  @include ico-icon-before($icon-chevron-left) {
    @include transition(transform);

    color: inherit;
    font-size: 0.7em;
    position: absolute;
    margin-top: 0.25em;
    margin-left: -1.4em;
    vertical-align: middle;
    line-height: normal;
  }
}

.btn-text-load-more {
  @include font-size-buttons();
  @include transition(color);

  display: inline-block;
  color: $color-red-on-white-foreground;
  text-transform: uppercase;

  &:hover {
    color: $color-link-hover;
  }

  @include ico-icon-after($icon-plus) {
    font-size: 0.65em;
    margin-left: 0.5em;
    margin-top: -0.25em;
    vertical-align: middle;
    line-height: 1; // 1 + (1 - font-size) to keep it centered
  }
}

@include screen($bp-mobile) {
  .btn-text-link-arrow {
    &:after {
      margin-top: 0.2em;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn--centered {
  margin: $section-spacing-small auto 0;
  display: block;
}

.btn-external-link {
  @include font-size-entity-title();

  display: inline-block;
  padding: 0.8em 1em 0.65em;
  margin-top: $section-spacing;
  margin-right: 0.25em;
  margin-bottom: 0.4em;
  color: $color-link;
  border: 1px solid $color-gray-steel;
  transition: $transition-default;

  &:hover {
    background-color: $color-gray-light;
    color: $color-link-hover;
  }

  &.icon-external {
    @include ico-icon-after($icon-upward-arrow) {
      font-size: 0.8em;
      margin-left: 0.75em;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .btn-external-link {
    width: 100%;
    text-align: center;
  }
}
