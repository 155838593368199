﻿.page-alumni-profile {
  .social-links {
    display: inline-block;
  }

  .deceased-label {
    display: inline-block;
    margin-left: 0.5em;
    position: relative;
    right: 0;
    bottom: 0;
  }
}
