@use "sass:math";

.page-title {
  margin: $section-spacing-small 0;
}

.program-info {
  margin-bottom: $section-spacing;
}

.program-desc {
  margin-bottom: $section-spacing-small;
}

.question-set {
  margin-bottom: $section-spacing-small;
}

.question {
  @extend .form-label;

  margin-bottom: math.div($section-spacing-small, 2);
  margin-right: 15px;
}

.question__topic {
  @include font-univers-condensed-bold();

  &:after {
    content: ":";
    margin-left: 1px;
  }
}

.cle-verification-controls {
  @include clearfix;

  margin-bottom: $section-spacing;
}

.form-message {
  margin-bottom: $section-spacing-small;
}
