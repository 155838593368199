$promo-card-max-width: 400px;

.promo-card {
  background-color: $color-charcoal;
  color: $color-brand-white;
  text-align: center;
  padding: 7% 3%;
  padding: 7vw 3%;
  position: relative;
}
.promo-card-rte {
  @include font-size-banner();

  max-width: px-to-em($promo-card-max-width, $font-size-banner);
  margin: 0 auto;
}

.promo-card-link {
  @include font-size-entity-title();

  display: inline-block;
  color: $color-link;
  padding-right: $arrow-padding * 0.75;
  margin-top: px-to-em($section-spacing-small-px, $font-size-entity-title);
  max-width: px-to-em($promo-card-max-width, $font-size-entity-title);
  position: relative;

  &:hover {
    color: $color-text-gray-soft;

    //arrow
    &:after {
      transform: translateX(100%) translateY(-50%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);
    @include transition(transform);

    font-size: px-to-em($arrow-icon-size, $font-size-entity-title);
    right: 0;
  }
}
