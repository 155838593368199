$main-menu-size: 42px;
$main-submenu-size: 31.5px;
$social-links-size: px-to-em(21px);

$bp-navigation-tablet: 1000px;

.site-nav {
  position: fixed;
  top: 0;
  height: 100%;
  left: calc(50% + #{$site-panel-logo-offset});
  right: 0;
  background-color: $color-black-background;
  z-index: $zindex-site-panel-ui;
  padding: 8% 5% 0;
  transform: translateX(100%);
  text-align: center;
  visibility: hidden;

  transition: visibility $transition-timing $transition-ease $transition-timing,
    transform $transition-timing $transition-ease;

  //social media links
  .social-links {
    margin-top: px-to-em(61px);
  }

  .social-links-item {
    margin-right: 1.3em;

    &:last-child {
      margin-right: 0;
    }
  }

  .social-links-icon {
    font-size: $social-links-size;
    color: $color-brand-white;

    &:hover {
      color: $color-text-gray-soft;
    }
  }

  &.show-site-nav {
    transform: translateX(0);

    visibility: visible;

    @include transition(transform);
  }
}

.site-nav-wrapper {
  @include center(vertical, relative);
}

//common link styles
.site-nav-menu-item {
  &:before {
    content: "\200B";
  }
  & + & {
    margin-top: px-to-em(22px);
  }
}

.site-nav-submenu-item {
  &:before {
    content: "\200B";
  }

  & + & {
    margin-top: px-to-em(17.5px);
  }
}

.site-nav-menu-link,
.site-nav-submenu-link {
  color: $color-brand-white;

  &:hover {
    color: $color-text-gray-soft;
  }
}

//main menu
.site-nav-menu-link {
  @extend %uc;

  @include font-univers-condensed-bold($main-menu-size);
  line-height: 1.1;
}
//sub menu
.site-nav-submenu {
  $line-spacing: px-to-em(25px);
  $line-width: px-to-em(44px);
  $line-height: px-to-em(2px);

  margin-top: $line-spacing;

  //design line
  &:before {
    content: "";
    background-color: $color-brand-gray;
    display: inline-block;
    width: $line-width;
    height: $line-height;
    margin-bottom: $line-spacing;
  }
}

.site-nav-submenu-link {
  @extend %uc;

  @include font-univers-condensed-light($main-submenu-size);
  line-height: 1.1;
}

//close icon
.site-nav-close {
  position: absolute;
  right: 0;
  top: px-to-em($site-header-height);
  margin: 5% 5% 0 0;
  color: $color-brand-white;

  @include ico-icon-before($icon-cross) {
    font-size: px-to-em(22px);
    line-height: 1;
  }

  &:hover {
    color: $color-text-gray-soft;
  }
}

//fellowshipt specific styles
.site-nav--fellowship {
  background-color: $color-brand-white;

  .site-nav-menu-link,
  .site-nav-submenu-link,
  .site-nav-close {
    color: $color-gray-steel;

    &:hover {
      color: $color-brand-black;
    }
  }

  .is-current {
    color: $color-brand-black;
  }
}

@include screen($bp-desktop-large) {
  $main-menu-size: px-to-em(33px);
  $main-submenu-size: px-to-em(24px);

  .site-nav-menu-link {
    font-size: $main-menu-size;
  }

  .site-nav-submenu-link {
    font-size: $main-submenu-size;
  }
}

@include screen($bp-navigation-tablet) {
  $main-menu-size: px-to-em(30px);
  $main-submenu-size: px-to-em(20px);

  .site-nav-menu-link {
    font-size: $main-menu-size;
  }

  .site-nav-submenu-link {
    font-size: $main-submenu-size;
  }
}

@include screen($bp-tablet) {
  .site-nav {
    left: 0;
  }
}

@include screen-height($bp-height-menu-desktop) {
  $main-menu-size: px-to-em(30px);
  $main-submenu-size: px-to-em(20px);

  .site-nav {
    left: 0;
    z-index: $zindex-site-header + 5;
    overflow-y: auto;

    .social-links {
      margin-top: 1em;
    }
  }

  .site-nav-close {
    top: 0;
  }

  .site-nav-menu-link {
    font-size: $main-menu-size;
  }

  .site-nav-submenu-link {
    font-size: $main-submenu-size;
  }
}

@include screen($bp-mobile) {
  .site-nav {
    z-index: $zindex-site-header + 5;
    overflow-y: auto;
  }

  .site-nav-close {
    top: 0;
  }
}

@include screen-height(290px) {
  .site-nav {
    padding: 2% 0 6%;
  }

  .site-nav-wrapper {
    top: auto;
    transform: translateY(0);
  }
}
