.site-search-toggle {
  width: $site-header-ui-width;
  height: px-to-em($site-header-height);
  background-color: $color-brand-white;
  text-align: center;
  color: $color-text-gray;
  transition: background-color $transition-timing ease-in-out;

  &:hover {
    background-color: $color-gray-alt;
  }

  @include ico-icon-before($icon-search) {
    font-size: px-to-em(17px);
  }
}

.site-search-toggle-label {
  @extend %uc;

  font-size: px-to-em($site-header-ui-font-size);
  letter-spacing: 0.1em;
  margin-left: 0.5em;
  vertical-align: bottom;
}

@include screen($bp-mobile) {
  .site-search-toggle {
    color: $color-text-base;
  }
}
