.lawyer-application-overview {
  margin: 1em 0 1.5em 0;

  //Accessibility update that will style h2 tag to resemble normal rich text
  h2 {
    font-size: 1em;
    line-height: $font-line-height;
    font-family: $font-family-copy;
  }
}

.form-submitted {
  .lawyer-application-overview {
    display: none;
  }
}
