.site-search-panel {
  position: fixed;
  top: 0;
  height: 100%;
  left: calc(50% + #{$site-panel-logo-offset});
  right: 0;
  background-color: $color-brand-white;
  z-index: $zindex-site-panel-ui;
  padding: 0 5%;
  transform: translate3d(100%, 0, 0);

  transition: visibility $transition-timing $transition-ease $transition-timing,
    transform $transition-timing $transition-ease;

  visibility: hidden;

  &.show-searchbox {
    transform: translate3d(0, 0, 0);
    visibility: visible;

    @include transition(transform);
  }

  .ios-device & {
    overflow: hidden;
  }
}

.site-search-panel-close {
  position: absolute;
  right: 0;
  top: px-to-em($site-header-height);
  margin: 5% 5% 0 0;

  @include ico-icon-before($icon-cross) {
    font-size: px-to-em(22px);
    line-height: 1;
  }

  // .close-search-label {
  // 	@extend %hide-label;
  // }
}

.site-search-panel-searchbox {
  top: 40%;
  height: px-to-em($site-searchbox-height);

  .searchbox-input {
    @include center(vertical, relative);
  }

  .ios-device & {
    transform: translate3d(0, 0, 0);
    top: auto;
    margin-top: px-to-em($site-header-height * 2.5);
  }
}

@include screen($bp-tablet) {
  .site-search-panel {
    left: 0;
  }
}

@include screen-height($bp-height-menu-desktop) {
  .site-search-panel-close {
    top: 0;
  }
}

@include screen($bp-mobile) {
  .site-search-panel-searchbox {
    height: auto;
    padding: $section-spacing-small $section-spacing-small
      $section-spacing-small * 1.25;

    .searchbox-input {
      top: auto;
      transform: translate(0, 0);
    }

    .ios-device & {
      margin-top: px-to-em($site-header-height);
    }
  }
  .site-search-panel {
    z-index: $zindex-site-header + 5;
  }

  .site-search-panel-close {
    top: 0;
  }
}
