$applied-filters-spacing: 0.4em;

.applied-filters-label {
  $font-size: 17px;

  @include font-univers-condensed-bold($font-size);

  line-height: px-to-em(28px, $font-size);
  text-transform: uppercase;
  float: left;

  &:after {
    content: ":";
  }
}

.applied-filters-list {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
  display: table;
}

.applied-filter {
  display: inline-block;
  margin-left: $applied-filters-spacing;
}

.applied-filter-remove {
  color: $color-link;

  &:hover {
    color: $color-link-hover;
  }

  @include ico-icon-after($icon-close) {
    font-size: 0.5em;
  }
}

.applied-filter-clear {
  color: $color-link;
  margin-left: $applied-filters-spacing;

  &:hover {
    color: $color-link-hover;
  }
}

@include screen($bp-mobile) {
  .applied-filters-label {
    font-size: 1em;
    margin-top: 0;
  }

  .applied-filters-list {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}

@include screen($bp-mobile-small) {
  .applied-filters-label {
    float: none;
  }

  .applied-filter {
    margin-right: $applied-filters-spacing * 2;
    margin-left: 0;
  }
}
