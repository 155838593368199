.hero-explore {
  &:before {
    display: none;
  }

  //placement of Close icon
  .site-nav {
    padding-top: 5%;
  }

  .site-nav-close {
    margin-top: 5%;
  }

  .site-search-panel-close {
    margin-top: 5%;
  }
}

.is-inview-header {
  & + .site-nav {
    padding-top: 8%;

    // .site-nav-close {
    // 	margin-top: 16%;
    // }
  }

  & ~ .site-search-panel {
    padding-top: 8%;

    // .site-search-panel-close {
    // 	margin-top: 16%;
    // }
  }
}

@include screen($bp-tablet) {
  .is-inview-header {
    .site-search-panel-close {
      margin-top: 5%;
    }

    & + .site-nav {
      padding-top: 5%;

      .site-nav-close {
        margin-top: 5%;
      }
    }

    & ~ .site-search-panel {
      padding-top: 5%;

      .site-search-panel-close {
        margin-top: 5%;
      }
    }
  }
}
