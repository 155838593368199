.lawyer-careers-nav-offset {
  padding-top: $secondary-nav-height;
}
.lawyer-careers-navigation.block-nav,
.stick-float-nav .lawyer-careers-navigation.top-nav {
  //for the regular pages and the fancy page after scroll. Changed to sticky positioning
  position: fixed !important;
  top: $header-height !important;
  display: block;
  height: $float-nav-height !important;
  //above in-page nav for interior pages on mobile, below header, below expanded site search and menu
  z-index: 440 !important;
  
  @include max-width-sm {
    .nav-item,
    .nav-item-inner {
      height: 100%;
    }
    .nav-item-content {
      vertical-align: middle;
    }

    .nav-item.title {
      position: relative;
      z-index: 500;
    }

    .nav-items {
      display: block;
      transform: translateY(-100%);
      height: auto !important;
    }

    transition: transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);

    &.show-menu {
      transform: none;
      // visibility: visible;
      transform: none !important;
      height: auto;
    }
  }
}

.lawyer-careers-navigation {
  position: relative;

  .title {
    display: none;
  }

  .pretitle {
    display: block;
    text-transform: uppercase;
  }

  li {
    display: inline-block;
  }

  &.navigation-footer {
    margin-top: $spacing;
    padding: $spacing 0 0 0;
    border-top: 1px solid $color-footer-border;
    @include font-size($font-size-md);

    li {
      text-transform: uppercase;
      line-height: 1em;
      border-right: 1px solid $color-footer-border;
      margin-right: 0.5em;

      &:last-child {
        border: none;

        a {
          padding-right: 0;
        }
      }
    }

    a {
      padding: 0 0.75em 0 0;
    }
  }

  &.navigation-sites {
    .title {
      display: block;
      text-transform: uppercase;
    }

    li {
      display: block;
    }
  }
}
