@use "sass:math";

.insightful-cards {
  $grid-spacing: px-to-em(3px);
  text-align: center;

  &__grid-cell {
    // max-width: px-to-em(340px);
    margin-bottom: $grid-spacing;
    text-align: left;
    width: 100%;

    // margin-left: auto;
    // margin-right: auto;
    &--accordion {
      cursor: pointer;
    }
  }

  &__grid {
    &-mobile-tray {
      display: none;
      overflow: hidden;

      background: $color-brand-black;
      color: $color-brand-white;
      $padding-horizontal: 20px;
      padding: px-to-em(30px) px-to-em($padding-horizontal);

      &__close {
        text-align: right;
        color: $color-brand-red;
        margin-bottom: 6px;
      }
    }
  }

  &__grid-tray {
    // width: 100%;
    display: none;
    overflow: hidden;
    margin-bottom: $grid-spacing;
    background: $color-brand-black;
    color: $color-brand-white;
    position: relative;
    box-sizing: content-box;
    width: 100%;

    &__close {
      position: absolute;
      right: math.div($section-spacing-large, 2);
      top: math.div($section-spacing-large, 2);
      cursor: pointer;

      &:before {
        font-size: 2em;
        color: $color-brand-red;
      }
    }

    &__wrapper {
      padding: $section-spacing-large;
    }

    &__item {
      visibility: hidden;
      display: none;

      &--visible {
        visibility: visible;
        display: block;
      }
    }
  }

  @include screen($bp-mobile, min) {
    &__grid {
      display: flex;
      flex-wrap: wrap;
      margin-right: $grid-spacing * -1;
      justify-content: space-between;
    }

    &__grid-cell {
      width: calc(50% - 1px);
      display: flex;
      max-width: none;
      margin-left: 0;
    }
  }
}
