@use "sass:math";


.lawyer-careers-navigation {
    &.navigation-float {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: $z-index-float-nav;
        background-color: $color-grey-zero;

        @include translate3DFix();
        @include transition(top .3s ease-in, bottom .3s ease-out);

        .nav-item {
            display: block;
            color: $color-brand-light;
        }

        .nav-item-inner {
            display: block;
            color: inherit;
        }
    }

    &.float-bottom {
        position: relative;
        width: 100%;
        height: $float-nav-height;
        text-align: center;
        box-sizing: border-box;
        padding: 0;
        top: auto;
        bottom: 0;
        //on mobile, account for the panel's facts pushing the height over 100%
        @include careers-mobile-only() {
            position: relative;
        }

        .nav-item-inner {
            width: auto;
            padding: $spacing * 0.5;
            padding-top: ($spacing * 0.5) + 2px;
            //spacing for absolute arrows
            padding-right: 40px;
            white-space: nowrap;

            @media screen and (max-width: $screen-md) and (min-width: $screen-sm) {
                padding-left: 4px;
            }
        }

        .nav-items {
            background-color: $color-grey-zero;
            // border-top: 1px solid $color-grey-three;
            display: flex;
            width: 100%;
            height: 100%;
        }

        .nav-item {
            display: inline-block;
            flex: 1;
            padding: 0;
            background-color: $color-grey-one;
            text-transform: uppercase;
            @include transition(translate .2s ease-in);

            &.title {
                margin: 0;
                font-size: inherit;
                border: none;
                display: none;
            }

            .icon {
                @include ico-icon-before($icon-chevron-right-thin);
                font-size: 22px;
                // color: $color-brand-one;
                color: $color-brand-light;
                margin: 0 0 0 .3em;
                position: absolute;
                top: 8px;

                @include max-width-md {
                    margin-left: .2em;
                    font-size: 20px;
                    top: 10px;
                }
            }

            &:hover {
                background-color: lighten($color-grey-one, 5%);
            }

            &.active {
                background-color: $color-grey-zero;
                color: lighten($color-brand-one, 5% );
                // color: $color-brand-one;
                font-weight: bold;

                .icon {
                    @include ico-icon-before($icon-chevron-down-thin)
                }
            }

            &.accent {
                @include min-width-md {
                    //match with search+menu width.
                    //account for side padding here, but not for when it is fixed on top
                    flex: initial;
                    width: $site-header-ui-width * 1.9; //calc fail fallback
                    width: calc( #{$site-header-ui-width * 1.99} - 20px);
                }

                background-color: $color-brand-one;
                text-transform: uppercase;

                .icon {
                    color: $color-brand-light;
                }

                &.active {
                    background-color: lighten($color-brand-one, 5%);
                    color: $color-brand-light;
                }
            }
        }

        @include max-width-sm {
            // height: auto;
            .nav-items {
                flex-direction: column;
                /*                position: absolute;
            display: block;
            top: -500px;
            transition: all .8s ease;

            &--open {
                top: 45px;
            }*/
                .icon {
                    display: none;
                }
            }

            .nav-item {
                display: block;
                flex: none;
                border-bottom: 1px solid $color-grey-three;
                // dont show active item since the menu does
                &.active {
                    display: none;
                }

                .icon {
                    float: right;
                }

                &.title {
                    display: block;
                    width: 100%;

                    .icon {
                        top: 9px;
                    }
                }

                &.accent {
                    width: auto;

                    .nav-item-content {
                        display: block;
                    }
                }
            }
        }

        .stick-float-nav & {
            position: fixed;
            top: $header-height;
            margin-top: 0 !important;
            //@include translate(0, -103%);
            @include animation( slidein .3s linear );

            @keyframes slidein {
                from {
                    @include translate(0, -100%);
                }

                to {
                    @include translate(0, 0);
                }
            }

            @include max-width-md {
                top: $header-height-mobile;
            }
        }
        //for interior pages and sticky top on .carve-your-path-page
        .stick-float-nav &, &.block-nav {
            @include min-width-md {
                .accent {
                    //match with search+menu width, no padding
                    width: $site-header-ui-width * 1.99;
                }
            }
        }
    }

}

//changed to stop transition flipping around on sticky nav transition
// .carve-your-path-page .para-container:not(.stick-float-nav) .lawyer-careers-navigation
//this sacrifices the upward transition
.carve-your-path-page .para-container .lawyer-careers-navigation:not(.float-side) {
    @include max-width-sm {

        .nav-items {
            visibility: hidden;
            height: auto;

            &.show-menu {
                visibility: visible;
            }
        }
    }
}

.carve-your-path-page .lawyer-careers-navigation.float-bottom {
    //margin-top: -$float-nav-height;
    @include careers-short-height-only {
        @include when-ios-scroll-fix {
            //the various styles for lining up the nav bar to the menu results in horrible artifacts on ios
            .nav-item {
                flex: 1 !important;
                width: auto !important;
            }
        }
    }
}

.carve-your-path-page .arrow-bounce {
    animation: arrow-bounce 2s infinite;
}

.carve-your-path-page .arrow-bounce-finished {
    //attempt to stop the animation smoothly
    //but it seemed to kill it harshly anyways and of course didn't work in IE
    // animation-iteration-count: 1;
    animation: none;
}

$bounce-height: 5px;

@keyframes arrow-bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY($bounce-height);
    }

    60% {
        transform: translateY($bounce-height * 0.5 );
    }
}
