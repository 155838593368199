.article-header {
  position: relative;
  background-color: $color-black-background;
  padding: $section-spacing-large 0;

  .page-wrapper {
    position: relative;
  }
}

.article-header-details {
  color: $color-brand-white;
  padding-right: $site-padding * 1.25;
}
.article-header-title {
  @include font-size-heading();
  padding-right: 0.5em;
}
.article-header-meta {
  //@include font-univers-condensed-light-oblique($font-size-h3);
  font-size: $font-size-h3;

  line-height: $profile-line-height;

  @include design-mark(17px, $font-size-h3, $color-brand-gray);

  .meta-item {
    & + .meta-item {
      &:before {
        content: "//";
        display: inline-block;
        margin: 0 0.5em;
      }
    }
  }
}

.article-header-slogan {
  @include font-univers-condensed-light-oblique($font-size-h3);
  font-size: px-to-em($font-size-entity-title);
  letter-spacing: 0.04em;
  line-height: 1.2;
}

.article-header-authors {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;

  margin: 0.5em 0 0;
}

.article-header-author {
  @include font-univers-condensed-bold();

  display: inline-block;
  color: $color-red-on-black-foreground;

  &:hover {
    color: $color-brand-white;
  }

  &:after {
    @include font-univers-condensed();
    content: ", ";
    color: $color-brand-white;
  }

  &.color-brand-black {
    &:after {
      color: $color-brand-black;
    }

    &:hover {
      color: $color-link-hover;
    }
  }
}

.article-header-author-last {
  @include font-univers-condensed-bold();
  display: inline-block;
  color: $color-red-on-black-foreground;

  &:hover {
    color: $color-brand-white;
  }

  &:after {
    content: "";
  }

  &.color-brand-black {
    &:after {
      color: $color-brand-black;
    }

    &:hover {
      color: $color-link-hover;
    }
  }
}

.article-header-related-item {
  @include font-size-entity-title;

  margin-top: 0.5em;
}

.article-header-related-label {
  @include font-univers-condensed();

  &:after {
    margin-right: 0.5em;
  }
}

//social media
.social-links {
  .article-header & {
    $spacing: 26.25px;

    position: absolute;
    top: 0;
    right: $site-padding;

    .social-links-item {
      display: block;
      line-height: 1;
      margin-bottom: 1em;
    }

    .ico-pdf {
      font-size: px-to-em(28px);
    }

    .ico-share {
      font-size: px-to-em(23px);
    }
  }
}

@include screen($bp-mobile) {
  .article-header {
    padding: $section-spacing-large-mobile 0;
    text-align: center;
  }

  .article-header-details {
    min-height: inherit;
    padding-right: 0;
  }

  .article-header-meta {
    font-size: px-to-em($font-size-h3-mobile);
    line-height: 1.6;

    &:after {
      margin-left: auto;
      margin-right: auto;
      width: px-to-em($global-line-mark, $font-size-h3-mobile);
    }
  }
  .article-header-title {
    padding-right: 0;
  }
  .article-header-slogan,
  .article-header-authors {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
  .social-links {
    .article-header & {
      $spacing: 30px;

      position: relative;
      top: auto;
      right: auto;
      display: inline-block;
      margin-top: 1em;
      vertical-align: middle;

      .social-links-item {
        display: inline-block;
        margin-bottom: 0;
        width: px-to-em(45px);
        text-align: left;
      }

      .ico-pdf {
        font-size: px-to-em(20px);
      }

      .ico-share {
        font-size: px-to-em(20px);
      }
    }
  }
}
