@use "sass:math";

.page-foundation-forum {
  .search-landing-results {
    &[aria-hidden="true"] {
      display: none;
    }
  }

  .entity-search-control {
    margin-bottom: $section-spacing-large;
  }

  .search-criteria-wrapper {
    @include content-wrapper;
  }

  .forum-listing-wrapper {
    @include content-wrapper;
  }

  .cross-link-panel {
    @include page-wrapper;

    margin-bottom: $section-spacing-largest;

    &.reduced-margin {
      margin-bottom: math.div($section-spacing-largest, 2);
    }
  }

  @include screen($bp-mobile) {
    .search-criteria {
      margin-bottom: $section-spacing-mobile;
    }

    .cross-link-panel,
    .entity-search-control {
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
