.scrollspy {
  padding-top: $section-spacing-large;
  margin-bottom: $section-spacing-largest;
  position: relative;
}

.scrollspy-sections {
  margin-left: $scrollspy-content-offset;
}

.scrollspy-nav-offset {
  @media screen and (min-width: $bp-scrollspy) {
    margin-left: $scrollspy-content-offset;
  }
}

@include screen($bp-scrollspy) {
  .scrollspy {
    position: relative;
  }
  .scrollspy-sections {
    margin-left: 0;
  }

  .mobile-nav-offset {
    margin-top: 120px;
  }
}

@include screen($bp-mobile) {
  .scrollspy {
    padding-top: px-to-em(
      $section-spacing-large-mobile-px + $scrollspy-nav-height-tablet
    );
    margin-bottom: $section-spacing-large-mobile;
  }
}
