.section-module {
  margin-bottom: $section-spacing-large-mobile;

  &:last-child {
    margin-bottom: 0;
  }

  &__view-more,
  &__cta {
    display: block;
    margin: $section-spacing-mobile auto 0;
    text-align: center;

    &--nomargin {
      margin-top: 0;
    }
  }

  @include screen($bp-mobile, min) {
    margin-bottom: $section-spacing-large;

    &:last-child {
      margin-bottom: 0;
    }

    &__view-more,
    &__cta {
      margin-top: $section-spacing;

      &--nomargin {
        margin-top: 0;
      }
    }
  }
}
