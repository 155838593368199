$search-landing-tabs-height: 61px;
$search-landing-between-spacing: px-to-em(10px);

.search-landing-tabs {
  @include page-wrapper;

  padding-top: $section-spacing-large;
  text-align: center;
}

.search-landing-tabs-nav {
  @include clearfix;

  margin: 0 $search-landing-between-spacing * -1;

  > li {
    display: table;
    width: 50%;
    float: left;
    text-align: center;
    padding: 0 $search-landing-between-spacing;
  }

  .is-panel-active {
    .search-landing-tabs-btn {
      background-color: $color-black-background;
      border-color: $color-brand-black;
      color: $color-brand-white;
      cursor: default;
    }
  }
}

.search-landing-tabs-btn {
  $padding: 17px;

  @include transition(background-color);
  @include font-size-entity-title();

  @extend %uc;

  display: table-cell;
  vertical-align: middle;
  width: 100%;
  color: $color-brand-black;
  line-height: 1.1;
  height: px-to-em($search-landing-tabs-height, $font-size-entity-title);
  padding: 0.25em px-to-em($padding, $font-size-entity-title);
  border: 1px solid $color-gray-steel;

  &:hover {
    background-color: $color-gray-alt;
  }

  &[aria-controls*="find"] {
    @include ico-icon-after($icon-search) {
      $font-size: 20px;

      font-size: px-to-em($font-size, $font-size-entity-title);
      margin-left: 0.5em;
      transform: rotate(90deg);
    }
  }
}

@include screen($bp-mobile) {
  .search-landing-tabs {
    background-color: $color-brand-white;
    padding-top: $section-spacing-large-mobile;
    padding-bottom: $section-spacing-large-mobile;
    position: relative;
    z-index: $zindex-featured-carousel + 1;
  }

  .search-landing-tabs-nav {
    margin: 0;

    li {
      height: auto;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .search-landing-tabs-nav {
    display: block;

    li {
      float: none;
      width: 100%;
      padding: 0;

      & + li {
        padding-top: $search-landing-between-spacing;
      }
    }
  }
}
