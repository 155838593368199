.carousel-spotlight {
  $heading-height: 130px;
  $arrow-height: 100px;
  color: $color-brand-white;
  background-color: $color-brand-black;

  &__frame {
    background-color: $color-brand-black;
    position: relative;
    overflow: hidden;

    .page-header {
      position: absolute;
      top: 0;
      width: 100%;
      padding-top: $section-spacing;
      padding-bottom: 0;
      background-color: transparent;
      z-index: 5;
    }
  }

  &__video-explore {
    @include bg-cover();

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;

    video {
      @include center("both");
      min-height: 100%;
      min-width: 100%;
    }
  }

  &__belt {
    position: relative;
    z-index: 4;
  }

  $slide-zindex-base: 3;
  &__slide {
    @include bg-cover;

    padding-top: px-to-em($heading-height);
    padding-bottom: px-to-em($arrow-height);

    &:before {
      @include absolute-full;

      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1)
      );
    }

    &:after {
      @include absolute-full;

      content: "";
      top: auto;
      height: px-to-em(250px);
      z-index: $slide-zindex-base - 2;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1)
      );
    }
  }

  &__title {
    @include font-size-banner;
    position: relative;
    z-index: $slide-zindex-base;
  }

  &__tag {
    margin-bottom: 0.75em;
    position: relative;
    z-index: $slide-zindex-base;
  }

  &__peek-content {
    padding-top: 1em;
    position: relative;
    z-index: $slide-zindex-base - 2;
  }

  &__arrows-context {
    position: absolute;
    z-index: 4;
    bottom: px-to-em(6px);
    left: 0;
    right: 0;
  }

  &__arrows {
    padding-bottom: $section-spacing-mobile;
  }

  &__arrow {
    @include ico-icon-after($icon-chevron-right) {
      @include transition(transform);

      font-size: px-to-em($arrow-icon-size + 5px);
      transform: rotateY(-180deg);
      color: $color-brand-white;
    }

    margin-right: $section-spacing-large;

    &:last-child {
      &:after {
        content: $icon-chevron-left;
      }
    }
  }

  &__nav {
    display: flex;
    flex-wrap: nowrap;
    margin-right: -4px;
    position: relative;
    z-index: 4;

    &-link {
      height: px-to-em(6px);
      display: inline-block;
      margin-right: 4px;
      background-color: $color-gray-ac;
      flex-grow: 1;
      cursor: pointer;

      &.is-selected {
        background-color: $color-red-on-black-foreground;
      }
    }
  }

  &__link {
    display: block;
    position: relative;
  }

  @include screen($bp-mobile, min) {
    $controls-offset: 80px;
    &__slide {
      min-height: px-to-em(500px);
      padding-bottom: px-to-em($controls-offset);
      display: flex;
    }

    &__slide-body {
      height: px-to-em(300px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__nav-context {
      @include center("horizontal");

      bottom: px-to-em($controls-offset - 20px);
      z-index: 4;
      width: 100%;
    }

    &__nav {
      margin-right: auto;
      height: 0;
    }

    &__nav-link {
      width: px-to-em(50px);
      flex-grow: 0;
    }

    &__arrows {
      text-align: right;
    }

    &__arrow {
      margin-left: $section-spacing;
      margin-right: 0;
    }

    &__peek-content {
      max-width: px-to-em(870px);
      display: none;
    }

    &__slide {
      &:after {
        content: none;
        display: none;
      }
    }

    &__title {
      @include ico-icon-after($icon-chevron-right) {
        @include transition(opacity);

        display: inline-block;
        color: $color-red-on-black-foreground;
        padding-left: 0.5em;
        font-size: px-to-em(22px, $font-size-banner);
        opacity: 0;
      }
    }
    &__link:hover & {
      &__title {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  @include screen($bp-tablet, min) {
    &__title {
      font-size: px-to-em(32px);
    }
  }

  @include screen($bp-mobile) {
    &__nav {
      padding: 0;
    }
  }
}
