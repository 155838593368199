.category-filters:not(.no-bottom-padding) {
  padding-bottom: $section-spacing;
}

.category-filters-option {
  display: inline;

  input {
    @extend .sr-only;

    &:checked {
      & + .category-filters-title {
        background-color: $color-brand-white;
        color: $color-text-body;
      }
    }
  }
}

.category-filters-title {
  @include font-size-entity-title();

  display: inline-block;
  padding: 0.8em 1em 0.65em;
  margin-right: 0.25em;
  margin-bottom: 0.4em;
  color: $color-red-on-white-foreground;
  border: 1px solid $color-gray-steel;
  transition: $transition-default;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-light;
    color: $color-link-hover;
  }
}

@include screen($bp-mobile-landscape) {
  .category-filters-option {
    display: block;
  }

  .category-filters-title {
    width: 100%;
    text-align: center;
  }
}

.category-filters-dark {
  .category-filters-title {
    border: none;
    color: $color-brand-black;
    background-color: $color-gray-med;
    &:hover {
      background-color: $color-text-red;
      color: $color-brand-white;
    }

    &.active {
      background-color: $color-red-background;
      color: $color-white-on-red-foreground;
    }

    &.category-filters-clear {
      background-color: transparent;
      border: 1px solid $color-gray-steel;
      &:hover {
        color: $color-brand-red;
      }
    }
  }
}

.category-filters-subheading {
  @include font-size-entity-title();
  margin-bottom: 1em;
  color: $color-gray-steel;
}

.category-filters-btn-subheading {
  @include font-univers-condensed-bold($font-size-entity-title);
  font-size: px-to-em(24px);
  margin-bottom: .5em;

  .icon::before {
    @include ico-icon();

    content: $icon-chevron-right;
    font-size: .7em;
    transform: rotate(90deg);
    margin-left: 0.25em;
  }

  &.is-expanded {
    .icon::before {
      transform: rotate(-90deg);
    }
  }

  @include screen($bp-mobile) {
    font-size: px-to-em(20px);
  }
}

.category-filters-rte-subheading {
  @include font-size-copy-text();
  margin-bottom: 1em;
}

.collapse-enabled .category-filters-list-desktop {
  @include screen($bp-mobile) {
    display: none;
  }
}

.collapse-enabled .category-filters-list-mobile {
  @include screen($bp-mobile) {
    //override inline style
    display: block !important;
  }
}
