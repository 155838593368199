@use "sass:math";

$keywork-search-btn-width: 184px;
$keywork-search-btn-width-mobile: 90px;
$keywork-search-button-offset: px-to-em(
    $keywork-search-btn-width,
    $font-size-base
  ) + $section-spacing-small;
$keywork-search-button-offset-mobile: px-to-em(
    $keywork-search-btn-width-mobile,
    $font-size-base
  ) + math.div($section-spacing-small, 2);

.keyword-search {
  position: relative;
  padding-right: $keywork-search-button-offset;
}

.keyword-search-input {
  $padding: 17.5px;

  @include font-size-entity-title;

  line-height: 1.1;
  padding: px-to-em($padding, $font-size-entity-title);

  border: 1px solid $color-brand-turquoise;
  color: $color-gray-steel;
  width: 100%;

  @include placeholder {
    color: $color-gray-steel;
    opacity: 1;
  }
}

.keyword-search-helper-text {
  margin-bottom: $section-spacing-small;
}

.keyword-search-btn {
  @include font-size-buttons;

  line-height: 1.1;
  color: $color-brand-white;
  background-color: $color-brand-red;
  border-color: $color-brand-red;
  width: px-to-em($keywork-search-btn-width, $font-size-buttons);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  text-transform: uppercase;
}

@include screen($bp-mobile) {
  .keyword-search {
    padding-right: $keywork-search-button-offset-mobile;

    &:after {
      $icon-font-size: 22px;

      font-size: 1.5rem; //IE has trouble computing EM's at this level
      right: px-to-em(
        $keywork-search-btn-width-mobile + $section-spacing-small-px,
        $icon-font-size
      );
    }
  }

  .keyword-search-btn {
    width: px-to-em(
      $keywork-search-btn-width-mobile,
      $font-size-buttons-mobile
    );
    font-size: px-to-em($font-size-buttons-mobile);
  }
}

@include screen($bp-mobile-landscape) {
  $padding: 20px;

  .keyword-search {
    &:after {
      display: none;
    }
  }

  .keyword-search-input {
    padding-right: px-to-em($padding, $font-size-entity-title-mobile);
  }
}

@include screen($bp-mobile-small) {
  .keyword-search {
    padding-right: 0;
  }

  .keyword-search-btn {
    position: static;
    width: 100%;
    padding: 0.75em;
    margin-top: 1em;
  }
}
