.credential-item {
  background-color: $color-gray-light;
  padding: 2.25em 1.75em;

  ul {
    padding-left: 0;

    li {
      color: $color-text-body;

      &:before {
        display: none;
      }
    }
  }
}

.credential-item-heading {
  $font-size: 24px;
  @include font-univers-condensed-bold($font-size);

  line-height: 1.1em;

  @include design-mark(15px, $font-size, $color-brand-gray);

  &:after {
    margin-top: 0.65em;
  }
}

.credential-item-flex {
  width: 48%;
  width: calc(50% - 4px);
  @include screen($bp-mobile) {
    width: 100%;
  }
  margin: 2px;
  // @include transition();
  // &:hover {
  // 	background-color: $color-gray-med;

  // 	.credential-item-heading:after {
  // 		border-color: $color-brand-red;
  // 	}
  // }
}
