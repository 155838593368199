$profile-line-height: 1.2;

//default styles are for alumni site,

//modifier corresponds to fellows site
.profile-header-compact {
  @include clearfix;

  margin-bottom: $section-spacing;
}

.profile-header-compact-name {
  @extend .section-heading;

  margin-bottom: px-to-em($element-spacing-small-px, $section-heading-size);
}

.profile-header-compact-position {
  @include font-univers-condensed-light($font-size-subheading);

  line-height: $profile-line-height;
  color: $color-gray-steel;

  @include design-mark(17.5px, $font-size-subheading, $color-gray-steel);
}

.profile-header-compact-employment {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: $profile-line-height;

  a {
    color: $color-link;

    &:hover {
      color: $color-text-gray;
    }
  }
}

.profile-header-compact-employment-item {
  & + & {
    margin-top: 0.25em;
  }
}

//social media
.social-links {
  .profile-header-compact & {
    $spacing: 26px;

    margin-top: 1em;

    .social-links-icon {
      font-size: px-to-em(17.5px);
      color: $color-link;
    }
  }
}

.profile-header-compact--fellows {
  background-color: $color-gray-med;
  padding: $element-spacing;
  margin-bottom: $section-spacing-large;

  .profile-header-compact-name {
    @include font-size-banner();
  }
  .profile-header-compact-position {
    font-size: $font-size-entity-title;

    &:after {
      padding-top: px-to-em(17.5px, $font-size-entity-title);
      margin-top: px-to-em(17.5px, $font-size-entity-title);
      width: px-to-em($global-line-mark, $font-size-entity-title);
    }
  }
}

.profile-header-compact-figure {
  float: left;
  width: px-to-em($professional-thumbnail-width);
  position: relative;
  margin-right: 5%;
  overflow: hidden;

  > img {
    @include center(horizontal, relative);

    display: block;
    //min-height: 100%;
    min-width: 100%;
  }

  & + .profile-header-compact-details {
    display: table;
  }
}

@include screen($bp-mobile) {
  .profile-header-compact {
    margin-bottom: $section-spacing-mobile;
    text-align: center;
  }

  .profile-header-compact-name {
    $header-size-mobile: 36px;

    font-size: px-to-em($header-size-mobile);
  }

  .profile-header-compact-position {
    font-size: px-to-em($font-size-subheading-mobile);

    &:after {
      width: px-to-em($global-line-mark, $font-size-subheading-mobile);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .profile-header-compact-employment {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .profile-header-compact--fellows {
    $card-spacing: 1.25em;

    padding: 0;
    text-align: left;
    margin-bottom: $section-spacing-mobile;

    .profile-header-compact-figure {
      margin-right: 0;
    }

    .profile-header-compact-details {
      padding: $card-spacing $card-spacing 0;
    }

    .profile-header-compact-position {
      font-size: $font-size-entity-title-mobile;

      &:after {
        width: px-to-em($global-line-mark, $font-size-entity-title-mobile);
        margin-left: 0;
      }
    }
  }
}

@include screen($bp-mobile-landscape) {
  .profile-header-compact-figure {
    width: $professional-card-img-width;
  }

  .profile-header-compact--fellows {
    $card-spacing: 1.25em;

    .profile-header-compact-details {
      padding: $card-spacing;
    }
  }
}

@include screen($bp-mobile-small) {
  // $professional-result-card-width-mobile: px-to-em(280px);

  .profile-header-compact--fellows {
    .profile-header-compact-figure {
      & + .profile-header-compact-details {
        padding: px-to-em(14px) px-to-em(14px) 0;

        .profile-header-compact-name {
          font-size: px-to-em(20px);
        }
      }
    }
  }
}
