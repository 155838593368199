$office-tile-padding: 31.5px;
$office-tile-padding-mobile: 30px;

.office-tile {
  background-color: $color-charcoal;
  padding: $office-tile-padding;
  color: $color-brand-white;
}

.office-tile-title {
  @include font-size-entity-title();

  line-height: 1.2em;
  text-transform: uppercase;

  @include design-mark(9px, $font-size-entity-title);
}

.office-tile-link {
  color: $color-brand-white;
  display: inline-block;

  &:hover {
    color: $color-brand-red;
  }
}

.office-tile-address {
  @include font-univers-condensed-light($font-size-entity-title);
  line-height: 1.3;
  margin: 0.15em auto
    px-to-em($section-spacing-small-px - 5px, $font-size-entity-title);

  &.bold {
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
}

.office-tile-content {
  @include font-univers-condensed-light($font-size-entity-title);
  line-height: 1.3;
}

.office-tile-phone {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.3;
  text-transform: uppercase;
}

.office-tile-phone-label {
  width: 1em;
  display: inline-block;
  color: $color-brand-gray;
}

.office-tile-map {
  @include font-univers-condensed-bold();

  display: inline-block;
  text-transform: uppercase;
  color: $color-brand-white;
  line-height: 1.2;
  margin-top: px-to-em($section-spacing-small-px);

  @include ico-icon-after($icon-location) {
    margin-left: 0.5em;
  }

  &:hover {
    color: $color-brand-red;
  }
}

@include screen($bp-mobile) {
  .office-tile {
    padding: px-to-em($office-tile-padding-mobile);
  }

  .office-tile-title {
    &:after {
      width: px-to-em($global-line-mark, $font-size-entity-title-mobile);
    }
  }

  .office-tile-phone,
  .office-tile-address,
  .office-tile-content {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}
