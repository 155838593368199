.sort-controls {
  text-align: right;
}

.sort-controls-label {
  $font-size: 17px;
  @include font-univers-condensed-bold($font-size);

  margin-right: 0.25em;
  text-transform: uppercase;

  &:after {
    content: ":";
  }
}
.sort-controls-options {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
  display: inline-block;

  ng-repeat {
    margin-left: 0.5em;

    .sort-controls-sort-by {
      margin-left: 0;
    }
  }
}

.sort-controls-sort-by {
  display: inline-block;
  color: $color-brand-black;
  min-width: px-to-em(44px, $font-size-entity-title);
  position: relative;
  margin-left: 0.5em;
  text-align: center;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:not(.nolink) {
    &:before {
      @include transition(opacity);

      content: "";
      display: block;
      position: absolute;
      top: 100%;
      margin-top: px-to-em(5px, $font-size-entity-title);
      width: 100%;
      opacity: 0;
      border-bottom: $global-line-thickness solid $color-brand-red;
      z-index: 1;
    }

    &:hover,
    &.is-selected {
      &:before {
        opacity: 1;
      }
    }

    &:hover {
      color: $color-link-hover;
    }
  }

  &.is-selected {
    cursor: default;

    &:hover {
      color: $color-brand-black;
    }
  }

  &.nolink {
    cursor: default;
    opacity: 0.5;
  }
}

@include screen($bp-mobile) {
  .sort-controls {
    text-align: left;
  }
  .sort-controls-label {
    font-size: 1em;
  }

  .sort-controls-options {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}

@include screen($bp-mobile-small) {
  .sort-controls-label {
    display: block;
  }

  .sort-controls-options {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    ng-repeat {
      flex-grow: 1;
    }
  }

  .sort-controls-sort-by {
    flex-grow: 1;

    &:not(.nolink) {
      &:before {
        margin-top: 1px;
      }
    }
  }
}
