.page-news-rankings {
  .recent-accolades-wrapper {
    @include content-wrapper;
  }

  .search-landing-panel-content {
    padding-top: $section-spacing-large;
    margin-bottom: $section-spacing-largest;
  }

  .search-criteria {
    margin-bottom: $section-spacing-large;
  }

  .search-criteria-wrapper {
    padding-left: 0;
    padding-right: 0;

    &:after {
      left: 0;
      right: 0;
    }
  }

  .search-landing-results {
    &[aria-hidden="true"] {
      display: none;
    }
  }

  @include screen($bp-mobile) {
    .search-criteria {
      margin-bottom: $section-spacing-mobile;
    }

    .search-landing-panel-content {
      padding-top: $section-spacing-large-mobile;
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
