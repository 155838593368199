﻿@use "sass:math";

.content-lead {
  margin-bottom: $section-spacing;
}

.content-lead-copy {
  //font size declared inside typography file
  font-size: px-to-em($impact-lead-copy);
  line-height: 1.25;
  padding-bottom: px-to-em(39px, $impact-lead-copy);
  border-bottom: $border-dark;

  a {
    color: $color-link;

    &:hover {
      color: $color-link-hover;
    }
  }

  ul,
  ol {
    padding-left: 1em;
    margin-bottom: px-to-em($element-spacing-px, $font-size-copy);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;

    li {
      color: $color-gray-steel;
      position: relative;

      &:before {
        content: "■";
        color: $color-link;
        font-size: 0.6em;
        line-height: 2.25;
        margin-left: -1.75em;
        position: absolute;
      }
    }

    ul {
      li {
        &:before {
          color: $color-text-gray-soft;
        }
      }
    }
  }

  li {
    margin-bottom: px-to-em($element-spacing-small-px, $font-size-copy);
  }

  ol {
    list-style-type: decimal;
  }
}

.content-lead-cross-links {
  margin-top: math.div($section-spacing, 1.5);
}

.content-lead-cross-links-option {
  display: inline;
  margin-right: 0.25em;
}

@include screen($bp-mobile) {
  .content-lead {
    margin-bottom: $section-spacing-mobile;
  }

  .content-lead-copy {
    font-size: px-to-em($impact-lead-copy-mobile);
  }
}
