$font-offices-size: 21px;

.offices-related-name {
  @include font-size-entity-title();

  line-height: 1.3;
  text-transform: uppercase;
  color: $color-text-red;

  &:hover {
    color: $color-brand-white;
  }
}

.offices-related-name-nolink {
  @include font-univers-condensed-bold($font-offices-size);

  line-height: 1.3em;
  text-transform: uppercase;
}

.offices-related-phone {
  @include font-univers-condensed-light($font-offices-size);

  line-height: 1.3em;
  text-transform: uppercase;
}

.offices-related-phone-label {
  width: 1em;
  display: inline-block;
  color: $color-brand-gray;
}

@include screen($bp-mobile) {
  $font-offices-size: 20px;

  .offices-related-name,
  .offices-related-name-nolink,
  .offices-related-phone {
    font-size: px-to-em($font-offices-size);
  }
}
