.job-employment-policy {
  margin-bottom: $section-spacing-large;

  .listing-files {
    margin-top: $section-spacing;
  }

  &--attorney {
      margin-top: $section-spacing-large;
  }  
}

@include screen($bp-mobile) {
  .job-employment-policy {
    margin-bottom: $section-spacing-large-mobile;

    .listing-files {
      margin-top: $section-spacing-mobile;
    }
  }
}
