.page-insights-landing {
  .highlight-card {
    margin-bottom: $section-spacing-large;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    table-layout: fixed;
    height: inherit;
    display: inherit;
    overflow: hidden;
  }

  .highlight-card--full {
    .highlight-card-figure {
      max-height: 100%;
    }
    em,
    i {
      font-family: $font-family-univers-condensed-light-oblique;
    }
  }

  a.highlight-card-link {
    &:hover {
      cursor: pointer;
    }
  }

  .highlight-card-img {
    height: 100%;
    width: 100.05%;
    max-width: inherit;
    max-height: inherit;
    min-height: inherit;
    min-width: inherit;
  }

  .highlight-card-link {
    display: block;
    width: 100%;
  }

  .highlight-card--full {
    .highlight-card-figure {
      display: inherit;
    }
  }

  .search-landing-panel-content {
    padding-top: $section-spacing-large;
    margin-bottom: $section-spacing-largest;
  }

  .search-landing-results {
    &[aria-hidden="true"] {
      display: none;
    }
  }

  .insights-landing-results-wrapper {
    @include page-wrapper;

    position: relative;
  }

  .search-criteria {
    margin-bottom: $section-spacing-large;
  }

  .search-criteria-wrapper {
    padding-left: 0;
    padding-right: 0;

    &:after {
      left: 0;
      right: 0;
    }
  }

  @include screen($bp-mobile) {
    .highlight-card {
      margin-bottom: $section-spacing-large-mobile;
    }

    .search-criteria {
      margin-bottom: $section-spacing-mobile;
    }

    .search-landing-panel-content {
      padding-top: $section-spacing-large-mobile;
      margin-bottom: $section-spacing-large-mobile;
    }
  }

  .event-listing-item {
    .listing-articles-meta {
      .meta-location,
      .meta-date {
        display: inline-block;
      }
      .meta-date {
        padding-top: 0;
      }
    }
  }
}
