.highlight-banners {
    margin-bottom: $section-spacing-large;

    .highlight-card {
        & + .highlight-card {
            margin-top: $element-spacing;
        }
    }

    &.slim {
        .highlight-banners-wrapper {
            margin-left: px-to-em($back-to-top-content-offset);

            @include screen($bp-back-to-top) {
                margin-left: 0;
            }
        }
    }

    &--home {
        margin-bottom: 0;        
        padding-top: 4.875em;
        background-color: $color-white-background;

        .highlight-banners-list {
            margin-top: 0;
        }

        + .page-homepage__section-module {
            padding-top: 4.875em;
        }
    }
}

.highlight-banners-list {
  margin-top: $section-spacing;
}

@include screen($bp-mobile) {
  .highlight-banners-list {
    margin-top: $section-spacing-mobile;
  }

  .highlight-banners {
    margin-bottom: $section-spacing-large-mobile;
  }

  .highlight-banners--home {
      margin-bottom: 0;

      .highlight-card {
          max-width: none;
      }
  }
}

.highlight-card-wrapper {
  &.slim {
    .highlight-banner-footer {
      margin-left: $back-to-top-content-offset;

      @include screen($bp-back-to-top) {
        margin-left: 0;
      }
    }
  }
}
