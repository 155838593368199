.header-no-banner {
  padding-bottom: $section-spacing-px;

  .section-subnav {
    text-align: left;
  }

  .section-subnav-wrapper {
    padding: 0;
  }

  .social-links {
    // background-color: black;
    $spacing: 30px;

    position: absolute;
    top: -30px;
    right: $site-padding;

    .social-links-item {
      display: block;
      line-height: 1;
      margin-bottom: 1em;
    }

    .ico-pdf {
      font-size: px-to-em(32px);
    }

    .ico-share {
      font-size: px-to-em(26px);
    }
  }
}

.header-no-banner-side-content {
  margin-top: $section-spacing-px;

  .section-subnav {
    text-align: left;
  }

  .section-subnav-wrapper {
    padding: 0;
  }
}
