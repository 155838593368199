//will resort to flex box instead of using display table  in the situation where an image is not present
.fellows-card {
  @include clearfix;

  background-color: $color-gray-light;
  padding: $element-spacing;
  position: relative;
}

.has-mark {
  @include design-mark($placement: after);
  &:after {
    font-size: 16px;
    // padding-top: 1em;
  }
}

/*----------  name and partner related info  ----------*/

.fellows-card-figure {
  float: left;
  width: px-to-em($professional-thumbnail-width);
  position: relative;
  margin-right: 5%;
  overflow: hidden;

  img {
    @include center(horizontal, relative);

    display: block;
  }

  & + .fellows-card-details {
    display: table;
  }

  &:hover + .fellows-card-details {
    .fellows-card-name {
      &:not(.nolink) {
        color: $color-gray-steel;
      }
    }
  }
}

.fellows-card-name {
  @include font-size-banner();

  padding-right: 2em;

  &:not(.nolink) {
    &:hover {
      color: $color-gray-steel;
    }
  }

  .is-current & {
    @include design-mark($placement: after);
    &:after {
      font-size: 16px;
    }
  }
}

.fellows-card-class-year {
  background-color: $color-brand-red;
  color: $color-brand-white;
  display: inline-block;
  font-size: px-to-em(17px, $font-size-banner);
  padding: 0.25em 0.5em 0.18em;
  position: absolute;
  top: $element-spacing;
  right: $element-spacing;

  // .is-current & {
  // top: $element-spacing * 3;
  // right: 0;
  // }
}

//boost font when not inside name h3
.fellows-card-class-year-wrapper {
  @include font-size-banner();
  .fellows-card-class-year {
    top: auto;
    // right: 0;
  }
}

/*---------- Contact Related   ----------*/

.fellows-card-description,
.fellows-card-description-no-mark {
  max-width: px-to-em(800px);

  .fellows-card-credentials {
    li:last-child {
      margin-bottom: 1em;
    }
  }
}

.fellows-card-credentials {
  &.no-mark {
    @include design-mark($placement: before);
  }
}

.fellows-card-description {
  @include design-mark($placement: before);

  &.not-relative {
    position: static;
  }
}

.fellows-card-description-no-mark {
  margin-top: 1em;
}

.fellows-card-credentials-item {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
  margin-top: 0.3em;
  &.no-margin {
    margin-top: 0;
  }
}

@include screen($bp-two-column) {
  .fellows-card-figure {
    img {
      max-width: 110%;
    }
  }
}

@include screen($bp-mobile) {
  .fellows-card-figure {
    width: px-to-em(120px);

    & + .fellows-card-name {
      padding-right: 0;
    }
  }

  .fellows-card-name {
    padding-right: 1.75em;
  }

  .fellows-card-credentials-item {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}

@include screen($bp-mobile-landscape) {
  $fellows-card-width-mobile: px-to-em(280px);
  $fellows-card-spacing: 1.25em;

  .fellows-card-figure {
    width: 100%;
    height: auto;

    img {
      left: auto;
      transform: none;
      width: 100%;
    }
  }

  .fellows-card-link {
    display: block;
  }

  .fellows-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: $fellows-card-width-mobile;
    padding: 0;
  }

  .fellows-card-class-year {
    top: $fellows-card-spacing + 0.25em;
    right: $fellows-card-spacing;

    // .is-current & {
    // 	top: 5.5em;
    // }
  }

  .fellows-card-details {
    padding: $fellows-card-spacing;
  }
}
