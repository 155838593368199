$font-size-messaging-heading: 50px;

.messaging-platform {
    @include bg-cover;
    background-color: $color-black-background;
    padding: 100px 0px 50px;

    &-wrapper {
        display: flex;
        flex-direction: column;
    }

    &-heading {
        @extend %uc;
        @include font-univers-condensed-light($font-size-messaging-heading);
        font-size: 2.5em;
        color: $color-gray-steel;
        text-align: center;
        line-height: 1;
        width: 100%;
        position: relative;
        padding: 20px 10px;
        opacity: 0;

        em {
            @extend .messaging-platform-figure;

            @include font-univers-condensed-light;
        }

        &.visible {
            opacity: 1;
        }
    }

    &-divider {
        &__line {
            height: 2px;
            width: 0%;
            background-color: $color-brand-red;
        }
        // &:after {
        // 	content: " ";
        // 	display: inline-block;
        // 	height: 2px;
        // 	width: 100%;
        // 	background-color: $color-brand-red;
        // }
    }

    &-figure {
        color: $color-brand-white;
    }

    @include screen($bp-tablet, min) {
        &-wrapper {
            flex-direction: row;
        }

        &-heading {
            width: 33.33%;
            font-size: 3.125em;
        }

        &-divider {
            &__line {
                height: 0%;
                width: 2px;
            }
            // &:after {
            // 	height: 100%;
            // 	width: 2px;
            // }
        }
    }
}
