//not very dynamic code but needed to space out nav links
$site-footer-logo-offset: 25%;

.site-footer--fellowship {
  background-color: $color-charcoal;
  padding-bottom: 0;

  .site-footer-logo {
    margin-top: 0.25em;
    float: left;
  }

  .content-wrapper {
    @include page-wrapper;
  }

  .site-footer-utility-item {
    form {
      display: inline-block;

      a {
        cursor: pointer;
      }
    }
  }
}

.site-footer-fellowship-navigation {
  margin-left: $site-footer-logo-offset;
  padding-bottom: $section-spacing;
}

@include screen($bp-site-footer-fellowship-tablet) {
  .site-footer--fellowship {
    .site-footer-logo {
      margin: 0 auto $section-spacing;
      float: none;
    }
  }

  .site-footer-fellowship-navigation {
    margin-left: 0;
  }
}

@include screen($bp-two-column) {
  $footer-spacing: px-to-em(21px);

  .site-footer {
    text-align: center;

    .social-links {
      float: none;
      padding-top: $footer-spacing;
      margin-top: $footer-spacing;
      border-top: 1px solid $color-brand-white;
      overflow: hidden;
    }

    .social-links-icon {
      margin: 0 0.5em;
    }
  }

  .site-footer-menu {
    display: block;
  }

  .site-footer-menu-item {
    margin-bottom: $footer-spacing;
  }

  .site-footer-utility {
    float: none;
  }

  .site-footer-utility-item {
    display: block;
    margin-bottom: $footer-spacing;

    form {
      display: inline-block;
    }
  }

  .site-footer-utility-link {
    display: inline-block;
  }
}
