$personnel-banner-width: 44%;
$personnel-banner-min-height: 390px;
$personnel-banner-offset: 21px;

.testimonial-banner {
  position: relative;
  display: block;
  padding-top: px-to-em($personnel-banner-offset);
  padding-left: px-to-em($personnel-banner-offset);

  &:nth-child(2n) {
    padding-left: 0;
    padding-right: px-to-em($personnel-banner-offset);

    .testimonial-banner-figure {
      left: auto;
      right: 0;

      padding-right: 0;
      padding-left: $element-spacing;
    }

    .testimonial-banner-info {
      padding-left: $element-spacing;
      padding-right: $personnel-banner-width;
    }
  }

  &.no-image {
    padding-left: 0;

    &:nth-child(2n) {
      padding-right: 0;

      .testimonial-banner-info {
        padding-right: $element-spacing;
      }
    }

    .testimonial-banner-info {
      padding-left: $element-spacing;
    }
  }

  &:not(:first-child) {
    margin-top: $element-spacing;
  }
}

.testimonial-banner-figure {
  position: absolute;
  left: 0;
  top: 0;
  width: 44%;
  padding-right: $element-spacing;
}
.testimonial-banner-img {
  max-width: 100%;
}

.testimonial-banner-info {
  background-color: $color-featured-banner;
  min-height: $personnel-banner-min-height;
  padding: $element-spacing $element-spacing $section-spacing;
  padding-left: $personnel-banner-width;
}

.testimonial-banner-subtitle {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
}

.testimonial-banner-details {
  @include design-mark($spacing: 17.5px, $border-color: $color-gray-steel);

  &:after {
    @include transition(border-color);
  }
}

@include screen($bp-featured-banners) {
  .testimonial-banner {
    &:nth-child(2n) {
      .testimonial-banner-figure {
        float: right;
        margin-right: $personnel-banner-offset * -1;
        margin-left: 0;
        padding-left: 0;
      }

      .testimonial-banner-info {
        padding-left: $element-spacing;
        padding-right: 0;
      }

      .testimonial-banner-abstract {
        padding-left: 0;
      }
    }
  }

  .testimonial-banner-figure {
    float: left;
    position: static;
    margin-left: $personnel-banner-offset * -1;
    margin-top: $personnel-banner-offset * -1;
  }

  .testimonial-banner-info {
    padding-left: 0;
    padding-right: 0;
  }

  .testimonial-banner-details {
    display: table;
  }

  .testimonial-banner-abstract {
    padding: 0 $element-spacing;
  }
}

@include screen($bp-mobile) {
  .testimonial-banner-subtitle {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .testimonial-banner-info {
    min-height: inherit;
  }
}

@include screen($bp-mobile-landscape) {
  .testimonial-banner {
    padding-left: 0;

    &:nth-child(2n) {
      padding-right: 0;

      .testimonial-banner-figure {
        float: none;
        margin-right: 0;
      }

      .testimonial-banner-info {
        padding-left: $site-padding-mobile;
        padding-right: $site-padding-mobile;
      }
    }
  }

  .testimonial-banner-figure {
    margin: 0;
    padding: 0;
    background-color: $color-featured-banner;
    float: none;
    width: 100%;
    text-align: center;
  }

  .testimonial-banner-img {
    position: relative;
    margin-top: $personnel-banner-offset * -1;
    width: 90%;
  }

  .testimonial-banner-info {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }

  .testimonial-banner-abstract {
    padding: 0;
  }
}
