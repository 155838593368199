$font-size-category-tabs: 17.5px;
$category-tabs-height: 64px;
$category-tabs-between-spacing: px-to-em(5px);

.category-tabs {
  margin-bottom: $section-spacing;
  text-align: center;
}

.category-tabs-nav {
  display: table;
  margin: 0 auto;
}

.category-tabs-label {
  @include font-univers-condensed-bold($form-label-font-size);

  display: inline-block;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: $element-spacing-small;
}

.category-tabs-item {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 0 $category-tabs-between-spacing;
  width: 33.3333%;
}

.category-tabs-btn {
  @extend %uc;
  @include font-univers-condensed-bold($font-size-category-tabs);

  line-height: 1.1;
  display: table;
  width: 100%;
  height: px-to-em($category-tabs-height, $font-size-category-tabs);
  padding: 0.2em 0.75em;
  transition: $transition-default;
  border: 1px solid $color-gray-steel;
  white-space: nowrap;

  &:hover {
    background-color: $color-gray-steel;
    color: $color-brand-white;
  }

  .is-tab-active & {
    background-color: $color-black-background;
    color: $color-brand-white;
  }
}
.category-tabs-btn-label {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
@include screen($bp-mobile) {
  .category-tabs {
    margin-bottom: $section-spacing-mobile;
  }

  .category-tabs-btn {
    font-size: px-to-em($font-size-copy-mobile);
    white-space: normal;
  }
}

@include screen($bp-mobile-small) {
  .category-tabs-nav {
    display: block;
  }
  .category-tabs-item {
    display: list-item;
    width: 100%;
    & + & {
      margin-top: 0.5em;
    }
  }
}
