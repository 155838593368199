@use "sass:math";

$keywork-searchbar-btn-width: 184px;
$keywork-searchbar-height: 60px;
$keywork-search-button-offset: px-to-em(
    $keywork-search-btn-width,
    $font-size-base
  ) + $section-spacing-small;
$keywork-search-button-offset-mobile: px-to-em(
    $keywork-search-btn-width-mobile,
    $font-size-base
  ) + math.div($section-spacing-small, 2);

.keyword-searchbar {
  position: relative;

  &__input {
    @include font-size-entity-title();
    @include font-univers-condensed-light();
    width: 100%;
    border: thin solid $color-site-header-border;
    background-color: $color-grey-f6;
    height: px-to-em($keywork-searchbar-height, $font-size-entity-title-mobile);
    padding: px-to-em($element-spacing-small-px, $font-size-entity-title-mobile)
      px-to-em($element-spacing-px, $font-size-entity-title-mobile);
    margin-bottom: px-to-em(8px, $font-size-entity-title-mobile);

    @include placeholder {
      color: $color-gray-steel;
      opacity: 1;
    }
  }

  &__btn {
    @include font-univers-condensed-bold();

    height: px-to-em($keywork-searchbar-height);
    line-height: 1.1;
    color: $color-brand-white;
    background-color: $color-brand-red;
    border-color: $color-brand-red;
    text-transform: uppercase;
    width: 100%;

    @include ico-icon-before($icon-search) {
      font-size: px-to-em(17px);
      margin-right: 0.5em;
      vertical-align: middle;
    }
  }

  @include screen($bp-mobile, min) {
    display: flex;
    &__input {
      height: px-to-em($keywork-searchbar-height, $font-size-entity-title);
      flex-grow: 1;
      margin-bottom: 0;
    }

    &__btn {
      flex-shrink: 0;
      font-size: px-to-em(18px);
      width: px-to-em(250px, 18px);
      height: px-to-em($keywork-searchbar-height, 18px);

      &:hover {
        background-color: $color-gray-on-gray-foreground;
        border-color: $color-gray-on-gray-foreground;
      }
    }
  }
}

// .keyword-search-input {
// 	$padding: 17.5px;

// 	@include font-size-entity-title;

// 	line-height: 1.1;
// 	padding: px-to-em($padding, $font-size-entity-title);

// 	border: 1px solid $color-brand-turquoise;
// 	color: $color-gray-steel;
// 	width: 100%;

// 	@include placeholder {
// 		color: $color-gray-steel;
// 		opacity: 1;
// 	}
// }

// .keyword-search-helper-text {
// 	margin-bottom: $section-spacing-small;
// }

// .keyword-search-btn {
// 	@include font-size-buttons;

// 	line-height: 1.1;
// 	color: $color-brand-white;
// 	background-color: $color-brand-red;
// 	border-color: $color-brand-red;
// 	width: px-to-em($keywork-search-btn-width, $font-size-buttons);
// 	position: absolute;
// 	right: 0;
// 	top: 0;
// 	height: 100%;
//     text-transform: uppercase;
// }

// @include screen($bp-mobile) {

// 	.keyword-search {
// 		padding-right: $keywork-search-button-offset-mobile;

// 		&:after {
// 			$icon-font-size: 22px;

// 			font-size: 1.5rem; //IE has trouble computing EM's at this level
// 			right: px-to-em($keywork-search-btn-width-mobile + $section-spacing-small-px, $icon-font-size);
// 		}
// 	}

// 	.keyword-search-btn {
// 		width: px-to-em($keywork-search-btn-width-mobile, $font-size-buttons-mobile);
// 		font-size: px-to-em($font-size-buttons-mobile);
// 	}
// }

// @include screen($bp-mobile-landscape) {
// 	$padding: 20px;

// 	.keyword-search {

// 		&:after {
// 			display: none;
// 		}
// 	}

// 	.keyword-search-input {
// 		padding-right: px-to-em($padding, $font-size-entity-title-mobile);
// 	}
// }

// @include screen($bp-mobile-small){
//     .keyword-search {
//         padding-right: 0;
//     }

//     .keyword-search-btn {
//         position: static;
//         width: 100%;
//         padding: .75em;
//         margin-top: 1em;
//     }
// }
