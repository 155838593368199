@use "sass:math";

.impactful-lead {
  padding: $section-spacing * 1.25 0;
  background-color: $color-lightgray-background;
}

.impactful-lead-copy {
  //font size declared inside typography file
  font-size: px-to-em($impact-lead-copy);
  line-height: 1.2;
  padding: px-to-em(22px, $impact-lead-copy) 0;
  border-top: 2px solid $color-red-on-lightgray-foreground;
  border-bottom: 2px solid $color-red-on-lightgray-foreground;

  a {
    color: $color-red-on-lightgray-foreground;

    &:hover {
      color: $color-link-hover;
    }
  }

  ul,
  ol,
  p,
  img,
  blockquote,
  address {
    margin-bottom: px-to-em(
      math.div($element-spacing-px, 2),
      $impact-lead-copy
    );

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: 1.15em;
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &:before {
        content: "■";
        color: $color-red-on-lightgray-foreground;
        font-size: 0.6em;
        line-height: 2.25;
        margin-left: -1.75em;
        position: absolute;
      }
    }

    ul {
      li {
        &:before {
          color: $color-text-gray-soft;
        }
      }
    }
  }

  li {
    margin-bottom: px-to-em($element-spacing-small-px, $font-size-copy);
  }

  ol {
    list-style-type: decimal;
  }
  //fixes bug sk-516 where the img has inline dimensions defined
  img {
    max-width: 100%;
    height: auto !important;
  }
}

.impactful-lead-cross-links {
  margin-top: math.div($section-spacing, 1.5);
}

.impactful-lead-cross-links-option {
  display: inline;
  margin-right: 0.25em;
}

.impactful-lead-cross-links-name {
  @extend %uc;
  @include font-size-buttons();
  @include transition(background-color);

  display: inline-block;
  line-height: 1;
  padding: 0.7em ($arrow-padding * 1.25) 0.5em 0.75em;
  position: relative;
  color: $color-red-on-lightgray-foreground;
  border: 1px solid $color-gray-steel;

  &:hover {
    background-color: $color-brand-white;

    &:after {
      transform: translateX(80%) translateY(-50%);
    }
  }

  //arrow
  @include ico-icon-after($icon-chevron-right) {
    $arrow-icon-size: 14px;

    @include center(vertical);
    @include transition(transform);

    font-size: px-to-em($arrow-icon-size, $font-size-buttons);
    right: math.div($arrow-padding, 1.25);
    color: $color-red-on-lightgray-foreground;
  }

  &[href^="mailto"] {
    padding-right: $arrow-padding * 1.5;

    &:hover {
      &:after {
        transform: translateY(-50%);
      }
    }

    &:after {
      content: $icon-email;
    }
  }
}

@include screen($bp-scrollspy) {
  .impactful-lead {
    position: relative;
    z-index: $zindex-scrollspy-nav + 5;
  }
}

@include screen($bp-mobile) {
  .impactful-lead-copy {
    font-size: px-to-em($impact-lead-copy-mobile);
    padding: px-to-em(25px, $impact-lead-copy-mobile) 0;
  }

  .impactful-lead-cross-links {
    margin-top: 1em;
  }

  .impactful-lead-cross-links-name {
    margin-top: 1em;
  }
}
