.section-subnav-links-title {
  @extend %uc;
  @include font-size-entity-title();

  display: inline-block;
  color: $color-red-on-white-foreground;
  transition: $transition-default;

  &:hover,
  .is-submenu-active & {
    color: $color-brand-black;
  }
}
