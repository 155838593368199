.open-jobs-option {
  display: inline;
}

.open-jobs-title {
  @include font-size-entity-title();

  display: inline-block;
  padding: 0.8em 1em 0.65em;
  margin-right: 0.25em;
  margin-bottom: 0.4em;
  color: $color-link;
  border: 1px solid $color-gray-steel;
  transition: $transition-default;

  &:hover {
    background-color: $color-gray-light;
    color: $color-link-hover;
  }

  @include ico-icon-after($icon-download) {
    font-size: 0.8em;
    margin-left: 0.75em;
  }
}
