.careers-opportunities {
  margin-bottom: $section-spacing-large;

  .open-jobs {
    margin-bottom: $section-spacing-large;
  }
}

.careers-opportunities-copy {
  margin-bottom: px-to-em($element-spacing-px, $font-size-copy);
}

.careers-opportunities-contacts-copy {
  @include font-univers-condensed-bold();

  li {
    margin-bottom: 0.25em;
  }
}

@include screen($bp-mobile) {
  .careers-opportunities {
    margin-bottom: $section-spacing-large-mobile;

    .open-jobs {
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
