$keywork-search-btn-width: 210px;
$keywork-search-btn-width-mobile: 90px;

.alumni-edit-btn {
  @include font-univers-condensed-bold($font-size-entity-title);

  line-height: 1.1;
  color: $color-brand-white;
  background-color: $color-brand-red;
  border-color: $color-brand-red;
  width: px-to-em($keywork-search-btn-width, $font-size-entity-title);
  height: 100%;
  padding: 1rem;

  @include screen($bp-mobile) {
    width: px-to-em(
      $keywork-search-btn-width-mobile,
      $font-size-entity-title-mobile
    );
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}
