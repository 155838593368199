.page-lawyer-careers-location {
    [id="Contact Us"] {
        padding-top: 0;

        .page-wrapper {
            margin-top: $section-spacing;
        }
    }

    .rte {
        line-height: 1.4;
    }

    .category-filters-title {
        font-size: 24px;
        padding: .4em .6em .4em;
    }

    .flex-panel-read-more {
        margin-bottom: 2em;
    }
}

.page-layout-spacing.careers-location-intro-spacing {
    padding-top: 2em;
    margin-bottom: 2em;
}

.careers-location-header {
    @include bg-cover();

    background-position: top center;
    color: $color-brand-white;
    position: relative;

    &__title {
        @include design-mark(16px, 26px, $color-brand-white);

        font-size: px-to-em(26px);
    }
    
    &__video {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    
        video,
        &::after {
            @include center("both");
            min-height: 100%;
            min-width: 100%;
        }
        
        &::after {
            content: "";
            display: block;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 79.5%);
            background-blend-mode: multiply;
        }
    }

    &__content {
        min-height: px-to-em(528px - 104px);
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .category-filters-title {
        background-color: $color-gray-ac;
        color: $color-brand-white;

        &:not(.category-filters-clear) {
            &.active,
            &:hover,
            &:focus {
                background-color: $color-alizarin-dark;
            }
        }
    }

    .category-filters-btn-subheading {
        color: $color-brand-gray;
    }
}

.careers-location-section {
    padding-top: px-to-em(60px);
    padding-bottom: px-to-em(60px);
}

.careers-location-tabs-section {
    background: #405A67 no-repeat center center;
    background-image: linear-gradient(0deg, #405A67CC, #405A67CC), var(--background);
    background-size: cover;
    background-attachment: fixed;
    color: $color-brand-white;

    .section-heading {
        font-weight: bold;
    }
        
    .category-filters {
        padding-bottom: 1rem;

        .category-filters-title {
            color: $color-brand-white;
            border-color: $color-brand-white;

            &.active {
                background-color: $color-alizarin-dark;
                color: $color-white-on-red-foreground;
                border-color: transparent;
            }
    
            &:hover:not(.active) {
                color: $color-black;
            }
        }
    }
    
    .panel-section-subheading {
        color: inherit;
    }
    
    .call-to-action-link {
        border-color: $color-brand-white;
    
        &::after {
            color: $color-brand-white;
        }

        &:hover {
            color: $color-alizarin-dark;

            &::after {
                color: $color-alizarin-dark;
            }
        }
    }

    .call-to-action {
        margin-bottom: px-to-em(20px);
    }

    .call-to-action + .call-to-action {
        margin-top: px-to-em(-20px);

        .call-to-action-link {
            border-top: none;
        }
    }

    .rte a:hover,
    .btn-text-link:hover,
    .read-more-toggle.is-expanded,
    .rte ul li:before {
        color: $color-brand-white;
    }

    .flex-panel,
    .flex-panel-intro {
        margin-bottom: $element-spacing;
    }

    .rte ul ul li:before {
        content: "□";
    }
    .rte ul ul ul li:before {
        content: "◆";
    }

    @include screen($bp-mobile, min) {
        .content-banner {
            padding: 0;
            display: flex;
        }

        .content-banner-info,
        .content-banner-title {
            padding: 40px;
        }

        .content-banner-title {
            @include font-univers-condensed();
            font-weight: 700;

            border-left: 6px solid $color-alizarin-dark;
            padding-left: 40px - 6px;
            flex: auto;
        }

        .content-banner-tile {
            margin: 0;
            display: flex;
            float: none;
            flex: 1 0 auto;
            width: 350px;
        }
        
        .content-banner-info {
            min-height: auto;

            &::before {
                content: none;
            }
        }

        .content-banner-title {
            font-size: 24px;
            display: flex;
            align-items: center;
        }

        .content-banner-abstract {
            font-size: 20px;
        }
    }
}

.careers-accordions-section {
    background-color: $color-gray-light;

    &__title {
        @include design-mark(16px, $font-size-h2, $color-alizarin-dark);
    }

    @include screen($bp-tablet, min) {
        &__columns {
            display: flex;
            align-items: flex-start;
        }

        &__title {
            width: 330px;
            margin-right: 1rem;
        }

        &__content {
            flex: 1;
        }
    }
}

.careers-accordions {
    &__trigger {
        @include font-univers-condensed-bold(24px, 28px);

        width: 100%;
        text-align: left;
        position: relative;
        padding-right: 1.25em;
        
        @include ico-icon-before($icon-plus) {
            @include center(vertical);
        
            position: absolute;
            font-size: px-to-em($arrow-icon-size, $font-size-banner);
            right: 0;
            line-height: 0;
            color: $color-alizarin-dark;
        }

        .is-expanded & {
            &::before {
                // Fake "minus" icon
                content: "";
                width: 1em;
                display: inline-block;
                border-bottom: px-to-em(5px) solid currentColor;
            }
        }
    }

    &__node {
        padding: px-to-em(30px);
        margin-bottom: 4px;
        transition: background-color 300ms ease;
        background-color: $color-brand-gray;
        
        &.is-expanded {
            background-color: $color-brand-white;
        }
    }

    &__panel {
        padding-top: px-to-em(15px);
    }
}

.careers-tabs {
    div:last-child {
        margin-bottom: 0;
    }
}

.careers-carousel {
    $arrow-height: 100px;
    background-color: $color-brand-gray;

    &__description {
        margin-bottom: px-to-em($element-spacing-px);
    }

    &__carousel {
        position: relative;
    }

    &__slide {
        padding-bottom: px-to-em($arrow-height);
    }
    
    .carousel-spotlight__arrow {
        background-color: $color-alizarin-dark;
        width: px-to-em(40px);
        height: px-to-em(40px);
        margin-left: 1px;
        margin-right: 1px;

        &::after {
            vertical-align: middle;
        }

        &.slick-disabled {
            opacity: 0.5;
            cursor: initial;
        }
    }

    .carousel-spotlight__nav-link {
        &.is-selected {
            background-color: $color-alizarin-dark;
        }
    }

    @include screen($bp-mobile, min) {
        &__slide {
            padding-bottom: px-to-em($arrow-height) - 2em;
        }

        .carousel-spotlight__arrows {
            padding-bottom: $section-spacing-mobile - 2em;
        }
    
        .carousel-spotlight__nav-context {
            bottom: 2em;
        }

        .carousel-spotlight__nav-link {
            width: px-to-em(42px);
        }
    
        .slick-track {
            display: flex;
    
            .slick-slide {
                display: flex;
                height: auto;
                align-items: center;
            }
        }

        .slick-slide {
            transition: opacity 300ms ease;

            &:not(.slick-current) {
                opacity: 0.5;
            }
        }
    }

    @include screen($bp-mobile) {
        .carousel-spotlight__nav {
            padding-left: $site-padding-mobile;
            padding-right: $site-padding-mobile;
        }

        .carousel-spotlight__arrows {
            text-align: right;
        }
    }
}

.careers-testimonial {
    display: flex;
    
    &__photo {
        align-self: flex-start;
        width: 337px;
        margin-right: px-to-em($element-spacing-px * 1.5);
    }

    &__body {
        flex: auto;
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-family: "Lyon Text Web";
        font-size: px-to-em($font-size-heading-mobile);
        font-style: italic;
        line-height: 1.2;
        margin-bottom: px-to-em($element-spacing-px, $font-size-heading-mobile);

        &::before {
            content: "“";
        }
    }

    &__quote {
        font-family: "Lyon Text Web";
        line-height: 1.4;
        flex: 1 0 auto;
        margin-bottom: px-to-em($element-spacing-px, $font-size-copy);
    }

    &__subtitle {
        @include font-univers-condensed-light(20px);

        line-height: 1.2;
    }

    &__name {
        color: $color-black !important;
    }

    .btn-text-link-arrow {
        &::after {
            color: $color-alizarin-dark;
        }
    }

    @include screen($bp-mobile, min) {
        &__title {
            font-size: px-to-em($font-size-heading);
            margin: px-to-em($element-spacing-px, $font-size-heading) 0;
            position: relative;

            &::before {
                position: absolute;
                left: px-to-em(-24px, $font-size-heading);
            }
        }
    }

    @include screen($bp-mobile) {
        &__photo {
            display: none !important;
        }
    }
}

.careers-video {
    width: $max-width;
    max-width: 100%;
    border-radius: px-to-em(8px);
    overflow: hidden;
    
    @include screen($bp-mobile, min) {
        // The scale of the videos that are not in the current slide (the ones to left and right)
        $video-scale: 0.7;
        // Calculate how much to translate so the edge of the scaled-down video meets the edge of the slide
        $video-translate: 0.5 * (100% - ($video-scale * 100%));

        transform: translateX($video-translate) scale($video-scale); // Translate to the right
        transition: transform 300ms ease;

        .slick-center & {
            transform: scale(1);
        }

        .slick-center ~ .slick-slide & {
            transform: translateX(-$video-translate) scale($video-scale); // Translate to the left
        }
    }
}

.careers-highlight-section {
    background: #000 no-repeat center center;
    background-image: linear-gradient(0deg, #00000080, #00000080), var(--background);
    background-size: cover;
    color: $color-brand-white;
}

.careers-highlight {
    padding-top: px-to-em($section-spacing-largest-px + 16px, $font-size-copy);
    padding-bottom: px-to-em($section-spacing-largest-px, $font-size-copy);
    position: relative;

    &__badge {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__title {
        @include font-size-heading();

        margin-bottom: px-to-em($element-spacing-px, $font-size-heading);
        letter-spacing: px-to-em(-1px, $font-size-heading);
    }

    &__link {
        &:hover,
        &:focus {
            color: $color-alizarin-dark;
        }

        //outbound link
        .highlight-card--external & {
            @include ico-icon-after($icon-upward-arrow) {
                font-size: px-to-em(20px, $font-size-banner);
                margin-left: 0.5em;
            }
        }

        //download link
        .highlight-card--download & {
            @include ico-icon-after($icon-download) {
                font-size: px-to-em(24px, $font-size-banner);
                margin-left: 0.5em;
            }
        }
    }
}