.breadcrumbs-options {
  max-height: 102%;
  overflow: hidden;
}

.breadcrumbs-item {
  display: inline;
  color: $color-gray-on-gray-foreground;
}

.breadcrumbs-title {
  color: $color-brand-black;
}

a.breadcrumbs-title {
  color: $color-gray-on-gray-foreground;

  &:hover {
    color: $color-brand-black;
  }
}
