﻿.foundation-profile-detail {
  .social-links {
    display: inline-block;
  }

  .deceased-label {
    display: inline-block;
    position: relative;
    right: 0;
    bottom: 0;
  }
}
