$font-size-region-tile: 21px;
$font-size-region-tile-title: 26px;

.region-tile {
  $region-tile-padding: px-to-em(35px);

  @include gradient-overlay();
  @include bg-cover;

  min-height: px-to-em(280px);
  display: block;
  padding: $region-tile-padding;
  background-color: $color-nero-90;
  position: relative;
  color: $color-brand-white;

  @include transition(background-color);

  &:focus {
    outline-color: $color-brand-turquoise;
    outline-width: 2px;
    outline-offset: -1px;
  }

  &:hover {
    background-color: $color-opaque-red;
  }
}

.region-tile-title {
  @include font-univers-condensed-bold($font-size-region-tile-title);
  @include design-mark(6px, $font-size-region-tile);

  line-height: 1.2em;
  display: block;
  margin-top: 50%;
}

@include screen($bp-tablet, min) {
  $region-tile-padding: 1.3em;
  .region-tile {
    min-height: px-to-em(300px);
    padding: $region-tile-padding;
  }

  .region-tile-title {
    $font-size: 35px;

    font-size: px-to-em($font-size);

    &:after {
      width: px-to-em($global-line-mark, $font-size);
    }
  }
}
