.article-body {
  margin-bottom: $section-spacing-large;

  .share-tools {
    display: inline-block;

    &:after {
      background-color: $color-brand-white;
    }
  }

  .share-tools-trigger {
    color: $color-brand-black;
  }

  .share-tools-link-icon {
    color: $color-brand-black;
  }

  //social links
  .social-links {
    margin-top: $section-spacing;

    @include design-mark(21px, 16px, $color-gray-steel, before);
  }

  .social-links-item {
    margin-right: 1em;
    margin-bottom: 1em;
  }

  .social-links-icon {
    color: $color-brand-black;

    &:hover {
      color: $color-gray-steel;
    }
  }

  .ico-pdf {
    font-size: px-to-em(28px);
  }
}

.article-body-register {
  margin-top: $section-spacing-mobile;
}

@include screen($bp-mobile) {
  .article-body {
    margin-bottom: $section-spacing-large-mobile;

    .ico-pdf {
      font-size: px-to-em(24px);
    }
  }
}
