.promo-card-grid {
  margin-bottom: $section-spacing-large;
}

.promo-card-grid-list {
  margin-bottom: $section-spacing-large;
  text-align: center;
  display: flex;
  flex-wrap: wrap;

  .promo-card {
    flex-shrink: 0;
    flex-grow: 1;

    width: 50%;

    &:nth-child(2n) {
      border-left: 2px solid $color-brand-white;
    }
  }
}

@include screen($bp-tablet) {
  .promo-card-grid-list {
    display: block;

    .promo-card {
      width: 100%;

      &:nth-child(2n) {
        border-top: 2px solid $color-brand-white;
        border-left: 0 none;
      }
    }
  }
}

@include screen($bp-mobile) {
  .promo-card-grid {
    margin-bottom: $section-spacing-large-mobile;
  }
}
