.site-header {
  width: 100%;
  height: px-to-em($site-header-height);
  background-color: $color-brand-white;
  border-bottom: 1px solid $color-site-header-border;
  position: fixed;
  z-index: $zindex-site-header;
  vertical-align: middle;
  display: flex;
  flex-wrap: nowrap;
  transform: translateZ(0);
  top: 0;

  .mobile-toggle,
  .site-search-toggle {
    float: right;
    height: 100%;
    flex-shrink: 0;
  }

  .site-search-toggle {
    border-bottom-color: $color-brand-white;
  }
}

.site-logo {
  width: px-to-em(177px);
  display: inline-block;
  float: left;
  height: 100%;
  color: $color-brand-white;
  background-color: $color-brand-red;
  flex-shrink: 0;
}

.site-logo-img {
  display: block;
  width: 100%;
}

.site-header-utility {
  @include font-univers-condensed-bold($font-size-site-header-utility);

  line-height: 1.2;
  position: relative;
  height: 100%;
  flex-grow: 1;
}

.site-header-firm-label {
  width: 100%;
  height: 100%;
  padding: 0.5em 1.2em;
  background-color: $color-gray-on-gray-background;
}

.site-header .breadcrumbs {
  width: 100%;
  height: 100%;
  padding: 0.25em 1.2em;
  background-color: $color-gray-on-gray-background;
  position: absolute;

  .breadcrumbs-options {
    @include center(vertical, relative);
  }
}

.site-header-firm-name {
  @include center(vertical, relative);

  display: block;
  color: $color-gray-on-gray-foreground;
}

//transition perspective
.site-header--perspective {
  $transition-cube-timing: 0.5s;

  .site-header-utility {
    position: relative;
    height: 100%;
    perspective: 1100px;
    perspective-origin: 50% 0;
  }

  .breadcrumbs,
  .site-header-firm-label {
    height: 100.75%;
    // backface-visibility: hidden;
    overflow: hidden;
    transition: transform $transition-cube-timing, top $transition-cube-timing;
  }

  .site-header-firm-label {
    z-index: 2;
    position: relative;

    transform: translateY(0%) rotateX(0deg);
    transform-origin: 50% 100%;
  }

  .breadcrumbs {
    position: absolute;
    top: 100%;
    transform: rotateX(-90deg);
    transform-origin: 50% 0%;
  }
}
//rotated back
.is-animated-rotated {
  .site-header-firm-label {
    transform: translateY(-100%) rotateX(90deg);
  }

  .breadcrumbs {
    transform: translateY(-100%);
  }
}

//IE fix to prevent a delay with having the firm label appear
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .site-header--perspective {
    .site-header-firm-label {
      opacity: 1;
    }
  }

  .is-animated-rotated {
    .site-header-firm-label {
      transform: translateY(-100%) rotateX(89deg);
    }
  }
}

@include screen($bp-mobile) {
  .site-header {
    height: px-to-em($site-header-height-mobile);
    background-color: $color-brand-gray;

    .site-search-toggle,
    .mobile-toggle {
      width: px-to-em($site-header-ui-width-mobile);
    }

    .site-search-toggle {
      &:before {
        font-size: px-to-em(30px);
        transform: translate(0);
      }
    }

    .site-search-toggle-label,
    .mobile-toggle-label {
      font-size: px-to-em(14px);
      visibility: hidden;
      position: absolute;
    }

    .mobile-toggle-bars {
      $bars-width: px-to-em(32px);
      $bars-height: px-to-em(3px);
      $bars-spacing: px-to-em(6px);

      width: $bars-width;
      height: $bars-height;
      margin-top: 1.25em;

      &:before,
      &:after {
        height: $bars-height;
      }

      &:before {
        margin-bottom: $bars-spacing;
      }

      &:after {
        margin-bottom: $bars-spacing * 2 + $bars-height;
      }
    }
  }

  .site-logo {
    width: px-to-em(170px);
  }
}

@include screen($bp-mobile-landscape) {
  .site-header {
    display: block;
    text-align: right;

    .site-search-toggle,
    .mobile-toggle {
      width: px-to-em($site-header-ui-width-mobile);
      float: right;
    }

    .site-search-toggle {
      transform: translateX(-100%);
      position: absolute;
      right: 0;
    }

    .site-search-toggle-label,
    .mobile-toggle-label {
      margin-left: 0;
      width: 100%;
      display: inline-block;
    }
  }
  .site-header-utility {
    display: none;

    .breadcrumbs {
      visibility: hidden;
    }
  }

  .site-logo {
    float: left;
  }
}
