.section-heading--with-checkbox {
  @include clearfix;

  .heading-checkbox {
    display: block;
    clear: both;

    @include screen($bp-mobile, min) {
      float: none;
      display: inline-block;
      margin-left: $element-spacing-small-px;
    }
  }

  .heading-checkbox__input {
    line-height: 1;
  }

  .heading-checkbox__label {
    display: inline-block;
    font-size: $font-size-base;
    line-height: 1;

    a {
      color: $color-link;
      display: inline-block;

      &:hover {
        color: $color-link-hover;
      }
    }
  }
}
