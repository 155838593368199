.site-header-nav {
  width: 100%;
  height: 100%;
  padding: 0.5em 1em 0.5em 0.5em;
  background-color: $color-gray-light;
  text-align: right;
}

.site-header-nav-options {
  display: table;
  height: 100%;
  float: right;
}

.site-header-nav-item {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 1em;
}
.site-header-nav-title {
  @extend %uc;
  position: relative;
  color: $color-gray-steel;

  &:hover {
    color: $color-brand-black;
  }

  .is-current & {
    color: $color-brand-black;
  }
}
