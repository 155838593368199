//if used as a standalone component
.content-banner-wrapper {
  @include content-wrapper;
}

.content-banner {
  @include clearfix;

  position: relative;
  display: block;
  padding-top: px-to-em($featured-banner-offset);
  padding-left: px-to-em($featured-banner-offset);
}

a.content-banner {
  &:hover {
    .content-banner-title {
      background-color: $color-link;
    }

    .content-banner-info {
      &:before {
        border-color: $color-link-hover;
      }
    }
  }

  &.no-link {
    &:hover {
      .content-banner-title {
        background-color: $color-charcoal;
      }

      .content-banner-info {
        &:before {
          border-color: $color-brand-red;
        }
      }
    }
  }
}

a.content-banner-tile {
  display: block;

  &:hover {
    .content-banner-title {
      background-color: $color-link;
    }

    .content-banner-info {
      &:before {
        border-color: $color-link-hover;
      }
    }
  }
}

.content-banner-abstract.rte {
  a {
    color: black !important;

    &:hover {
      color: $color-brand-red-on-medgray-foreground !important;
    }
  }
}

.content-banner-title {
  @include font-size-banner();

  background-color: $color-charcoal;
  color: $color-brand-white;
  transition: $transition-default;
  padding: px-to-em($featured-banner-offset, $font-size-banner);

  word-wrap: break-word;
}

.content-banner-tile {
  float: left;
  width: px-to-em($featured-banner-tile-width);
  margin-top: px-to-em($featured-banner-offset) * -1;
  margin-left: px-to-em($featured-banner-offset) * -1;
}

.content-banner-info {
  @include design-mark($placement: before);

  position: static;
  color: $color-brand-black;
  background-color: $color-featured-banner;
  min-height: $featured-banner-min-height;
  padding: $element-spacing $element-spacing $section-spacing;
  padding-left: px-to-em($featured-banner-tile-width) + $element-spacing-small;

  word-wrap: break-word;

  &:before {
    @include transition(border-color);
    margin-top: $element-spacing-small;
  }
}

@include screen($bp-desktop) {
  //context
  .back-to-top {
    .content-banner-tile {
      width: px-to-em($featured-banner-tile-width-medium);
    }

    .content-banner-info {
      padding-left: px-to-em($featured-banner-tile-width-medium) +
        $element-spacing-small;
    }
  }
}

@include screen($bp-mobile) {
  .content-banner-tile {
    width: px-to-em($featured-banner-tile-width-small);
  }

  .content-banner-title {
    padding: px-to-em($featured-banner-offset, $font-size-banner-mobile);
  }

  .content-banner-info {
    padding-left: px-to-em($featured-banner-tile-width-small) +
      $element-spacing-small;
    min-height: auto;
  }

  .back-to-top {
    .content-banner-tile {
      width: px-to-em($featured-banner-tile-width-small);
    }

    .content-banner-info {
      padding-left: px-to-em($featured-banner-tile-width-small) +
        $element-spacing-small;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .content-banner {
    padding-left: 0;
  }

  .content-banner-info {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }

  .content-banner-tile {
    background-color: $color-featured-banner;
    float: none;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    text-align: center;
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }

  .content-banner-title {
    display: inline-block;
    width: 100%;
    margin-top: px-to-em($featured-banner-offset, $font-size-banner-mobile) * -1;
    position: relative;
  }

  .back-to-top {
    .content-banner-tile {
      width: 100%;
    }

    .content-banner-info {
      padding-left: $site-padding-mobile;
    }
  }
}
