.recent-news-list {
  margin: 0;
}

.recent-news-list-item {
  $list-spacing: px-to-em(35px);

  padding: $list-spacing 0;
  margin-bottom: 0;

  & + & {
    border-top: 1px solid transparent;
  }
}

.recent-news-list-link {
  $title-size: 33px;

  @include font-univers-condensed-bold($title-size);
  display: block;
  line-height: 1.2;
  position: relative;
  padding-right: $arrow-padding;

  &:hover {
    color: $color-link-hover;

    &:after {
      //color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    font-size: px-to-em($arrow-icon-size, $title-size);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    //color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }
}

@include screen($bp-tablet) {
  .recent-news-list-link {
    font-size: px-to-em(24.5px);
  }
}

@include screen($bp-mobile) {
  .recent-news-list-item {
    padding: $section-spacing-small 0;
  }
}
