$suggested-search-spacing: px-to-em(58px);

.suggested-search {
  padding-top: $suggested-search-spacing;
  background-color: $color-brand-white;

  .searchbox {
    padding-top: $element-spacing;
    margin-bottom: $suggested-search-spacing;

    margin-left: auto;
    margin-right: auto;
    max-width: px-to-em(945px);
  }

  .searchbox-input {
    font-size: px-to-em(32px);
  }

  .searchbox-btn {
    &:before {
      font-size: 1.64rem; //need to resort to rem since IE11 doesnt compute icon size correctly
    }
  }
}

.suggested-search-branding {
  background: $color-brand-white
    url($file-path-images + "/locations-pattern-wide.png") center top;
  text-align: center;
}

.suggested-search-brand-logo {
  margin: px-to-em(61px) auto;
  width: px-to-em(180px);
  height: px-to-em(61px);
}

@include screen($bp-mobile) {
  .suggested-search {
    padding-top: $section-spacing-large-mobile;

    .searchbox {
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
