$professional-card-height: px-to-em(162px);
$professional-card-img-width: px-to-em(136px);

.professional-card {
  display: table;
  width: 100%;
  min-height: $professional-card-height;
}

a.professional-card {
  &:hover {
    .professional-card-details {
      background-color: lighten($color-gray-light, 2%);
    }

    .professional-card-name {
      color: $color-gray-steel;
    }
  }
}

.professional-card-figure,
.professional-card-details {
  display: table-cell;
  vertical-align: top;
}

.professional-card-figure {
  position: relative;
  overflow: hidden;
  width: $professional-card-img-width;
  max-width: $professional-card-img-width;

  > img {
    @include center(horizontal, relative);
    display: block;
    min-height: 100%;
  }
}

.professional-card-name {
  @include transition(color);
  @include font-size-entity-title();
}

.professional-card-details {
  @include transition(background-color);

  padding: 1.2em 1.5em 0.4em;
  text-align: left;
  background-color: $color-gray-light;

  @include design-mark(17.5px);

  &:after {
    padding-top: 0;
  }
}
.professional-card-position {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
}

@include screen($bp-mobile) {
  .professional-card-details {
    padding: 1.25em;
  }

  .professional-card-name {
    margin-bottom: 0.25em;
  }

  .professional-card-position {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}
