$back-to-top-font-size: 21px;
$back-to-top-border-width: 10px;

.back-to-top-trigger {
  @include font-univers-condensed-bold($back-to-top-font-size);

  position: absolute;
  width: px-to-em($back-to-top-btn-width, $back-to-top-font-size);
  display: inline-block;
  line-height: 1.2;
  text-align: left;
  float: left;
  color: $color-gray-steel;
  top: px-to-em($section-spacing-large-px, $back-to-top-font-size);
  transform: translate3d(0, 0, 0);

  @include ico-icon-before($icon-up-arrow) {
    color: $color-brand-red;
    display: block;
    font-size: 0.6em;
    margin: 1em 0 0.5em;
  }

  &.is-sticky-top {
    position: fixed;
    top: px-to-em(
      $section-spacing-large-px + $site-header-height,
      $back-to-top-font-size
    );
  }

  &.force-160 {
    position: fixed;
    top: 163px;
  }

  &.is-sticky-bottom {
    position: absolute;
    bottom: 0;
    top: auto;
  }
}

@include screen($bp-back-to-top) {
  .back-to-top-trigger {
    display: none;
  }
}
