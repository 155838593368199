$font-office-card-size: 21px;

.office-card-title {
  @include font-univers-condensed-bold($font-office-card-size);

  line-height: 1.2em;
  text-transform: uppercase;
}

.office-card-link {
  color: $color-text-red;
  display: inline-block;

  &:hover {
    color: $color-brand-white;
  }
}

.office-card-address {
  @include font-univers-condensed-light($font-office-card-size);

  line-height: 1.3;

  margin: 0.4em auto;
}

.office-card-phone {
  @include font-univers-condensed-light($font-office-card-size);

  line-height: 1.3;
  text-transform: uppercase;
}

.office-card-phone-label {
  width: 1em;
  display: inline-block;
  color: $color-brand-gray;
}

.office-card-map {
  $font-size: 16px;

  @include font-univers-condensed-bold($font-size);

  display: inline-block;
  text-transform: uppercase;
  color: $color-red-on-black-foreground;
  line-height: 1.2;
  margin-top: px-to-em($section-spacing-small-px, $font-size);

  @include ico-icon-after($icon-location) {
    margin-left: 0.5em;
  }

  &:hover {
    color: $color-brand-white;
  }
}

@include screen($bp-mobile) {
  $font-office-card-size: 18px;

  .office-card-title {
    font-size: px-to-em(20px);
  }

  .office-card-name,
  .office-card-phone,
  .office-card-address {
    font-size: px-to-em($font-office-card-size);
  }
}

.office-card {
  &.parent-office {
    .office-card-address {
      margin-top: 0;
    }
  }
}
