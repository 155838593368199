@use "sass:math";

.listing-jobs-item {
  @include clearfix;

  position: relative;
  padding-bottom: px-to-em($listing-padding);
  padding-right: $arrow-padding * 2;
  border-bottom: $border-dark;

  & + & {
    padding-top: px-to-em($listing-padding);
  }

  //first item in the list need to have the arrow readjusted to be visually centered,
  &:first-child {
    .listing-jobs-title {
      &:after {
        transform: translateY(-125%);
      }

      &:hover {
        &:after {
          transform: translateX(100%) translateY(-125%);
        }
      }

      &.no-transform {
        &:after {
          transform: translateY(0);
        }

        &:hover {
          &:after {
            transform: translateX(0) translateY(0);
          }
        }
      }
    }
  }
}

a.listing-jobs-title {
  @include font-size-banner();

  display: block;

  &:hover {
    color: $color-link-hover;

    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    font-size: px-to-em($arrow-icon-size, $font-size-banner);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }

  .meta-industry {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }
}

span.listing-jobs-title {
  @include font-size-banner();

  padding-right: $arrow-padding;
  display: block;

  .meta-industry {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }
}

.listing-jobs-item-pdf-download {
  .listing-jobs-title {
    &:after {
      content: $icon-download;
      font-size: px-to-em($arrow-icon-size * 1.3, $font-size-banner);
      position: relative;
      right: auto;
      transform: translateY(0);
      margin-left: 0.5em;
    }

    &:hover {
      &:after {
        transform: translateY(0);
      }
    }
  }
}

.listing-jobs-meta {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
  margin-top: px-to-em(
    math.div($section-spacing-small-px, 2),
    $font-size-entity-title
  );

  color: $color-gray-steel;

  .meta-category + .meta-date {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }

  .meta-location + .meta-date {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }
}

.listing-jobs-description {
  line-height: 1.4;
  margin-top: 0.5em;
}

@include screen($bp-mobile) {
  .listing-jobs-item {
    padding: px-to-em($listing-padding-mobile) $arrow-padding
      px-to-em($listing-padding-mobile) 0;

    &:first-child {
      .listing-jobs-title {
        &:after {
          transform: translateY(0);
        }
      }
    }
  }

  .listing-jobs-title {
    //chevron
    &:after {
      bottom: px-to-em($listing-padding);
      top: auto;
      right: 0;
      transform: translateY(0);
    }

    &:hover {
      &:after {
        transform: translateX(100%);
      }
    }
  }

  .listing-jobs-item-pdf-download {
    .listing-jobs-title {
      &:after {
        bottom: auto;
        right: auto;
      }
    }
  }

  .listing-jobs-meta {
    font-size: px-to-em($font-size-entity-title-mobile);
    margin-top: px-to-em(
      math.div($section-spacing-small-px, 2),
      $font-size-entity-title-mobile
    );
  }
}

.listing-jobs-item-by-location {
  $padding: 32px;

  @include clearfix;

  position: relative;
  padding-bottom: px-to-em($padding);
  padding-right: $arrow-padding * 2;

  & + & {
    padding-top: px-to-em($padding);
  }

  .listing-jobs-meta {
    font-size: px-to-em($font-size-site-header-utility);
    a {
      color: $color-link;
      &:hover {
        color: $color-link-hover;
      }
    }
  }

  .rte {
    margin-top: 1.6em;
    font-size: px-to-em($font-size-site-header-utility);
  }
}

.listing-jobs-by-location-office {
  @include font-size-banner();
  border-bottom: $border-dark;
  padding-right: $arrow-padding;
}

.listing-jobs-by-location-title {
  font-weight: regular;
}

.listing-jobs-by-location-department {
  text-transform: uppercase;
  @include font-size-buttons();
}

.listing-jobs-by-location-link {
  padding-right: $arrow-padding;
  padding-top: 1.5em;
  display: block;
  position: relative;

  &:hover {
    color: $color-link-hover;

    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    font-size: px-to-em($arrow-icon-size, $font-size-base);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }
}
