.insight-tile-headline {
  $insight-padding: px-to-em(35px);

  display: block;
  padding: $insight-padding;
  background-color: $color-nero-90;
  position: relative;
  color: $color-brand-white;

  @include transition(background-color);

  &:focus {
    outline-color: $color-brand-turquoise;
    outline-offset: -1px;
  }

  &:hover {
    background-color: $color-opaque-red;

    .insight-tile-headline-title {
      &:after {
        border-color: $color-brand-black;
      }
    }
  }
}

div.insight-tile-headline {
  background-color: $color-nero-90;

  &:hover {
    background-color: $color-nero-90;

    .insight-tile-headline-title {
      &:after {
        border-color: $color-brand-red;
      }
    }
  }
}

.insight-tile-headline-title {
  $font-size: 42px;

  @include font-univers-condensed-bold($font-size);
  line-height: 1.2em;

  &:after {
    @include transition(border-color);

    content: "";
    display: block;
    width: px-to-em($global-line-mark, $font-size);
    border-top: 2px solid $color-brand-red;
    margin-top: px-to-em(22px, $font-size);
  }
}

@include screen($bp-tablet) {
  $insight-padding: 1.3em;

  .insight-tile-headline {
    padding: $insight-padding;
  }

  .insight-tile-headline-title {
    $font-size: 28px;

    font-size: px-to-em($font-size);

    &:after {
      width: px-to-em($global-line-mark, $font-size);
    }
  }
}
