@use "sass:math";

.alpha-search {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-left: 2px;
  margin-right: 2px;
}

.alpha-search-item {
}

.alpha-search-btn {
  $font-size-alpha: 17px;

  @include font-univers-condensed-bold($font-size-alpha);

  line-height: 1.1;

  &:hover {
    color: $color-gray-steel;
  }

  &.is-active {
    color: $color-link;
  }

  &.inactive {
    color: $color-gray-steel;
  }
}

@include screen($bp-tablet) {
  .alpha-search {
    flex-wrap: wrap;
  }

  .alpha-search-item {
    margin-bottom: $element-spacing-small;
    text-align: center;
  }

  .alpha-search-item {
    flex-shrink: 0;
    width: math.div(100, 13) * 1%;
  }
}

@include screen($bp-mobile-landscape) {
  .alpha-search {
    justify-content: flex-start;
  }
  .alpha-search-item {
    flex-shrink: 0;
    width: math.div(100, 9) * 1%;
  }
}

@include screen($bp-mobile-small) {
  .alpha-search-item {
    flex-shrink: 0;
    width: math.div(100, 6) * 1%;
  }
}
