.key-contributors {
  margin-bottom: $section-spacing-large;
}

.key-contributors-intro {
  margin-bottom: px-to-em($element-spacing-px, $font-size-copy);
}

@include screen($bp-mobile) {
  .key-contributors {
    margin-bottom: $section-spacing-large-mobile;
  }
}
