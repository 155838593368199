.call-to-action-wrapper {
  @include content-wrapper;
}

.call-to-action {
  margin-bottom: $section-spacing;
}

.call-to-action-heading {
  @include font-univers-condensed-light($section-heading-size);

  line-height: 1.1;
  margin-bottom: px-to-em($section-heading-spacing, $section-heading-size);

  @include design-mark(24px, $section-heading-size, $color-brand-gray);

  & + .call-to-action-link {
    border-top: 0 none;
    padding-top: 0;
  }
}

.call-to-action-link {
  @include font-size-banner();

  position: relative;
  display: block;
  padding: px-to-em($section-spacing-small-px, $font-size-banner) 0;
  padding-right: $arrow-padding;

  border: $border-dark;
  border-width: 1px 0;

  &:hover {
    color: $color-link-hover;

    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    @include transition(color);

    font-size: px-to-em($arrow-icon-size, $font-size-banner);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }
}

@include screen($bp-mobile) {
  .call-action {
    margin-bottom: $section-spacing-mobile;
  }

  .call-to-action-heading {
    font-size: px-to-em($section-heading-mobile-size);

    line-height: 1.1;

    @include design-mark(24px, $section-heading-mobile-size, $color-brand-gray);
  }
}

@include screen($bp-mobile-small) {
  .call-to-action-heading {
    word-break: break-word;
    word-wrap: break-word;
  }
}
