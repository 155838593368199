.page-alumni-directory {
  .impactful-lead {
    padding: $section-spacing-large 0 0;
    background-color: $color-search-filters;
  }

  .alumni-directory-results-load-more {
    margin: $section-spacing-small auto 0;
    display: block;
  }

  .search-landing-results {
    padding-top: $section-spacing-large;
    margin-bottom: $section-spacing-largest;

    &[aria-hidden="true"] {
      display: none;
    }
  }

  @include screen($bp-tablet) {
    .search-criteria-wrapper {
      display: block;

      .applied-filters,
      .sort-controls {
        display: block;
      }

      .sort-controls {
        text-align: left;
        margin-top: 0.9em;
        clear: left;
        padding-left: 0;
        white-space: normal;
      }
    }
  }

  @include screen($bp-mobile) {
    .impactful-lead {
      padding: $section-spacing-large-mobile 0 0;
    }

    .search-landing-results {
      padding-top: $section-spacing-large-mobile;
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
