$credentials-grid-spacing: 2px;

.search-criteria {
  margin-bottom: $section-spacing-small * 2;
}

.search-criteria-wrapper {
  @include page-wrapper;

  display: table;
  width: 100%;
  position: relative;
  padding-bottom: px-to-em(5px);

  .applied-filters,
  .sort-controls {
    display: table-cell;
    vertical-align: bottom;
  }

  //bottom border
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 1px;
    left: $site-padding;
    right: $site-padding;
    border-bottom: $border-dark;
  }

  .sort-controls {
    padding-left: 1em;
    white-space: nowrap;
  }
}

@include screen($bp-tablet) {
  .search-criteria-wrapper {
    &:after {
      left: $site-padding-mobile;
      right: $site-padding-mobile;
    }
  }
}

@include screen($bp-mobile) {
  .search-criteria-wrapper {
    display: block;

    .applied-filters,
    .sort-controls {
      display: block;
    }

    .sort-controls {
      text-align: left;
      margin-top: 1em;
      clear: left;
      padding-left: 0;
      white-space: normal;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .search-criteria {
    margin-bottom: $section-spacing-mobile;

    &:after {
      bottom: -3px;
    }
  }
}
