.job-header {
  position: relative;
  background-color: $color-black-background;
  padding: $section-spacing-large 0;

  .page-wrapper {
    position: relative;
  }
}

.job-header-details {
  color: $color-brand-white;
  padding-right: $site-padding * 1.25;
}

.job-header-details-title {
  @include font-univers-condensed-bold(36px);

  line-height: 1.1;

  @include screen($bp-mobile-landscape, min) {
    @include font-univers-condensed-bold(42px);
  }
}

.job-header-meta {
  @include font-univers-condensed-light-oblique($font-size-h3);
  @include design-mark(17px, $font-size-h3, $color-brand-gray);

  line-height: $profile-line-height;

  .meta-item {
    & + .meta-item {
      &:before {
        content: "//";
        display: inline-block;
        margin: 0 0.5em;
      }
    }
  }
}

.job-header-date,
.job-header-related-item {
  @include font-size-entity-title;
}

.job-header-date-label,
.job-header-related-label {
  @include font-univers-condensed();

  &:after {
    content: ":";
    margin-right: 0.5em;
  }
}

.job-header-related {
  color: $color-brand-white;
}

.job-header-related-item {
  margin-top: 0.5em;
}

.job-header-authors {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;

  margin-top: 0.5em;
}

.job-header-author {
  @include font-univers-condensed-bold();

  display: inline-block;
  color: $color-brand-red;

  &:after {
    @include font-univers-condensed();
    content: ", ";
    color: $color-brand-white;
  }

  &:last-child {
    &:after {
      content: "";
    }
  }
}

span.job-header-author {
  color: $color-text-gray-soft;
}

//social media
.social-links {
  .job-header & {
    $spacing: 26.25px;

    position: absolute;
    top: 0;
    right: $site-padding;

    .social-links-item {
      display: block;
      line-height: 1;
      margin-bottom: 1em;
    }

    .ico-pdf {
      font-size: px-to-em(28px);
    }

    .ico-share {
      font-size: px-to-em(23px);
    }
  }
}

@include screen($bp-scrollspy) {
  .job-header {
    position: relative;
    z-index: $zindex-scrollspy-nav + 5;
  }
}

@include screen($bp-mobile) {
  .job-header {
    padding: $section-spacing-large-mobile 0;
    text-align: center;
  }

  .job-header-details {
    padding-right: 0;
  }
  .job-header-title {
    $header-size-mobile: 36px;

    font-size: px-to-em($header-size-mobile);
  }

  .job-header-authors {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .job-header-meta {
    font-size: px-to-em($font-size-h3-mobile);

    line-height: 1.6;

    &:after {
      margin-left: auto;
      margin-right: auto;
      width: px-to-em($global-line-mark, $font-size-h3-mobile);
      padding-top: 1em;
    }
  }

  .social-links {
    .job-header & {
      $spacing: 30px;

      position: relative;
      top: auto;
      right: auto;
      display: inline-block;
      margin-top: 1em;
      vertical-align: middle;

      .social-links-item {
        display: inline-block;
        margin-bottom: 0;
        width: px-to-em(45px);
        text-align: left;
      }

      .ico-pdf {
        font-size: px-to-em(20px);
      }

      .ico-share {
        font-size: px-to-em(20px);
      }
    }
  }
}
