.job-related-material {
  margin-bottom: $section-spacing-large;

  .listing-files {
    margin-bottom: $section-spacing;
  }

  .job-related-material-note {
    max-width: px-to-em(375px);
    margin-top: $element-spacing-small;
  }
}

@include screen($bp-mobile) {
  .job-related-material {
    margin-bottom: $section-spacing-large-mobile;

    .listing-files {
      margin-bottom: $section-spacing-mobile;
    }
  }
}
