@use "sass:math";

.insightful-card {
  @include bg-cover;

  $padding-horizontal: 20px;
  color: $color-brand-white;
  background-color: $color-brand-black;
  padding: px-to-em(30px) px-to-em($padding-horizontal) 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-direction: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: px-to-em(240px);
  overflow: hidden;
  position: relative;

  @include gradient-overlay($start-color: rgba(0, 0, 0, 0));

  &--accordion {
    &--selected {
      &:before {
        @include transition(background);
        background: $color-brand-red;
      }
    }
  }

  &:before {
    z-index: 1;
  }

  &__background {
    @include transition(opacity);
    @include absolute-full;
    @include bg-cover;
  }

  &__title {
    @include font-size-banner();
    padding-bottom: px-to-em(45px, $font-size-banner);

    z-index: 2;
    //red mark
    @include design-mark(
      $spacing: $element-spacing-px,
      $font-size: $font-size-banner,
      $placement: before
    );

    &:before {
      position: absolute;
      bottom: 0;
    }

    // @include ico-icon-after($icon-chevron-right) {
    //   display: inline-block;
    //   color: $color-red-on-black-foreground;
    //   padding-left: 0.3em;
    //   font-size: px-to-em(22px, $font-size-banner-mobile);
    //   vertical-align: middle;
    // }
  }

  &__peek-content {
    position: relative;
    z-index: 2;
    flex-shrink: 0;

    @include transition(height);
    height: 0;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      padding-bottom: $element-spacing;
    }
  }

  &__abstract {
    margin-bottom: math.div($element-spacing-px, 2);
    line-height: 1.5;

    em,
    i {
      font-family: inherit;
      font-style: italic;
    }
    strong,
    b {
      font-family: inherit;
      font-weight: 700;
    }
  }

  &__abstract,
  &__supplemental-label {
    font-family: $font-family-copy;
  }

  &__supplement-label {
    @include font-univers-condensed-bold();

    font-size: px-to-em($font-size-entity-title-mobile);
    color: $color-red-on-black-foreground;
    padding-top: px-to-em($element-spacing-px, $font-size-entity-title-mobile);

    em {
      font-weight: 700;
    }
  }

  @include screen($bp-mobile, min) {
    height: px-to-em(370px);

    &--accordion {
      height: px-to-em(240px);
    }

    &:after {
      z-index: 2;
      top: 85%;
    }

    &__title {
      &:after {
        content: none;
      }
    }

    &:after {
      @include transition(opacity);
      opacity: 0;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &--accordion:hover {
      &:after {
        opacity: 0;
      }
    }

    &:hover &,
    &--accordion--not-selected & {
      &__background {
        opacity: 0.5;
      }
    }
  }

  @include screen($bp-tablet, min) {
    padding: px-to-em(35px) px-to-em(30px) 0;
  }
}
