$site-search-nav-font-size: 20px;
$site-search-nav-border-width: 10px;

$site-search-nav-width: 150px;

$site-search-content-offset: 250px;
$site-search-nav-height-tablet: 70px;
$site-search-nav-font-size-tablet: 18px;

.site-search-sections {
  margin-left: px-to-em($site-search-content-offset);
}

.site-search-criteria-wrapper {
  padding-bottom: 0.5em;
  border-bottom: 1px solid;
}

@include screen($bp-scrollspy) {
  .site-search {
    position: relative;
  }
  .site-search-sections {
    margin-left: 0;
  }

  .site-search-criteria-wrapper {
    margin-left: 0;
  }
}

@include screen($bp-mobile) {
  .site-search {
    padding-top: px-to-em(
      $section-spacing-large-mobile-px + $site-search-nav-height-tablet
    );
    margin-bottom: $section-spacing-large-mobile;
  }
}

.site-search-nav {
  width: px-to-em($site-search-nav-width);
  float: left;
}

.site-search-nav-label {
  $font-size: 17px;

  @include font-univers-condensed-bold($font-size);

  display: inline-block;
  line-height: 1.8;
  text-transform: uppercase;
  margin-bottom: $section-spacing-small;

  &:after {
    content: ":";
  }
}

.site-search-nav-option {
  padding-bottom: $section-spacing-small;
  white-space: nowrap;

  & + & {
    border-top: $border-dark;
    padding-top: $section-spacing-small;
  }

  &.is-selected {
    .site-search-nav-link {
      color: $color-brand-red;

      &:after {
        opacity: 1;
      }
    }
  }
}

.site-search-nav-link {
  @include font-univers-condensed-bold($site-search-nav-font-size);

  line-height: 1.2;
  display: block;
  text-transform: uppercase;
  color: $color-gray-steel;
  position: relative;

  &:hover {
    color: $color-brand-black;
  }

  &:after {
    @include center(vertical);
    @include transition(opacity);

    content: "—";
    right: 100%;
    margin-right: 0.25em;
    opacity: 0;
  }
}

.site-search-nav-toggle {
  display: none;
}

@include screen($bp-scrollspy, min) {
  .site-search-nav {
    &.is-sticky {
      top: px-to-em($site-header-height + $section-spacing-large-px) !important;
    }
  }
}

@include screen($bp-scrollspy) {
  .site-search-nav {
    float: none;
    width: auto;
    position: relative;
    z-index: 5;
  }

  .site-search-nav-dropdown {
    vertical-align: middle;
    display: inline-block;
    max-width: px-to-em(280px);
    width: 100%;
    position: relative;
  }

  .site-search-nav-toggle {
    background-color: $color-brand-white;
    border-bottom: $border-dark;

    @include font-univers-condensed-bold($form-input-font-size);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    color: $color-gray-steel;
    padding: px-to-em($form-input-padding, $form-input-font-size);
    padding-right: $arrow-padding * 1.25;
    border-bottom: $border-dark;
    width: 100%;
    text-align: left;
    position: relative;
    z-index: 2;
    display: inline-block;

    &:hover {
      color: $color-link-hover;

      &:after {
        color: $color-link-hover;
      }
    }

    @include ico-icon-after($icon-chevron-down-alt) {
      @include center(vertical);

      font-size: px-to-em($arrow-down-icon-size, $form-input-font-size);
      right: px-to-em($arrow-listing-offset, $arrow-down-icon-size);
      color: $color-link;

      transition: color $transition-timing $transition-ease;
    }

    .show-menu & {
      &:after {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  .site-search-nav-items {
    width: 100%;
    background-color: $color-gray-light;
    position: absolute;
    opacity: 0;
    top: 0;
    visibility: hidden;
    transition: top $transition-timing $transition-ease,
      opacity $transition-timing $transition-ease,
      visibility $transition-timing $transition-ease $transition-timing;

    .show-menu & {
      top: 100%;
      opacity: 1;
      visibility: visible;
      transition: top $transition-timing $transition-ease,
        opacity $transition-timing $transition-ease;
    }
  }

  .site-search-nav-option {
    border-bottom: 1px solid $color-brand-gray;
    padding: 0;

    & + & {
      border-top-width: 0;
      padding-top: 0;
    }

    &.is-selected {
      display: none;
    }
  }

  .site-search-nav-link {
    border-left: 0;
    padding: px-to-em($form-input-padding);

    &:after {
      content: no-close-quote;
    }
  }
}
