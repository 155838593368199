.autocomplete-suggestions-wrapper {
  position: relative;
  margin-right: -2px;
}

.autocomplete-suggestions {
  $border-style: 1px solid $color-border;

  background-color: $color-brand-white;
  overflow-y: auto;
  width: 100% !important;
  max-height: 450px;
  overflow-y: auto;

  .autocomplete-no-suggestion,
  .autocomplete-suggestion {
    padding: rem(15px) rem(20px);
    border: $border-style;
    line-height: 1.2;
    cursor: pointer;

    .auto-term {
      color: $color-brand-red;
    }

    &:hover {
      background-color: $color-gray-med;
    }
  }

  .autocomplete-selected {
    background-color: $color-gray-med;
  }
}
