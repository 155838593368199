.listing-general-entity-item {
  @include clearfix;

  position: relative;
  padding-bottom: px-to-em($listing-padding);
  border-bottom: $border-dark;

  & + & {
    padding-top: px-to-em($listing-padding);
  }
}

.listing-general-entity-title {
  @include font-size-entity-title();

  display: block;
}

@include screen($bp-mobile) {
  .listing-general-entity-item {
    padding-bottom: px-to-em($listing-padding-mobile);

    & + & {
      padding-top: px-to-em($listing-padding-mobile);
    }
  }
}
