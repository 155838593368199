.section-subnav {
  $border-spacing: 0.55em;

  text-align: center;

  .section-nav + & {
    margin-top: $section-spacing;
  }

  .section-subnav-links-option {
    display: inline;
    margin-right: $border-spacing;

    &:last-child {
      .section-subnav-links-title {
        border-right: 0 none;
      }
    }
  }

  .section-subnav-links-title {
    border-right: 1px solid $color-gray-steel;
    padding-right: $border-spacing;
    margin-top: $section-spacing-small;
  }
}

.section-subnav-wrapper {
  padding-left: $site-padding;
  padding-right: $site-padding;
}

@include screen($bp-tablet) {
  .section-subnav-wrapper {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }
}
