.related-experience {
  margin-bottom: $section-spacing-large;
}

.related-experience-intro {
  margin-bottom: px-to-em($section-spacing-px, $font-size-copy);
}

@include screen($bp-mobile) {
  .related-experience-intro {
    margin-bottom: px-to-em($section-spacing-mobile-px, $font-size-copy);
  }

  .related-experience {
    margin-bottom: $section-spacing-large-mobile;
  }
}
