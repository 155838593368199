.article-header-condensed {
  margin-bottom: $section-spacing;
  color: $color-brand-black;
}

.article-header-condensed-title {
  @extend .section-heading;

  margin-bottom: px-to-em($element-spacing-small-px, $section-heading-size);
}

.article-header-condensed-meta {
  @include font-univers-condensed-light($font-size-subheading);

  line-height: $profile-line-height;
  color: $color-gray-steel;

  @include design-mark(17.5px, $font-size-subheading, $color-gray-steel);

  .meta-item {
    & + .meta-item {
      &:before {
        content: "//";
        display: inline-block;
        margin: 0 0.25em;
      }
    }
  }
}

.article-header-condensed-slogan {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
}

//social media
.article-header-condensed {
  .social-links-item {
    margin-right: 1em;
    margin-bottom: 1em;

    &:last-child {
      margin-right: 0;
    }
  }

  .social-links-icon {
    color: $color-brand-black;

    &:hover {
      color: $color-gray-steel;
    }
  }

  //share options
  .share-tools {
    display: inline-block;

    &:after {
      background-color: $color-brand-white;
    }
  }

  .share-tools-trigger {
    padding-bottom: 0;
    color: $color-brand-black;
  }

  .share-tools-link-icon {
    color: $color-brand-black;
  }

  .ico-pdf {
    font-size: px-to-em(28px);
  }
}

@include screen($bp-mobile) {
  .article-header-condensed {
    margin-bottom: $section-spacing-mobile;
    text-align: center;
  }

  .article-header-condensed-meta {
    font-size: px-to-em($font-size-subheading-mobile);

    &:after {
      margin-left: auto;
      margin-right: auto;
      width: px-to-em($global-line-mark, $font-size-subheading-mobile);
    }
  }
}
