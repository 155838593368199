//if used as a standalone component
.personnel-banner-wrapper {
  @include content-wrapper;
}

.personnel-banner {
  @include clearfix;

  position: relative;
  display: block;
  padding-top: px-to-em($featured-banner-offset);
  padding-left: px-to-em($featured-banner-offset);
  color: $color-text-base;
  transition: $transition-default;

  &:nth-child(2n) {
    padding-right: px-to-em($featured-banner-offset);
    padding-left: 0;

    .personnel-banner-figure {
      float: right;
      margin-right: px-to-em($featured-banner-offset, 32px) * -1;
      margin-left: 0;
    }

    .personnel-banner-info {
      padding-left: $element-spacing;
      padding-right: px-to-em($featured-banner-tile-width) +
        $element-spacing-small;

      &.no-image {
        padding-right: $element-spacing;
      }
    }
  }

  &.no-image {
    padding-left: 0;

    &:nth-child(2n) {
      padding-right: 0;

      .personnel-banner-info {
        padding-right: $element-spacing;
      }
    }

    .personnel-banner-info {
      padding-left: $element-spacing;
    }
  }

  &:not(:first-child) {
    margin-top: $element-spacing;
  }
}

a.personnel-banner {
  &:hover {
    color: $color-brand-white;

    .personnel-banner-info {
      background-color: $color-brand-red;
    }

    .personnel-banner-details {
      &:after {
        border-color: $color-brand-black;
      }
    }
  }
}

.personnel-banner-name {
  @include font-size-banner();
}

.personnel-banner-subtitle {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
}

.personnel-banner-figure {
  float: left;
  width: px-to-em($featured-banner-tile-width);
  height: px-to-em($featured-banner-personnel-image-height);
  margin-top: px-to-em($featured-banner-offset) * -1;
  margin-left: px-to-em($featured-banner-offset) * -1;
  overflow: hidden;
}

.personnel-banner-info {
  @include transition(background-color);

  position: static;
  background-color: $color-featured-banner;
  min-height: px-to-em($featured-banner-personnel-min-height);
  padding: $element-spacing $element-spacing $section-spacing;
  padding-left: px-to-em($featured-banner-tile-width) + $element-spacing-small;
}

.personnel-banner-details {
  @include design-mark();

  &:after {
    @include transition(border-color);
  }
}

@include screen($bp-desktop) {
  //context
  .back-to-top {
    .personnel-banner {
      &:nth-child(2n) {
        .personnel-banner-info {
          padding-left: $element-spacing;
          padding-right: px-to-em($featured-banner-tile-width-medium) +
            $element-spacing-small;
        }
      }
    }

    .personnel-banner-figure {
      width: px-to-em($featured-banner-tile-width-medium);
    }

    .personnel-banner-img {
      max-width: 100%;
    }

    .personnel-banner-info {
      padding-left: px-to-em($featured-banner-tile-width-medium) +
        $element-spacing-small;
    }
  }
}

@include screen($bp-mobile) {
  .personnel-banner {
    &:nth-child(2n) {
      .personnel-banner-info {
        padding-left: $element-spacing;
        padding-right: px-to-em($featured-banner-tile-width-small) +
          $element-spacing-small;
      }
    }
  }

  a.personnel-banner {
    &:hover {
      .personnel-banner-figure {
        background-color: $color-brand-red;
      }
    }
  }

  .personnel-banner-figure {
    @include transition(background-color);

    width: px-to-em($featured-banner-tile-width-small);
  }

  .personnel-banner-img {
    max-width: 100%;
  }

  .personnel-banner-subtitle {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .personnel-banner-info {
    padding-left: px-to-em($featured-banner-tile-width-small) +
      $element-spacing-small;
    min-height: auto;
  }

  .back-to-top {
    .personnel-banner {
      &:nth-child(2n) {
        .personnel-banner-info {
          padding-left: $element-spacing;
          padding-right: px-to-em($featured-banner-tile-width-small) +
            $element-spacing-small;
        }
      }
    }

    .personnel-banner-figure {
      width: px-to-em($featured-banner-tile-width-small);
    }

    .personnel-banner-info {
      padding-left: px-to-em($featured-banner-tile-width-small) +
        $element-spacing-small;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .personnel-banner {
    padding-left: 0;

    &:nth-child(2n) {
      padding-right: 0;

      .personnel-banner-figure {
        margin-right: 0;
        float: none;
      }

      .personnel-banner-info {
        padding-right: $site-padding-mobile;
      }
    }
  }

  .personnel-banner-figure {
    background-color: $color-featured-banner;
    float: none;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    text-align: center;
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
    overflow: visible;
    height: auto;
  }

  .personnel-banner-info {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }

  .personnel-banner-img {
    display: inline-block;
    margin-top: px-to-em($featured-banner-offset) * -1;
  }

  .back-to-top {
    .personnel-banner {
      padding-right: 0;

      &:nth-child(2n) {
        .personnel-banner-info {
          padding-left: $site-padding-mobile;
          padding-right: $site-padding-mobile;
        }
      }
    }

    .personnel-banner-figure {
      width: 100%;
    }

    .personnel-banner-info {
      padding-left: $site-padding-mobile;
    }
  }
}
