@use "sass:math";

.recent-news {
  padding: $section-spacing-largest $site-padding-mobile;

  .recent-news-list-item {
    border-color: $color-white-50;
  }

  .recent-news-list-link {
    color: $color-brand-white;

    &:hover {
      color: $color-text-red-hover;
    }
  }
}

.recent-news-wrapper {
  background-color: $color-opaque-red;
  padding-top: $section-spacing;
  padding-bottom: math.div($section-spacing, 2);
}

.recent-news-header {
  $header-size: 47px;

  @include font-univers-condensed-light($header-size);
  line-height: 1.2;
  text-align: center;
  color: $color-brand-white;
  margin-bottom: px-to-em($section-spacing-px, $header-size);

  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: px-to-em(236px, $header-size);
    border-top: 2px solid $color-brand-white;
    margin-top: 0.2em;
  }
}

.recent-news-img {
  @include center(horizontal);

  background-position: center top;
  position: fixed;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  z-index: -1;
}

@include screen($bp-tablet) {
  .recent-news {
    padding-top: $section-spacing-large;
    padding-bottom: $section-spacing-large;
  }
  .recent-news-header {
    $header-size: 35px;

    font-size: px-to-em($header-size);
  }
}

@include screen($bp-mobile) {
  .recent-news {
    padding-top: $section-spacing-large-mobile;
    padding-bottom: $section-spacing-large-mobile;
  }
}
