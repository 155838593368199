.professional-results-listing {
  margin-bottom: $section-spacing-large;

  .professional-result-card {
    margin-bottom: $element-spacing;
  }
}

@include screen($bp-mobile) {
  .professional-results-listing {
    margin-bottom: $section-spacing-large-mobile;
  }
}
