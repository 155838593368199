.path-selection-grid {
  display: flex;
  flex-wrap: wrap;

  .promo-card-path {
    flex-shrink: 0;
    flex-grow: 1;

    width: 50%;

    &:nth-child(2n) {
      border-left: 2px solid $color-brand-white;
    }
  }
}

@include screen($bp-two-column) {
  .path-selection-grid {
    display: block;

    .promo-card-path {
      width: 100%;

      &:nth-child(2n) {
        border-top: 2px solid $color-brand-white;
        border-left: 0 none;
      }
    }
  }
}
