@use "sass:math";

$locations-img-ratio: math.div(1370, 597);

$locations-img-width: 1370px;
$locations-img-height: 519px;
$locations-visible-window: 509px;
$locations-line-height: 1.2;

.locations-header {
  position: relative;
  background-color: $color-black-background;

  display: flex;
  flex-direction: column;
}

.locations-header-wrapper {
  min-height: px-to-em($locations-img-height);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.locations-header-figure {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: px-to-em($locations-visible-window);
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-nero-90;
  }
}

.locations-header-landscape {
  @include center(horizontal, relative);

  min-height: 100%;
  min-width: 100%;
}

.locations-header-details {
  $spacing: 26.5px;

  z-index: 5;
  padding: px-to-em($spacing * 2.25) px-to-em($spacing);
  padding-right: px-to-em($spacing * 1.75);
  color: $color-brand-white;
  position: relative;
  margin-left: px-to-em($locations-visible-window);
  flex-grow: 1;
  max-width: px-to-em(925px);
}

.locations-header-title {
  @include font-size-heading();
}

.locations-header-condensed {
  @include design-mark(13px, $font-size-base, $color-brand-gray);
}

//social media
.social-links {
  .locations-header & {
    $spacing: 22px;

    position: absolute;
    top: px-to-em($spacing * 2.5);
    right: px-to-em($spacing);

    .social-links-item {
      display: block;
      line-height: 1;
      margin-bottom: 1em;
    }

    .social-links-icon {
      font-size: px-to-em(24px);
    }

    .ico-pdf {
      font-size: px-to-em(28px);
    }

    .ico-vcard {
      font-size: px-to-em(19px);
    }

    .ico-share {
      font-size: px-to-em(23px);
    }
  }
}

.languages {
  $spacing: 26px;

  @include transition(padding);

  .locations-header & {
    position: absolute;
    margin-left: px-to-em($locations-visible-window);
    top: px-to-em($spacing);
    padding-left: px-to-em($spacing);
    z-index: 10;
  }
}

.locations-header-offices {
  @include clearfix;

  margin-top: 0.5em;

  .office-card {
    float: left;
    width: 50%;
    min-width: px-to-em(245px);
    padding-right: 0.9em;
    margin-bottom: 1.3em;

    &:nth-child(2n + 1) {
      clear: left;
    }
  }
}

@include screen($bp-desktop-large) {
  $locations-visible-window: 35%;

  .locations-header-figure {
    &:after {
      left: $locations-visible-window;
    }
  }

  .locations-header-landscape {
    position: relative;
    height: 100%;
  }

  .locations-header-details {
    margin-left: $locations-visible-window;
  }

  .languages {
    .locations-header & {
      margin-left: $locations-visible-window;
    }
  }
}

@include screen($bp-scrollspy) {
  .locations-header {
    z-index: $zindex-scrollspy-nav + 5;
  }
}

@include screen($bp-tablet) {
  $locations-img-height: 300px;

  .locations-header-wrapper {
    min-height: inherit;
    display: block;
    text-align: center;
  }

  .locations-header-figure {
    height: px-to-em($locations-img-height);

    &:after {
      @include absolute-full;

      @include gradient($color-nero, $color-nero-transparent, 0deg, 100%);

      background-color: transparent;
      content: "";
      display: block;
      top: 50%;
    }
  }

  .locations-header-landscape {
    height: 120%;
  }

  .locations-header-details {
    $spacing: 30px;

    padding: px-to-em($locations-img-height) px-to-em($spacing)
      px-to-em($spacing * 2.25);
    margin-left: 0;
    flex-grow: auto;
  }

  .locations-header-condensed {
    //design mark
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .languages {
    .locations-header & {
      @include center(horizontal);

      margin-left: 0;
      padding-left: 0;
      top: px-to-em($locations-img-height - $section-spacing-px);
    }
  }

  .locations-header-offices {
    .office-card {
      min-width: px-to-em(100px);
      padding-right: 0.5em;
      text-align: left;

      &:first-child:last-child {
        width: 100%;
        padding-right: 0;
        text-align: center;
      }
    }
  }

  .locations-header-title {
    $header-size-mobile: 36px;
    font-size: px-to-em($header-size-mobile);
  }

  .social-links {
    .locations-header & {
      $spacing: 30px;

      position: relative;
      top: auto;
      right: auto;
      display: inline-block;
      margin-top: 0.5em;
      vertical-align: middle;

      .social-links-item {
        display: inline-block;
        margin-bottom: 0;
        width: px-to-em(45px);
      }

      .ico-pdf {
        font-size: px-to-em(20px);
      }
    }
  }
}

@include screen($bp-mobile) {
  $locations-img-height: 180px;
  $locations-office-font-size: 20px;

  .locations-header-figure {
    height: px-to-em($locations-img-height);
  }

  .locations-header-landscape {
    height: 170%;
  }

  .locations-header-details {
    $spacing: 30px;

    padding: px-to-em($locations-img-height) $site-padding-mobile
      px-to-em($spacing * 1.5);
  }

  .languages {
    .locations-header & {
      top: px-to-em($locations-img-height - $section-spacing-px);
    }
  }
}
