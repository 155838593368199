.governance-listing {
  margin-bottom: $section-spacing-large;
}

.governance-listing-cards {
  .professional-card {
    margin-bottom: px-to-em($listing-padding);
  }
}

@include screen($bp-mobile) {
  .governance-listing {
    margin-bottom: $section-spacing-large-mobile;
  }

  .professional-card {
    margin-bottom: px-to-em($listing-padding-mobile);
  }
}
