.page-alumni-cle-library {
  .impactful-lead {
    padding: $section-spacing-large 0 0;
    background-color: $color-search-filters;
  }

  .cle-library-opportunities-load-more {
    margin: $section-spacing-small auto 0;
    display: block;
  }

  .search-landing-results {
    &[aria-hidden="true"] {
      display: none;
    }
  }

  .highlight-banners,
  .highlight-card-wrapper {
    @include page-wrapper;
  }

  .highlight-card-wrapper {
    margin-bottom: $section-spacing-largest;

    .highlight-banners {
      margin-bottom: 1em;
    }

    .highlight-banner-footer {
      margin-bottom: $section-spacing-large;
    }
  }

  //indent listin items
  .listing-articles-item {
    padding-left: px-to-em($listing-articles-video-padding);
  }

  .search-criteria-wrapper {
    padding-left: 0;
    padding-right: 0;

    &:after {
      left: 0;
      right: 0;
    }
  }

  @include screen($bp-mobile) {
    .impactful-lead {
      padding: $section-spacing-large-mobile 0 0;
    }

    .listing-articles-item {
      padding-left: px-to-em($listing-articles-video-padding-mobile);
    }

    .search-criteria {
      margin-bottom: $section-spacing-mobile;
    }

    .highlight-card-wrapper {
      margin-bottom: $section-spacing-large-mobile;

      .highlight-banner-footer {
        margin-bottom: $section-spacing-large-mobile;
      }
    }
  }
}
