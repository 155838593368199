$capability-region-grid-spacing: 1px;
.region-grid-list {
  @include screen($bp-mobile-landscape, min) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.region-grid-list-item {
  @include screen($bp-mobile-landscape, min) {
    width: 50%;
    padding-right: $capability-region-grid-spacing;
  }
  margin-bottom: 0;
  padding-bottom: $capability-region-grid-spacing;
  break-inside: avoid;
  overflow: hidden;
  vertical-align: top;
  &:nth-child(3n + 1) {
    .region-tile {
      background-color: $color-nero-98;
      &:hover {
        background-color: $color-opaque-red;
      }
    }
  }
  .region-tile {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-opaque-red;
      opacity: 0;
      transition: opacity 0.3s;
    }
    .region-tile-title {
      position: relative;
      z-index: 2;
      display: block;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}
