@use "sass:math";

.search-landing-filters {
  @include transition(background-color);

  background-color: $color-search-filters;
  padding: $section-spacing-large 0;

  &__fieldset {
    margin: 0;
  }

  &--fullbar {
    background-color: transparent;
    padding: 0;
  }

  .alpha-search {
    padding-right: $keywork-search-button-offset;
    margin-top: $section-spacing-small;
  }
}

@include screen($bp-tablet) {
  .search-landing-filters {
    padding: $section-spacing-large-mobile 0;

    .alpha-search {
      padding-right: 0;
    }
  }
}

// New Insights Landing styles
.search-landing-filters {
  &--fullbar {
    padding-top: $section-spacing-mobile;
    padding-bottom: $section-spacing-large-mobile;
    position: relative;

    // .search-landing-filters__advanced-filters {
    // 	padding-bottom: $section-spacing-mobile;
    // }
    .advanced-search-toggle {
      @include center("horizontal");
      position: absolute;
      bottom: $section-spacing-large-mobile;
      display: block;
      z-index: 3;
    }

    .advanced-search {
      margin-top: 0;

      .form--layout-halfs {
        padding-top: $section-spacing-mobile;
      }
    }
  }

  &__offalign-wrapper {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;

    & + & {
      margin-top: $site-padding-mobile;
    }
  }

  &.is-expanded-andgray {
    .advanced-search-toggle {
      &:after {
        transform: rotateX(180deg);
      }
    }
  }

  @include screen($bp-mobile, min) {
    &--fullbar {
      padding-top: 0;

      .advanced-search-toggle {
        position: relative;
        bottom: auto;
        left: auto;
        transform: none;
        flex-shrink: 0;
        width: px-to-em(250px, $font-size-buttons);
      }
    }

    &.is-expanded-andgray {
      background-color: $color-search-filters;
    }
    &__advanced-filters {
      padding-top: $section-spacing;
    }
    &__offalign-wrapper {
      margin-right: auto;
      padding-right: 0;
      padding-left: 0;

      & + & {
        margin-top: 0;
      }

      &-context {
        display: flex;
        flex-wrap: nowrap;
        // align-items: center;

        .form-field {
          margin-bottom: 0;
        }
      }

      &--left-align {
        padding-left: $site-padding-mobile;
        flex-grow: 1;

        &.keyword-searchbar__input {
          padding-left: px-to-em(
            $site-padding-mobile-px,
            $font-size-entity-title
          );
        }
      }

      &--right-align {
        flex-shrink: 0;
      }
    }
  }

  @include screen($bp-tablet, min) {
    &--fullbar {
      padding-bottom: 0;
      margin-bottom: $section-spacing-large-mobile;
    }
    &__offalign-wrapper {
      &--left-align {
        padding-left: $site-padding;

        &.keyword-searchbar__input {
          padding-left: px-to-em($site-padding-px, $font-size-entity-title);
        }
      }
    }
  }

  $site-width: $site-max-width-wide + ($site-padding-px * 2);
  @include screen($site-width, min) {
    &__offalign-wrapper {
      &--left-align {
        padding-left: calc(
          50% - #{math.div(px-to-em($site-width), 2) - $site-padding}
        );

        &.keyword-searchbar__input {
          padding-left: calc(
            50% - #{math.div(px-to-em($site-width, $font-size-entity-title), 2) -
              px-to-em($site-padding-px, $font-size-entity-title)}
          );
        }
      }
    }
  }
}
