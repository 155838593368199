.forum-listing {
  margin-bottom: $section-spacing-large;
}

.forum-listing-view-more {
  display: block;
  margin: $section-spacing auto 0;
  display: block;
}

@include screen($bp-mobile) {
  .forum-listing {
    margin-bottom: $section-spacing-large-mobile;
  }

  .forum-listing-view-more {
    margin-top: $section-spacing-mobile;
  }
}
