.form--application {
  margin-bottom: $section-spacing-large;

  .form-fields-header {
    font-size: px-to-em(28px);
  }

  .form-field-header {
    @include font-univers-condensed-bold(16px);
    line-height: 1.2;
    margin-bottom: $element-spacing-small;
    text-transform: uppercase;

    &:after {
      content: "*";
    }

    &.not-required {
      &:after {
        content: "";
      }
    }
  }

  .file-input-container {
    position: relative;

    .form-label {
      cursor: pointer;
      display: block;
      font-size: px-to-em(21px);
      padding: px-to-em($form-input-padding, 21px);
      padding-right: 1.5em;
      background-color: $color-brand-white;
      color: $color-gray-steel;
      border: 1px solid $color-border;

      @include font-univers-condensed-bold;

      @include ico-icon-after($icon-link) {
        float: right;
        color: $color-brand-red;
      }
    }

    .input-upload {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  @include screen($bp-mobile) {
    .form-fields-header {
      font-size: px-to-em(28px);
    }

    .file-input-container {
      .form-label {
        font-size: px-to-em(20px);
      }
    }
  }
}

.recipient-info {
  margin-bottom: $section-spacing-small;

  .field-label {
    margin-right: 4px;
    text-transform: uppercase;
  }

  .email-label {
    color: $color-gray-steel;
    @include font-univers-condensed-bold(21px);
  }
}
