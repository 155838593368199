.page-fellow-profile-edit {
  background-color: $color-gray-med;
  padding-top: $section-spacing;
  padding-bottom: $section-spacing;

  &.standard-form {
    margin-top: 0;
  }

  .fellow-page-content-tabs {
    padding-bottom: $section-spacing-large;
  }

  .form-response {
    margin-bottom: 0 !important;
    padding-bottom: 4rem !important;
  }
}

.fellow-page-content-tabs {
  padding-top: $section-spacing-large;

  .page-header-user-actions {
    margin-top: 0;
  }
}

.fellow-profile-edit-body {
  background-color: $color-gray-med;
}

.fellow-profile-edit-form {
  margin-top: $section-spacing;
}

@include screen($bp-mobile) {
  .page-fellow-profile-edit {
    background-color: $color-gray-med;

    .fellow-page-content-tabs {
      padding-bottom: $section-spacing-large;
    }
  }
  .fellow-page-content-tabs {
    padding-top: $section-spacing-large-mobile;

    .page-header-user-actions {
      margin-top: 0;
    }
  }
}
