.tile-insight {
  background-color: $color-grey-f6;
  padding: px-to-em(35px) px-to-em(20px);
  display: block;
  width: 100%;
  min-height: px-to-em(240px);

  &__title {
    @include font-size-entity-title;
    @include transition(color);
  }

  &--minimal {
    background-color: $color-brand-white;
    min-height: px-to-em(180px);
    padding-left: 0;
    padding-right: 0;
  }

  @include screen($bp-mobile, min) {
    &:hover & {
      &__title {
        color: $color-gray-on-gray-foreground;
      }
    }

    &--on-dark:hover & {
      &__title {
        color: $color-brand-white;
      }
    }
  }

  @include screen($bp-tablet, min) {
    padding: px-to-em(35px) px-to-em(30px);

    &--minimal {
      background-color: $color-brand-white;
      min-height: px-to-em(210px);
      padding-left: 0;
      padding-right: 0;
    }
  }
}
