.featured-carousel {
  background-color: $color-black-background;
  border-top: $section-spacing-large solid $color-brand-white;
  padding-bottom: $section-spacing-small;
  position: relative;
  z-index: $zindex-featured-carousel;
}

.featured-carousel-nav {
  @include page-wrapper;
}

.featured-carousel-nav-title {
  $spacing: 22px;
  @include font-univers-condensed-light($font-size-entity-title);
  line-height: 1.2;
  color: $color-gray-alt;
  margin: px-to-em($spacing, $font-size-entity-title) 0;
  padding-top: px-to-em($spacing, $font-size-entity-title);
  border-top: 1px solid $color-site-header-border;

  &:after {
    content: ":";
  }
}

.featured-carousel-nav-option {
  display: inline;
  cursor: pointer;

  &:last-child {
    .featured-carousel-nav-topic {
      border-right: 0 none;
      margin-right: 0;
    }
  }

  &.is-selected {
    .featured-carousel-nav-topic {
      color: $color-brand-white;
    }
  }
}

.featured-carousel-nav-topic {
  $spacing: 0.65em;
  @include font-univers-condensed-bold($font-size-copy);

  display: inline-block;
  color: $color-red-on-black-foreground;
  text-transform: uppercase;
  border-right: 1px solid $color-site-header-border;
  margin-bottom: 0.45em;
  margin-right: $spacing * 0.65;
  padding-right: $spacing;

  &:hover {
    color: $color-text-gray-soft;
  }
}

.featured-carousel-topics {
  overflow: hidden;

  margin-top: $featured-topic-img-offset * -1;
  position: relative;
  z-index: 5;

  .featured-topic-slide {
    float: left;
  }
}

.featured-carousel-nav {
  margin-top: $featured-topic-img-offset;
  position: relative;

  .featured-topic-slide {
    float: left;
  }
}

//arrows
.featured-carousel-arrows {
  @include center(horizontal);
  @include page-wrapper;

  width: 100%;
  top: 2.15em;
  z-index: 10;
}

.featured-carousel-arrow {
  color: $color-red-on-black-foreground;
  right: $site-padding * 2.5;
  position: absolute;

  @include ico-icon-after($icon-chevron-right) {
    @include transition(transform);

    font-size: px-to-em($arrow-icon-size);
    transform: rotateY(-180deg);
  }

  & + & {
    right: $site-padding;

    &:after {
      content: $icon-chevron-right;
      transform: translateX(0);
    }
  }
}

@include screen($bp-featured-carousel-tablet) {
  .featured-carousel {
    padding-bottom: 0;
  }
  .featured-carousel-topics {
    background-color: $color-black-background;
    margin-top: 0;
    padding-bottom: $element-spacing;
  }

  .featured-carousel-arrows {
    top: px-to-em(26px);
  }

  .featured-carousel-nav {
    padding: 0;
    margin-top: 0;
    position: relative;
    //overflow: hidden;

    &.show-topics {
      //overflow: visible;
    }
  }

  .featured-carousel-nav-title {
    $font-size-mobile: 17.5px;

    @include font-univers-condensed-bold($font-size-mobile);

    background-color: $color-black-background;
    color: $color-brand-white;
    text-transform: uppercase;
    border-top-color: $color-brand-white;
    padding: $section-spacing-small
      px-to-em($site-padding-mobile-px, $font-size-mobile);
    margin: 0;
    cursor: pointer;
    text-align: center;
    z-index: 10;
    position: relative;

    @include ico-icon-after($icon-chevron-down-alt) {
      @include transition(transform);

      font-size: px-to-em($arrow-down-icon-size, $font-size-mobile);
      margin-left: 1em;
    }

    .show-topics & {
      &:after {
        transform: rotateX(-180deg);
      }
    }
  }

  .featured-carousel-nav-list {
    //@include transition(transform);
    @include multi-transition(
      transform $transition-timing $transition-ease,
      max-height $transition-timing $transition-ease
    );

    position: absolute;
    width: 100%;
    background-color: $color-black-background;
    max-height: 0;
    transform: translateY(-100%);
    overflow: hidden;

    .show-topics & {
      max-height: none;
      transform: translateY(0);
    }
  }
  //convert to vertical list
  .featured-carousel-nav-option {
    display: block;
    padding: px-to-em(22px) $site-padding-mobile;
    border-top: 1px solid $color-gray-steel;
  }

  .featured-carousel-nav-topic {
    border-right: 0;
    width: 100%;
    margin: 0;
  }
}

@include screen($bp-mobile) {
  .featured-carousel {
    border-top: 0;
  }

  .featured-carousel-nav-topic {
    $font-size-mobile: 20px;
    @include font-univers-condensed-bold($font-size-mobile);
  }
}
