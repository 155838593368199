@use "sass:math";

$number-box-dimen: 105px;
$number-font-size: 63px;
$number-count: 50;

.listing-articles-numbered {
}

.listing-articles-numbered-item {
  padding-bottom: px-to-em($listing-padding);
  padding-right: $arrow-padding * 2;
  padding-left: px-to-em($number-box-dimen) + $element-spacing;
  position: relative;
  min-height: px-to-em(175px);

  & + & {
    border-top: $border-dark;
    padding-top: px-to-em($listing-padding);
  }

  &:last-child {
    padding-bottom: 0;
  }

  @while $number-count > 1 {
    &:nth-child(#{$number-count}) {
      .listing-articles-numbered-title {
        &:before {
          content: "#{$number-count}";
        }
      }
    }

    $number-count: $number-count - 1;
  }
}

.listing-articles-numbered-title {
  @include font-size-banner();

  display: block;

  &:hover {
    color: $color-link-hover;
    //arrow
    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
    //number
    &:before {
      background-color: $color-black-background;
    }
  }
  //arrow
  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    font-size: px-to-em($arrow-icon-size, $font-size-banner);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }

  //numbers
  &:before {
    @include transition(background-color);

    content: "1";
    font-size: px-to-em($number-font-size, $font-size-banner);
    line-height: 1;
    padding-top: px-to-em(
      23px,
      $number-font-size
    ); //need this styles because of inconsistent line-height of native font between IOS and Windows machines
    width: px-to-em($number-box-dimen, $number-font-size);
    height: px-to-em($number-box-dimen, $number-font-size);
    position: absolute;
    left: 0;
    text-align: center;
    background-color: $color-brand-red;
    color: $color-brand-white;
    margin-top: 0.05em;
  }
}

div.listing-articles-numbered-title {
  //arrow
  &:after {
    content: "";
  }

  &:hover {
    color: $color-brand-black;

    //number
    &:before {
      background-color: $color-brand-red;
    }
  }
}

.listing-articles-numbered-abstract {
  margin-top: $section-spacing-small;
}

.listing-articles-numbered-meta {
  font-size: px-to-em($font-size-entity-title);
  margin-top: px-to-em($section-spacing-small-px, $font-size-entity-title);

  color: $color-gray-steel;

  .meta-category + .meta-date {
    &:before {
      content: "//";
      display: inline-block;
      margin: 0 0.5em;
    }
  }
}

@include screen($bp-tablet) {
  $number-box-dimen: 70px;
  $number-font-size: 48px;

  .listing-articles-numbered-item {
    padding-left: px-to-em($number-box-dimen) + $element-spacing;
  }

  .listing-articles-numbered-title {
    //numbers
    &:before {
      font-size: px-to-em($number-font-size, $font-size-banner);
      line-height: 1;
      width: px-to-em($number-box-dimen, $number-font-size);
      height: px-to-em($number-box-dimen, $number-font-size);
    }
  }
}

@include screen($bp-mobile) {
  $number-box-dimen: 60px;
  $number-font-size: 40px;

  .listing-articles-numbered-item {
    padding: px-to-em($listing-padding-mobile) $arrow-padding
      px-to-em($listing-padding-mobile) 0;
    min-height: inherit;
  }

  .listing-articles-numbered-meta {
    padding-right: $arrow-padding;
  }

  .listing-articles-numbered-title {
    //chevron
    &:after {
      bottom: px-to-em($listing-padding-mobile * 1.35);
      top: auto;
      right: 0;
      transform: translateY(0);
    }

    //numbers
    &:before {
      display: block;
      position: relative;
      font-size: px-to-em($number-font-size, $font-size-banner-mobile);
      padding-top: px-to-em(10px, $number-font-size);
      width: px-to-em($number-box-dimen, $number-font-size);
      height: px-to-em($number-box-dimen, $number-font-size);
      margin-bottom: px-to-em(
        math.div($section-spacing-small-px, 2),
        $font-size-banner-mobile
      );
    }

    &:hover {
      &:after {
        transform: translateX(100%);
      }
    }
  }

  .listing-articles-numbered-abstract {
    margin-top: math.div($element-spacing-small-px, 2);
  }

  .listing-articles-numbered-meta {
    font-size: px-to-em($font-size-entity-title-mobile);
    margin-top: px-to-em(
      math.div($section-spacing-small-px, 2),
      $font-size-entity-title-mobile
    );
  }
}
