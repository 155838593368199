$office-contacts-grid-spacing: px-to-em(19px);

.office-contacts {
  margin-bottom: $section-spacing-large;
}

.office-contacts-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-right: $office-contacts-grid-spacing * -1;

  .professional-card {
    width: 50%;
    padding-right: $office-contacts-grid-spacing;
    margin-bottom: $office-contacts-grid-spacing;

    &:first-child {
      &:last-child {
        width: 100%;
      }
    }
  }
}

.office-contacts-see-all {
  text-align: center;
  margin-top: $section-spacing * 0.9;
}

@include screen($bp-desktop) {
  .scrollspy,
  .back-to-top {
    .professional-card {
      width: 100%;
    }
  }
}

@include screen($bp-mobile) {
  .office-contacts {
    margin-bottom: $section-spacing-large-mobile;
  }
  .office-contacts-grid {
    margin-right: 0;

    .professional-card {
      width: 100%;
      padding-right: 0;
    }
  }

  .office-contacts-see-all {
    margin-top: $section-spacing-small;
  }
}
