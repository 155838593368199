.site-search-body {
  @include clearfix;
  position: relative;
}

.site-search-results-item {
  position: relative;
  padding-bottom: 2em;
  padding-right: 2em;
  margin-bottom: 2em;
  border-bottom: 1px solid $color-black;

  .site-search-results-item-title-link {
    @include font-size-banner();
    font-size: 1em;

    @include ico-icon-after($icon-chevron-right) {
      @include center(vertical);

      font-size: px-to-em($arrow-icon-size, $font-size-banner);
      right: px-to-em($arrow-listing-offset, $arrow-icon-size);
      color: $color-link;

      transition: color $transition-timing $transition-ease,
        transform $transition-timing $transition-ease;
    }

    &:hover {
      &:after {
        transform: translateX(100%) translateY(-50%);
      }
    }
  }

  div.site-search-results-item-title-link {
    &:after {
      content: "";
    }
  }

  .site-search-results-item-excerpt {
    margin-top: px-to-em(10px);
  }

  .site-search-results-item-title-section {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }

  @include screen($bp-mobile) {
    .site-search-results-item-title-link {
      font-size: 0.75em;
    }
  }
}

.site-search-results-load-more-container {
  text-align: center;
}

.site-search-results-icon {
  &--headphones {
    @include ico-icon-before($icon-headphones);

    &:before {
      margin-right: 0.5em;
    }
  }
}
