// New Insights Landing styles
.supplemental-search {
  $padding-offset: 35px;
  padding: $section-spacing-large-mobile 0;
  background-color: $color-brand-black;
  color: $color-brand-white;

  &__heading {
    @extend .section-heading;

    padding-left: px-to-em($padding-offset, $section-heading-mobile-size);
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      width: px-to-em(3px, $section-heading-mobile-size);
      height: px-to-em(60px, $section-heading-mobile-size);
      position: absolute;
      left: 0;
      background-color: $color-brand-red;
    }
  }

  .form-field {
    padding-left: px-to-em($padding-offset);
  }

  & &__textbox {
    @include font-univers-condensed-bold();

    line-height: 1.1;
    color: $color-brand-white;
    background-color: $color-brand-black;
    border-color: transparent;
    text-transform: uppercase;
    width: auto;
    flex-grow: 1;
    padding: 0 0 0.5em 0;
    margin-bottom: 1em;
    border-bottom: thin solid $color-brand-white;
    text-align: center;

    @include placeholder() {
      color: $color-brand-white;
    }
  }

  &__textbox-group {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
  }
  &__submit {
    @include font-size-buttons;

    line-height: 1.1;
    color: $color-brand-white;
    text-transform: uppercase;
    width: 100%;

    @include ico-icon-before($icon-search) {
      font-size: px-to-em(17px);
      margin-right: 0.5em;
      flex-shrink: 0;
    }
  }
  &__textbox-container {
    display: none;
    margin-bottom: 0;
  }

  @include screen($bp-mobile, min) {
    text-align: center;

    &__heading {
      &:before {
        content: "";
        width: px-to-em(3px, $section-heading-size);
        height: px-to-em(60px, $section-heading-size);
        position: absolute;
        left: auto;
        margin-left: -1em;
        background-color: $color-brand-red;
      }
    }

    &__textbox-group {
      max-width: px-to-em(360px);
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
    @include screen($bp-tablet, min) {
      &__submit {
        width: inherit;
      }
      &__textbox-group {
        width: inherit;
      }
    }

    [class*="form-field--"] {
      float: none;
    }

    .form-field {
      justify-content: center;
    }

    .form-label--radio + .form-label--radio {
      margin-left: 30px;
    }

    .form-label {
      @include font-univers-condensed-bold;
      @include font-size-entity-title;
      color: $color-red-on-black-foreground;
    }
  }

  @include screen($bp-tablet, min) {
    padding: $section-spacing-large 0;
  }
}

.questionnaire-step {
  opacity: 0;

  &.active {
    opacity: 1;
  }
}

.cursor-type-fx {
  position: relative;

  .cursor-fx-item {
    position: relative;
    font-style: inherit;
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.07s linear);
  }

  .questionnaire-step.active &,
  &.active {
    // &:before {
    //   animation: blinker 1s linear infinite;

    //   @keyframes blinker {
    //     50% { opacity: 0; }
    //   }
    // }

    .cursor-fx-item {
      opacity: 1;
      visibility: visible;
    }
  }

  .questionnaire-step.completed &,
  &.complete,
  &.fx-completed.active,
  &.fx-completed {
    // &:before {
    //   animation-name: none; //stop blink
    // }

    .cursor-fx-item {
      @include transition(none);
    }
  }
}

//stop the transition from messing up 'start over'
.question-unstarted .cursor-fx-item {
  transition-delay: 0s !important;
}

.cursor-style {
  position: relative;
  // padding-left: .5em;

  // &:before {
  //   position: absolute;
  //   top: .2em;
  //   left: 0;
  //   z-index: 1;
  //   content: " ";
  //   width: .15em;
  //   height: .8em;
  //   background: $color-brand-red;
  // }
}
