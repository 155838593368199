.line-hover-arrow {
  $spacing: 18px;
  $wing-length: 10px;
  $line-color: $color-brand-red;
  $line-hover-width: px-to-em($global-line-mark + $wing-length);
  $transition-timing: 0.175s;

  display: block;
  width: px-to-em($global-line-mark);
  height: $global-line-thickness;
  background-color: $line-color;
  margin: px-to-em($spacing) 0;
  position: relative;
  transition: width $transition-timing linear;
  transition-delay: $transition-timing;
  transform: translateZ(0);

  @include screen($bp-mobile, min) {
    $spacing: 22px;

    margin: px-to-em($spacing) 0;

    &:after,
    &:before {
      content: "";
      width: px-to-em($wing-length);
      height: $global-line-thickness;
      position: absolute;
      right: -3px;
      top: 1px;
      background-color: inherit;
      transform-origin: 100% 0;
      transition: width $transition-timing linear;
      width: 0em;
      backface-visibility: hidden;
      transform: rotate(45deg);
    }

    &:after {
      transform-origin: 100% 50%;
      transform: rotate(-45deg);
      top: -1px;
      right: -2px;
    }

    &__context:hover & {
      width: px-to-em($global-line-mark + $wing-length);
      transition-delay: 0s;

      &:after,
      &:before {
        width: px-to-em($wing-length);
        transition-delay: $transition-timing;
      }

      &:after {
        // width: px-to-em($wing-length + 1px);
      }
    }
  }
}
