@use "sass:math";

.key-takeaways {
  @include bg-cover;

  background-position: center;
  padding: $element-spacing 0;
  margin-bottom: $section-spacing-large-mobile;

  &__wrapper {
    background-color: $color-nero-90;
    color: $color-brand-white;
    padding: $site-padding-mobile math.div($site-padding-mobile, 2);
  }

  header {
    padding: 0 px-to-em(20px);
  }

  .section-heading {
    margin-bottom: 0;
  }

  .tile-insight {
    background-color: transparent;
    min-height: inherit;
  }

  @include screen($bp-mobile, min) {
    padding: $section-spacing 0;
    margin-bottom: $section-spacing-large;

    &__wrapper {
      padding-top: $section-spacing;
      padding-bottom: $section-spacing;
    }

    .tile-insight {
      min-height: px-to-em(240);

      &:hover {
        .tile-insight__title {
          color: $color-brand-white;
        }
      }
    }
  }

  @include screen($bp-tablet, min) {
    padding: $section-spacing-large 0;

    header {
      padding: 0 px-to-em(30px);
    }

    .section-heading {
      margin-bottom: px-to-em(20px, $section-heading-size);
    }
  }
}
