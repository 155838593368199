.related-insights {
  margin-bottom: $section-spacing-large;
}

.related-insights-intro {
  margin-bottom: px-to-em($section-spacing-px, $font-size-copy);
}

.related-insights-listing {
  margin-top: $section-spacing;
}

.related-insights-articles {
  display: none;
}

.related-insights-view-more {
  margin: $section-spacing auto 0;
  display: block;
}

@include screen($bp-mobile) {
  .related-insights {
    margin-bottom: $section-spacing-large-mobile;
  }

  .related-insights-view-more,
  .related-insights-listing {
    margin-top: $section-spacing-mobile;
  }
}
