$fe-logo-width: 239px;
$fe-logo-height: 104px;

$fe-logo-viewbox: 160px;

.foundation-explore {
  $ui-control-font-size: 17px;
  @include bg-cover;

  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  min-height: px-to-em(475px);

  .site-search-toggle {
    display: block;
  }

  //faded out gradiant
  &:before {
    @include absolute-full;
    @include gradient($color-nero 0%, $color-nero-transparent, 0deg, 99%);

    content: "";
    display: block;
    top: 40%;
  }

  //mobile toggle specific styles

  .mobile-toggle {
    float: left;
    height: 100%;
    padding-top: 0.5em;
    width: px-to-em($fe-logo-height);
  }

  .mobile-toggle-bars {
    $bars-height: px-to-em(3px);
    $bars-width: px-to-em(45px);
    $bars-spacing: px-to-em(6px);

    width: $bars-width;
    height: $bars-height;
    margin-right: 0.2em;

    &:before {
      margin-bottom: $bars-spacing;
    }

    &:after {
      margin-bottom: $bars-spacing * 2 + $bars-height;
    }
  }

  .mobile-toggle-label {
    display: block;
    margin-left: 0;
    margin-top: 0.25em;
  }

  .mobile-toggle-label,
  .site-user-login-label {
    font-size: px-to-em($ui-control-font-size);
  }

  .site-user-login {
    width: px-to-em($fe-logo-height);
  }
}

.foundation-explore-tools {
  @include center(vertical, relative);

  top: 45%;
  margin: 0 auto;
  background-color: $color-brand-red;
  z-index: 5;
  height: px-to-em($fe-logo-height);
  display: inline-block;

  form {
    display: inline;
  }
}

.foundation-explore-logo-wrapper {
  float: left;
  display: block;
  width: px-to-em($fe-logo-width);
  text-align: center;
  height: 100%;
}

.foundation-explore-logo-viewbox {
  width: px-to-em($fe-logo-viewbox);
  display: inline-block;

  @include center(vertical, relative);
}

.foundation-explore-logo {
  width: 100%;
}

.foundation-explore-cta {
  @include center(horizontal);

  bottom: 10%;
  color: $color-brand-white;
  text-align: center;
  text-transform: uppercase;
  z-index: 5;
  white-space: nowrap;

  @include ico-icon-after($icon-chevron-bottom) {
    display: block;
    margin: 0 auto;
    font-size: 1.8em;
    margin-top: 2vh;
  }
}

@include screen($bp-scrollspy) {
  .foundation-explore {
    z-index: $zindex-scrollspy-nav + 5;
  }
}

@include screen($bp-mobile-landscape) {
  .foundation-explore {
    $toggle-label-size: 18px;

    .site-user-login,
    .mobile-toggle {
      display: block;
      float: left;
      width: 50%;
      height: px-to-em($site-header-height);
    }

    .mobile-toggle {
      padding-top: 0;
    }

    .mobile-toggle-bars {
      $bars-height: px-to-em(2px);
      $bars-width: px-to-em(28px);
      $bars-spacing: px-to-em(6px);

      width: $bars-width;
      height: $bars-height;
      margin-top: 1.25em;

      &:before,
      &:after {
        height: $bars-height;
      }

      &:before {
        margin-bottom: $bars-spacing;
      }

      &:after {
        margin-bottom: $bars-spacing * 2 + $bars-height;
      }
    }

    .mobile-toggle-label {
      font-size: px-to-em($toggle-label-size);
      margin-left: 0.25em;
      display: inline-block;
    }

    .site-user-login-label {
      font-size: $toggle-label-size;

      &:before {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 0.25em;
        font-size: px-to-em(20px);
      }
    }
  }

  .foundation-explore-tools {
    width: px-to-em(300px);
    top: 40%;
  }

  .foundation-explore-logo-wrapper {
    width: 100%;
  }
}

@include screen-landscape($bp-mobile-small) {
  .foundation-explore-cta {
    bottom: 15%;
  }
}
