$insight-grid-spacing: 1px;
.insights-grid-headlines {
  background-color: $color-brand-white;
  padding: $section-spacing 0 $section-spacing-largest;
}

.insights-grid-headlines-list {
  columns: 2 auto;
  column-gap: $insight-grid-spacing;
}

.insights-grid-headlines-list-item {
  padding-bottom: $insight-grid-spacing;
  break-inside: avoid;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  z-index: 2;

  &:nth-child(3n + 1) {
    .insight-tile {
      background-color: $color-nero-98;

      &:hover {
        background-color: $color-opaque-red;
      }
    }

    div.insight-tile {
      &:hover {
        background-color: $color-nero-98;
      }
    }
  }
}

.insights-grid-headlines-list-item {
  div.insight-tile {
    &:hover {
      background-color: $color-nero-98;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .insights-grid-headlines {
    padding: 0 0 $section-spacing;
  }
  .insights-grid-headlines-list {
    columns: auto;
  }
}
