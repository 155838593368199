.tile-listing {
  $grid-spacing: px-to-em(12px);

  &__cell {
    max-width: px-to-em(340px);
    margin-left: auto;
    margin-right: auto;

    &:not(:first-child) {
      margin-top: $grid-spacing;
    }
  }

  @include screen($bp-mobile-landscape, min) {
    display: flex;
    flex-wrap: wrap;
    margin-left: $grid-spacing * -0.5;
    margin-right: $grid-spacing * -0.5;

    &__cell {
      width: calc(50% - #{$grid-spacing});
      display: flex;
      max-width: none;
      margin-left: $grid-spacing * 0.5;
      margin-right: $grid-spacing * 0.5;

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }

  @include screen($bp-tablet, min) {
    &__cell {
      width: calc(33.3333% - #{$grid-spacing});

      &:nth-child(3) {
        margin-top: 0;
      }
    }
    // padding: px-to-em(35px) px-to-em(30px);
  }
}
