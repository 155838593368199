//will resort to flex box instead of using display table  in the situation where an image is not present
.alumni-card {
  display: table;
  width: 100%;
  background-color: $color-gray-light;
  position: relative;

  .deceased-label {
    @include screen($bp-mobile) {
      position: relative;
      right: 0;
      bottom: 5px;
      padding-left: 1em;
    }
  }
}

.alumni-card-details,
.alumni-card-employment {
  display: table-cell;
  vertical-align: top;
}

/*----------  name and partner related info  ----------*/

.alumni-card-details {
  padding: $element-spacing;

  @include design-mark(14px);

  width: 40%;
  max-width: px-to-em(375px);

  &:after {
    padding-top: 0;
  }
}

.alumni-card-name {
  @include font-size-banner();

  &:hover {
    color: $color-gray-steel;
  }
}

.alumni-card-skadden-info {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
}

/*---------- Contact Related information, office, phone, email  ----------*/

.alumni-card-employment {
  padding: $element-spacing;
  width: 60%;
}

.alumni-card-employment-item {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;

  & + & {
    margin-top: 0.5em;
  }
}

@include screen($bp-mobile) {
  .alumni-card-name {
    margin-bottom: 0.25em;
  }

  .alumni-card-employment-item,
  .alumni-card-skadden-info {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .alumni-card-details,
  .alumni-card-employment {
    width: 50%;
    padding: $site-padding-mobile;
  }
}

@include screen($bp-mobile-landscape) {
  $alumni-card-spacing: 1.25em;

  .alumni-card {
    display: block;
  }

  .alumni-card-details,
  .alumni-card-employment {
    display: block;
    width: auto;
  }

  .alumni-card-details {
    padding-bottom: 0;
  }
}
