@use "sass:math";

.lawyer-careers-navigation {
  &.navigation-primary {
    .toggle {
      display: block;
      position: absolute;
      right: 0;
      z-index: $z-index-header-content;
      height: ($header-height - ($spacing * 2));
      line-height: 1em;
      padding: 0;
      box-sizing: border-box;
    }

    .nav-items-wrapper {
      position: relative;
      text-align: center;
      width: auto;
      top: 50%;

      @include translate(0, -50%);
    }

    .nav-items {
      position: relative;
      display: block;
      float: none;
      padding: 0;
      text-transform: uppercase;
      z-index: $z-index-header-content;
    }

    .mainitems {
      margin-bottom: 0.65rem;
      @include font-size($font-size-xxxl);
      font-weight: bold;
    }
    .subitems {
      @include font-size($font-size-xl);
      margin-bottom: 0.65rem;

      &:before {
        content: " ";
        background-color: $color-grey-six;
        display: inline-block;
        width: 3.125em;
        height: 0.125em;
        margin-bottom: 0.65rem;
      }
    }

    .socialitems {
      text-align: center;
      margin-top: 0.65rem;

      .icon {
        margin: 0;
      }

      @include font-size($font-size-xl);

      li {
        display: inline-block;
        padding: 0 math.div($spacing, 2);
      }
    }

    li {
      display: block;
      margin: 0;
      padding: 0;
    }

    a {
      display: block;
      padding: 0.65rem 0;
      font-size: inherit;
      color: $color-brand-light;

      &:hover,
      &:focus,
      &:active {
        color: darken($color-brand-light, 20%);
      }
    }
  }
}
