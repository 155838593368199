$capabilities-column-spacing: 2px;

.related-listing-capabilities {
  .btn-text-link-arrow {
    cursor: pointer;
  }

  .search-criteria {
    padding-top: $section-spacing;
  }

  .search-criteria-wrapper {
    padding: 0;

    //extending borders
    &:after {
      left: 0;
      right: 0;
    }
  }

  .section-intro {
    padding-bottom: 0;
  }
}

.related-listing-capabilities-options {
  columns: 2 auto;
  column-gap: $capabilities-column-spacing;
}

.related-listing-capabilities-capability {
  padding-bottom: $capabilities-column-spacing;
  break-inside: avoid;
  overflow: hidden;
}

.related-listing-capabilities-link {
  @include font-size-entity-title();

  $padding: px-to-em(19px, 22px) px-to-em(16px, 22px);

  font-size: px-to-em(
    22px
  ); //increasing font size to comply with aria requirements
  line-height: 1.2;
  background-color: $color-lightgray-background;
  padding: $padding;
  color: $color-red-on-lightgray-foreground;
  display: block;
  transition: $transition-default;

  &:hover {
    background-color: $color-gray-steel;
    color: $color-brand-white;
  }
}

@include screen($bp-mobile) {
  .related-listing-capabilities-options {
    columns: auto;
  }
}
