@use "sass:math";

$accordion-spacing: 35px;

.accordions {
  margin-top: $accordion-spacing;
}

.accordion-node {
  padding: px-to-em($accordion-spacing) 0;
  border-bottom: $border-dark;

  &:first-child {
    padding-top: 0;
  }
}

.accordion-node-title {
  font-size: 1em;
}

.accordion-node-trigger {
  @include font-size-banner();

  line-height: 1.1;
  color: $color-brand-red;
  position: relative;
  width: 100%;
  padding-right: $arrow-padding;
  text-align: left;

  &:hover {
    color: $color-gray-steel;
  }

  @include ico-icon-before($icon-plus) {
    @include transition(transform);
    @include center(vertical);

    position: absolute;
    font-size: px-to-em($arrow-icon-size, $font-size-banner);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    line-height: 0;
  }

  .is-expanded & {
    color: $color-text-body;

    &:before {
      transform: rotate(45deg);
    }
  }
}

//content has rte class applied
.accordion-node-content {
  display: none;
  padding-top: px-to-em(math.div($accordion-spacing, 2), $font-size-copy);
  scroll-margin-top: 110px;
}

.accordion-content {
  &[aria-hidden="true"] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
}

@include screen($bp-mobile) {
  $accordion-spacing: 20px;

  .accordion-node {
    padding: px-to-em($accordion-spacing) 0;
  }

  .accordion-node-trigger {
    font-size: px-to-em($font-size-copy);

    &:after {
      font-size: 0.7em;
    }
  }

  .accordion-node-content {
    scroll-margin-top: 160px;
  }
}

.capability-accordion-node-title {
  color: $color-black;

  .accordion-node-trigger {
    width: auto;
  }
}
