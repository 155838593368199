.professional-testimonials {
  @include page-wrapper;

  margin-bottom: $section-spacing-large;

  header {
    margin-bottom: $section-spacing;
  }

  .testimonial-banner {
    & + .testimonial-banner {
      margin-top: $section-spacing;
    }
  }
}

.professional-testimonials-view-more {
  margin: $section-spacing auto 0;
  display: block;
}

@include screen($bp-mobile) {
  .professional-testimonials {
    margin-bottom: $section-spacing-large-mobile;

    header {
      margin-bottom: $section-spacing-mobile;
    }

    .testimonial-banner {
      & + .testimonial-banner {
        margin-top: $section-spacing-mobile;
      }
    }
  }

  .professional-testimonials-view-more {
    margin-top: $section-spacing-mobile;
  }
}
