.page-staff-application {
  background-color: $color-gray-med;

  .input-upload {
    background-color: $color-brand-white;
  }

  .listing-files-title {
    color: $color-text-base;
  }
}
