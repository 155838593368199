.suggested-insight {
  margin-bottom: $section-spacing-large;

  padding-bottom: $section-spacing-small;
  border-bottom: 2px solid $color-gray-alt;
}

.suggested-insight-title {
  @include font-univers-condensed-light($section-heading-size);

  line-height: 1.1;
  margin-bottom: px-to-em($section-heading-spacing, $section-heading-size);

  @include design-mark(21px, $section-heading-size, $color-brand-gray);
}

.suggested-insight-tag {
  @include transition(color);

  color: $color-link;
}

.suggested-insight-link {
  @include font-size-banner();

  position: relative;
  display: block;
  padding-right: $arrow-padding;

  &:hover {
    color: $color-link-hover;

    .suggested-insight-tag {
      color: $color-link-hover;
    }

    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    @include transition(color);

    font-size: px-to-em($arrow-icon-size, $font-size-banner);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }
}

@include screen($bp-mobile) {
  .suggested-insight {
    margin-bottom: $section-spacing-large-mobile;
  }
}
