$scrollspy-nav-font-size: 21px;
$scrollspy-nav-border-width: 9px;

.scrollspy-nav {
  position: absolute;
  width: px-to-em($scrollspy-nav-width);
  float: left;
  transform: translate3d(0, 0, 0);

  &.is-sticky-top {
    position: fixed;
    top: px-to-em($section-spacing-large-px + $site-header-height);
  }

  &.is-sticky-bottom {
    position: absolute;
    bottom: 0;
    top: auto;
  }
}

//TODO: this fix made the recruiting contacts page glitch the opposite way and does not fix it there
.lawyer-careers-nav-offset .scrollspy-nav.is-sticky-top {
  top: px-to-em($section-spacing-large-px * 2 + $site-header-height);
}

//the sticky bottom doesn't work very well on mobile here ...
//remove sticky bottom functionality for mobile across the entire site
.scrollspy-nav.scrollspy-nav.is-sticky-bottom {
  @include screen($bp-scrollspy) {
    position: fixed;
    bottom: auto;
    top: $site-header-height;
  }
}
//make up for lawyer careers secondary nav
.lawyer-careers-nav-offset .scrollspy-nav.is-sticky-top,
.lawyer-careers-nav-offset .scrollspy-nav.is-sticky-bottom {
  @include screen($bp-scrollspy) {
    position: fixed;
    top: $site-header-height + 45px;
    bottom: auto;
  }
}

.scrollspy-nav-link {
  @include font-univers-condensed-bold($scrollspy-nav-font-size);
  line-height: 1.2;
  display: inline-block;
  border-left: $scrollspy-nav-border-width solid $color-gray-steel;
  padding-left: px-to-em(17.5px, $scrollspy-nav-font-size);
  padding-top: px-to-em(9px, $scrollspy-nav-font-size);
  padding-bottom: px-to-em(40px, $scrollspy-nav-font-size);
  text-transform: uppercase;
  color: $color-gray-steel;
  transition: $transition-default;

  &:hover {
    color: $color-brand-black;
    border-left-color: $color-brand-black;
  }

  .is-inview & {
    color: $color-red-on-white-foreground;
    border-left-color: $color-brand-red;
  }
}

$scrollspy-nav-height-tablet: 61px;
$scrollspy-nav-font-size-tablet: 16px;

.scrollspy-nav-toggle {
  @include font-univers-condensed-bold($scrollspy-nav-font-size-tablet);
  line-height: 1.2;
  width: 100%;
  height: px-to-em(
    $scrollspy-nav-height-tablet,
    $scrollspy-nav-font-size-tablet
  );
  text-transform: uppercase;
  color: $color-link;
  transition: $transition-default;
  padding: 1.1em 1em 0.95em;
  position: relative;
  z-index: 10;
  background-color: $color-gray-light;
  border-top: 1px solid $color-brand-white;
  display: none;

  @include ico-icon-after($icon-chevron-down-alt) {
    @include transition(transform);
    font-size: 0.5em;
    margin-top: 0.45em;
    margin-left: 1em;
    vertical-align: top;
  }

  &:hover {
    color: $color-text-gray-soft;
  }
}

@include screen($bp-scrollspy) {
  .scrollspy-nav {
    background-color: $color-gray-light;
    border-bottom: 1px solid $color-brand-gray;
    width: 100%;
    top: 0;
    left: 0;
    z-index: $zindex-scrollspy-nav;

    &.show-menu {
      .scrollspy-nav-list {
        transform: translateY(0);
      }

      .scrollspy-nav-toggle {
        &:after {
          transform: rotateX(180deg);
        }
      }
    }

    &.is-sticky-top {
      position: fixed;
      top: px-to-em($site-header-height-mobile);
    }

    &.is-sticky-bottom {
      position: absolute;
      bottom: 0;
      top: auto;
    }
  }

  .scrollspy-nav-toggle {
    display: inline-block;
  }

  .scrollspy-nav-option {
    border-bottom: 1px solid $color-brand-gray;
    display: table;
    width: 100%;
  }

  .scrollspy-nav-list {
    @include transition(transform);
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: $color-gray-light;
    transform: translateY(-100%);
  }

  .scrollspy-nav-link {
    display: table-cell;
    vertical-align: middle;
    font-size: 1em;
    border-left: 0;
    padding: 1em;
    text-align: center;
    height: px-to-em(
      $scrollspy-nav-height-tablet,
      $scrollspy-nav-font-size-tablet
    );

    .is-inview & {
      display: none;
    }
  }

  .lawyer-careers-nav-offset {
    .scrollspy-nav {
      position: fixed;
      top: 106px;

      &.is-sticky-bottom {
        position: fixed;
        top: 106px;
        bottom: auto;
      }
    }
  }
}
