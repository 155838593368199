.page-professionals-landing {
  .impactful-lead {
    padding: $section-spacing-large 0 0;
    background-color: $color-search-filters;
  }

  .professionals-landing-results-load-more {
    margin: $section-spacing-small auto 0;
    display: block;
  }

  .professionals-landing-results {
    padding-top: $section-spacing-large;
    margin-bottom: $section-spacing-largest;
  }

  .professionals-landing-promo,
  .professionals-landing-results {
    &[aria-hidden="true"] {
      display: none;
    }
  }

  @include screen($bp-mobile) {
    .impactful-lead {
      padding: $section-spacing-large-mobile 0 0;
    }

    .professionals-landing-results {
      padding-top: $section-spacing-large-mobile;
      margin-bottom: $section-spacing-large-mobile;
    }
  }
}
