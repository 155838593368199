.listing-contributors-item {
  @include font-size-entity-title();

  & + & {
    margin-top: px-to-em($element-spacing-small-px, $font-size-entity-title);
  }
}

.listing-contributors-name {
  display: inline-block;
  margin-right: 0.5em;
  color: $color-link;

  &:hover {
    color: $color-link-hover;
  }
}

.listing-contributors-meta {
  &:before {
    content: "/";
    margin-right: 0.5em;
  }
}

@include screen($bp-mobile) {
  .capabilities-list-capability {
    font-size: px-to-em($font-size-entity-title-mobile);
  }

  .capabilities-list-link {
    display: inline;
  }
}
