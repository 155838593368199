$left-width: 35%;

.secondary-footer {
  background-color: $color-black-background;
  padding: 2em 0;
}

//left content
.secondary-footer-description-text {
  color: $color-brand-white;
  display: inline-block;
  vertical-align: top;
  margin: auto 0;
  width: $left-width;
  line-height: inherit;
  @include font-univers-condensed-bold(30px);

  @include screen($bp-tablet) {
    display: block;
    width: auto;
    text-align: center;
  }
}

//right content
.secondary-footer-links {
  display: inline-block;
  vertical-align: middle;
  // flex-grow: 4;
  // margin-left: auto;
  width: 99- $left-width;
  // flex-direction: column;
  // flex-wrap: wrap;
  // align-items: flex-end;
  // justify-content: flex-end;
  @include screen($bp-tablet) {
    display: block;
    width: auto;
    text-align: center;
  }
}

.secondary-footer-links-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  @include screen($bp-tablet) {
    flex-direction: column;
  }
}

.secondary-footer-link {
  @include font-univers-condensed-bold(20px);
  justify-content: flex-start;
  width: 50%;
  display: flex;
  color: $color-brand-red;
  text-align: left;

  &:hover {
    color: $color-brand-white;
    // color: $color-text-red-hover;
  }

  @include screen($bp-tablet) {
    justify-content: center;
    width: 100%;
  }
}
