@use "sass:math";

$profile-img-ratio: math.div(1370, 597);

$profile-img-width: 1370px;
$profile-img-height: 519px;
$profile-face-window: 509px;
$profile-line-height: 1.2;
$profile-hide-should-offset: -30px;

.profile-header {
  position: relative;
  background-color: $color-black-background;

  display: flex;
  flex-direction: column;
}

.profile-header-wrapper {
  min-height: px-to-em($profile-img-height);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  @include transition(min-height);
}

.profile-header-figure {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: px-to-em($profile-face-window);
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-nero-90;
  }
}

.profile-header-snapshot-wrapper {
  display: inline-block;
  position: relative;
  margin-left: px-to-em($profile-hide-should-offset);
  height: 100%;

  &:after {
    @include gradient($color-nero-transparent, $color-nero, 90deg, 100%);
    @include transition(width);

    content: "";
    position: absolute;
    display: block;
    right: 0;
    background-color: transparent;
    width: 15%;
    display: block;
    top: 0;
    bottom: 0;
  }
}

.profile-header-snapshot {
  position: relative;
  //margin-left: px-to-em($profile-hide-should-offset);
  height: 105%;
  min-height: 100%;
}

.profile-header-details {
  $spacing: 26.5px;
  @include transition(padding);

  z-index: 5;
  padding: px-to-em($spacing * 2.25) px-to-em($spacing)
    px-to-em($spacing * 1.25);
  padding-right: px-to-em($spacing * 1.75);
  color: $color-brand-white;
  position: relative;
  margin-left: px-to-em($profile-face-window);
  flex-grow: 1;
  max-width: px-to-em(925px);
}

.profile-header-name {
  @include font-size-heading();
}

.profile-header-position {
  @include font-univers-condensed-light($font-size-h3);

  line-height: $profile-line-height;

  @include design-mark(17.5px, $font-size-h3, $color-brand-gray);
}

.profie-header-contact {
  margin-top: 0.45em;
}

.profile-header-email,
.profile-header-linkedin {
  $icon-padding: 39px;

  @include font-univers-condensed-light($font-size-entity-title);

  line-height: $profile-line-height;
  color: $color-brand-white;

  position: relative;
  padding-left: px-to-em($icon-padding, $font-size-entity-title);

  @include ico-icon-before($icon-linkedin) {
    position: absolute;
    left: 0;
    top: 0.05em;
  }
}

.profile-header-email {
  &:before {
    content: $icon-email;
    font-size: 0.6em;
    margin-top: 0.4em;
  }
}

.profile-header {
  .offices-related {
    margin-top: 0.5em;
    overflow: hidden;
  }

  .offices-related-office {
    width: px-to-em(194px);
    padding-right: 0.9em;
    padding-bottom: 1em;
    display: inline-block;
    vertical-align: top;
  }
}

.languages {
  $spacing: 26px;

  @include transition(padding);

  .profile-header & {
    position: absolute;
    margin-left: px-to-em($profile-face-window);
    top: px-to-em($spacing);
    padding-left: px-to-em($spacing);
    z-index: 10;
  }
}

//social media - find share tools styles inside share-tools.scss
.social-links {
  .profile-header & {
    $spacing: 22px;

    position: absolute;
    top: px-to-em($spacing * 2.5);
    right: px-to-em($spacing);

    .social-links-item {
      display: block;
      line-height: 1;
      margin-bottom: 1em;
    }

    .social-links-icon {
      font-size: px-to-em(24px);
    }

    .ico-pdf {
      font-size: px-to-em(28px);
    }

    .ico-vcard {
      font-size: px-to-em(19px);
    }

    .ico-share {
      font-size: px-to-em(23px);
    }
  }
}

/*----------  Portrait Image  ----------*/
.profile-header--portrait {
  .profile-header-snapshot-wrapper {
    margin-left: 0;

    &:after {
      content: none;
    }
  }

  .profile-header-snapshot {
    height: 100%;
  }
}

/*----------  no IMage  ----------*/

.profile-header--condensed {
  text-align: center;

  .profile-header-details {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 0;
    width: 100%;
  }

  .profile-header-position {
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .languages {
    @include center(horizontal);

    margin-left: 0;
    padding-left: 0;
  }
}

@include screen($profile-img-width, min) {
  $profile-face-window: math.div(585, 1370) * 100%;
  $profile-content-padding: 2%;

  .profile-header-wrapper {
    min-height: math.div(80vw, $profile-img-ratio);
  }

  .profile-header-figure {
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: $profile-face-window;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $color-nero-90;
    }
  }

  .profile-header-snapshot-wrapper {
    max-width: 90%;
  }

  .profile-header-snapshot {
    height: auto;
    min-height: 100%;
    max-width: 100%;
  }

  .languages {
    .profile-header & {
      margin-left: $profile-face-window;
      padding-left: $profile-content-padding;
    }
  }

  .profile-header-details {
    margin-left: $profile-face-window;
    padding-left: $profile-content-padding;
  }

  /*----------  Portrait Image  ----------*/
  .profile-header--portrait {
    $profile-face-window: 585px;

    .profile-header-wrapper {
      min-height: px-to-em($profile-img-height);
    }

    .profile-header-snapshot {
      min-width: auto;
      min-height: inherit;
    }

    .profile-header-details,
    .languages {
      margin-left: px-to-em($profile-face-window);
    }
  }

  .profile-header--condensed {
    .profile-header-wrapper {
      min-height: px-to-em($profile-img-height);
    }

    .languages {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

@include screen($bp-bio-desktop) {
  $profile-face-window: 45%;

  .profile-header-figure {
    &:after {
      left: $profile-face-window;
    }
  }

  .profile-header-snapshot {
    height: 100%;
  }

  .languages {
    .profile-header & {
      margin-left: $profile-face-window;
    }
  }

  .profile-header-details {
    margin-left: $profile-face-window;
  }

  /*----------  no IMage  ----------*/

  .profile-header--condensed {
    text-align: center;

    .languages {
      margin-left: 0;
    }
  }

  /*----------  Portrait Version  ----------*/
  .profile-header--portrait {
    .profile-header-figure {
      width: $profile-face-window;

      &:after {
        display: none;
      }
    }

    .profile-header-snapshot {
      @include center(horizontal, relative);
    }

    .profile-header-snapshot-wrapper {
      display: block;
    }
  }
}

@include screen($bp-scrollspy) {
  .profile-header {
    z-index: $zindex-scrollspy-nav + 5;
  }
}

@include screen($bp-tablet) {
  $profile-img-height: 508px;

  .profile-header {
    .offices-related {
      margin-bottom: 0.4em;
    }
  }

  .profile-header-wrapper {
    min-height: inherit;
    display: block;
    text-align: center;
  }

  .profile-header-figure {
    height: px-to-em($profile-img-height);

    &:after {
      @include absolute-full;

      @include gradient($color-nero, $color-nero-transparent, 0deg, 100%);

      background-color: transparent;
      content: "";
      display: block;
      top: 80%;
    }
  }

  .profile-header-snapshot-wrapper {
    margin-left: 0;
  }

  .profile-header-snapshot {
    height: 100%;
  }

  .profile-header-details {
    $spacing: 26px;

    padding: px-to-em($profile-img-height) px-to-em($spacing)
      px-to-em($spacing * 2.25);
    margin-left: 0;
  }

  .profile-header-name {
    $header-size-mobile: 32px;
    font-size: px-to-em($header-size-mobile);
  }

  .profile-header-position {
    font-size: px-to-em($font-size-h3-mobile);
    line-height: 1.6;

    &:after {
      margin-left: auto;
      margin-right: auto;
      width: px-to-em($global-line-mark, $font-size-h3-mobile);
    }
  }

  .profie-header-contact {
    display: inline-block;
    vertical-align: bottom;
    margin-top: 0;
  }

  .profile-header-email,
  .profile-header-linkedin {
    $icon-size: 30px;

    display: inline-block;
    width: px-to-em($icon-size, $font-size-entity-title);
    padding-left: px-to-em($icon-size);
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    vertical-align: middle;

    &:before {
      top: 0;
    }
  }

  .languages {
    .profile-header & {
      @include center(horizontal);

      margin-left: 0;
      padding-left: 0;
      top: px-to-em($profile-img-height - $section-spacing-px);
    }
  }

  .social-links {
    .profile-header & {
      position: relative;
      top: auto;
      right: auto;
      display: inline-block;

      .social-links-item {
        display: inline-block;
        margin-bottom: 0;
        width: px-to-em(39px);
        text-align: left;
        vertical-align: bottom;
      }

      .share-tools-trigger {
        padding-bottom: 0;

        &:before {
          vertical-align: middle;
        }
      }

      .ico-pdf {
        font-size: px-to-em(20px);
      }

      .ico-vcard {
        font-size: px-to-em(18px);
      }
    }
  }

  /*----------  no IMage  ----------*/

  .profile-header--condensed {
    $spacing: 39px;

    .profile-header-details {
      padding-top: px-to-em($spacing * 2.25);
    }

    .languages {
      top: px-to-em($spacing);
    }
  }

  /*----------  Portrait Version  ----------*/
  .profile-header--portrait {
    .profile-header-figure {
      width: 100%;

      &:after {
        display: block;
      }
    }

    .profile-header-snapshot {
      left: auto;
      transform: translateX(0);
    }
  }
}

@include screen($bp-mobile) {
  $profile-img-height: 320px;

  .profile-header {
    .offices-related-office {
      width: 49%;
      padding-right: 0.5em;
    }
  }

  .profile-header-figure {
    height: px-to-em($profile-img-height);
  }

  .profile-header-snapshot-wrapper {
    margin-left: 0;
  }

  .profile-header-snapshot {
    height: 116%;
  }

  .profile-header-details {
    $spacing: 30px;

    padding: px-to-em($profile-img-height) 0.75em px-to-em($spacing * 1.5);
  }

  .languages {
    .profile-header & {
      top: px-to-em($profile-img-height - $section-spacing-px);
    }
  }

  .profile-header-position {
    font-size: px-to-em(20px);
    line-height: 1.2;
  }

  /*----------  no IMage  ----------*/

  .profile-header--condensed {
    $spacing: 30px;

    .profile-header-details {
      padding-top: px-to-em($spacing * 2.25);
    }

    .languages {
      top: px-to-em($spacing);
    }
  }
}

@include screen($bp-mobile-small) {
  .profile-header-snapshot {
    margin-left: -5vw;
  }

  .offices-related {
    .profile-header & {
      .offices-related-office {
        &:nth-child(2n + 1) {
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}
