.get-in-touch {
  background-color: $color-black-background;
  padding: $section-spacing 0;
  color: $color-brand-white;
  text-align: center;

  .page-wrapper {
    display: inline-block;
    text-align: left;
    min-width: $content-max-width;
  }
}

.get-in-touch-header {
  @include font-size-heading();

  line-height: 1.2;
}

.get-in-touch-position {
  @include font-univers-condensed-light($font-size-h3);

  line-height: 1.2;

  @include design-mark(17.5px, $font-size-h3, $color-brand-white);
}

.get-in-touch-email {
  @include font-univers-condensed-light($font-size-entity-title);

  color: $color-brand-white;
}

.get-in-touch-details {
  display: flex;

  .offices-related {
    @include clearfix;
  }

  .offices-related-office {
    margin-top: 0.65em;
    display: inline-block;
    width: px-to-em(280px);
    vertical-align: top;
    padding-left: px-to-em(50px);
  }
}

.get-in-touch-info {
  flex-shrink: 0;
  max-width: 45%;
}

@include screen(1040px) {
  .get-in-touch {
    .page-wrapper {
      min-width: auto;
    }
  }
}

@include screen($bp-tablet) {
  .get-in-touch {
    text-align: center;

    .page-wrapper {
      display: block;
      text-align: center;
    }
  }

  .get-in-touch-details {
    display: block;

    .offices-related {
      width: 100%;
      margin-top: $element-spacing;
      display: flex;
      flex-wrap: wrap;
    }

    .offices-related-office {
      flex-grow: 1;
      width: auto;
      padding-left: 0;
      text-align: center;
    }
  }

  .get-in-touch-info {
    max-width: none;
  }

  .get-in-touch-position {
    &:after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@include screen($bp-mobile) {
  .get-in-touch {
    padding: $section-spacing-mobile 0 $section-spacing-small;
  }

  .get-in-touch-position {
    font-size: px-to-em($font-size-h3-mobile);
  }

  .get-in-touch-details {
    .offices-related-office {
      padding: 0 1.25em;
    }
  }
}

@include screen($bp-mobile-small) {
  .get-in-touch-details {
    .offices-related {
      margin-top: 0;
      display: block;
    }

    .offices-related-office {
      display: block;
      margin: $element-spacing auto;
    }
  }
}
