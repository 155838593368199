.social-connection {
  $grid-spacing: px-to-em(20px);
  text-align: center;

  .social-links {
    &-item {
      &:not(:last-child) {
        margin-right: $section-spacing-mobile;
      }
    }

    &-icon {
      color: $color-text-red;
      font-size: px-to-em(35px);
    }
  }

  @include screen($bp-tablet, min) {
    &__body {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: baseline;
    }

    &__title {
      margin-bottom: 0;
    }

    .social-links {
      margin-left: $section-spacing-mobile;

      &-icon {
        &:hover {
          color: $color-gray-on-gray-foreground;
        }
      }
    }
  }
}
