@use "sass:math";

.impactful-lead--contact-us {
  margin-bottom: $section-spacing;
}

.section-separator {
  margin-bottom: $section-spacing;
}

.input-textarea {
  display: block;
  width: 100%;
  height: 300px;
}

.contact-us-form-meta {
  display: block;
  margin-bottom: math.div($section-spacing, 2);
}

.contact-us-form-actions {
  margin-bottom: $section-spacing * 2;
}

.send-email-label {
  margin-right: 4px;
  text-transform: uppercase;
  @include font-univers-condensed-bold();
}

.send-email-recipient {
  color: $color-gray-on-lightgray-foreground;
  @include font-univers-condensed-bold($font-size-copy);
}

.btn--primary {
  $padding: 17.5px;
  @include transition(background-color);
  @include font-univers-condensed-bold($font-size-copy-mobile);

  line-height: 1.1;
  color: $color-brand-white;
  font-size: px-to-em($font-size-copy-mobile);
  background-color: $color-brand-red;
  border-color: $color-brand-red;
  padding: px-to-em($padding, $font-size-copy);

  &:hover {
    background-color: $color-opaque-red;
  }

  @include screen($bp-mobile-landscape, min) {
    font-size: px-to-em($font-size-copy);
  }
}

.btn--block {
  display: block;
}

@include screen($bp-mobile) {
  .impactful-lead--contact-us {
    margin-bottom: 0;
  }

  .send-email-recipient {
    color: $color-gray-steel;
    @include font-univers-condensed-bold($font-size-copy-mobile);
  }
}
