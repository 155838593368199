.page-site-search {
  @include screen($bp-scrollspy) {
    .site-search {
      padding-top: 3em;
    }

    .site-search-sections {
      margin-top: 3em;
    }
  }
}
