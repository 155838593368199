.overview {
  margin-bottom: $section-spacing-large;
}

.overview-cta {
  text-align: center;
  margin-top: $section-spacing;
}

@include screen($bp-mobile) {
  .overview {
    margin-bottom: $section-spacing-large-mobile;
  }

  .overview-cta {
    margin-top: $section-spacing-mobile;
  }
}
