.share-tools {
  position: relative;
}

.share-tools-item {
  display: inline;
}

.share-tools-options {
  position: absolute;
  top: 100%;
  transform: translateY(-2em);
  opacity: 0;
  visibility: hidden;
  padding: 1em 1em 0;
  margin-left: -1em;
  transition: transform $transition-timing $transition-ease,
    opacity $transition-timing $transition-ease,
    visibility $transition-timing $transition-ease $transition-timing;

  .show-menu & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: transform $transition-timing $transition-ease,
      opacity $transition-timing $transition-ease, visibility 100ms;
  }
}

.share-tools-link-icon {
  color: $color-brand-white;
  display: inline-block;
  width: 100%;

  &:before {
    @include ico-icon();
  }

  font-size: px-to-em(18px);
  text-align: center;

  &.ico-email {
    font-size: px-to-em(14px);
  }
}

.share-tools-trigger {
  color: $color-brand-white;
  padding-bottom: 0.5em;
  font-size: px-to-em(19px);
  width: 100%;

  @include ico-icon-before($icon-share);

  .show-menu & {
    &:before {
      content: $icon-cross;
    }
  }
}

//social media
.page-header,
.page-header-flex,
.profile-header,
.locations-header,
.article-header,
.job-header {
  .share-tools-options {
    background-color: $color-black-background;
  }

  .share-tools-item {
    display: block;
    line-height: 1;
    margin-bottom: 1em;
  }
}

.share-tools--horizontal {
  $font-size-trigger: 21px;

  .share-tools-trigger {
    font-size: px-to-em($font-size-trigger);

    padding-bottom: 0;
    min-width: px-to-em(23px, $font-size-trigger);
    height: px-to-em(28px, $font-size-trigger);
  }

  .share-tools-options {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-1em);
    white-space: nowrap;
    z-index: 5;
    padding: 0;
    margin-left: 0;
  }

  .share-tools-links-icon {
    width: auto;
    padding: 0 1.25em;
  }

  &.show-menu {
    .share-tools-trigger {
      font-size: px-to-em(19px);
      min-width: px-to-em(26px, 19px);
      height: px-to-em(28px, 19px);
    }

    .share-tools-options {
      transform: translateX(1em);
    }
  }
}

@include screen($bp-tablet) {
  .share-tools-options {
    padding: 0;
    margin-left: 0;
  }

  .profile-header,
  .locations-header {
    .share-tools {
      &:after {
        content: "";
        position: absolute;
        width: 3em;
        height: 150%;
        right: 100%;
        width: px-to-em(219px);
        bottom: 0;
        background-color: transparent;
        z-index: -1;
        transition: background-color 100ms ease-in, z-index $transition-timing;
      }

      &.show-menu {
        &:after {
          background-color: $color-black-background;
          z-index: 1;
          transition: background-color 100ms ease-in;
        }

        .share-tools-options {
          transform: translateX(-1em);
        }
      }
    }

    .share-tools-options {
      position: absolute;
      top: 0.2em;
      left: auto;
      right: 100%;
      transform: translateX(1em);
      white-space: nowrap;
      z-index: 5;
    }

    .share-tools-trigger {
      text-align: left;
    }

    .share-tools-item {
      display: inline;
      margin-left: 1.5em;
    }

    .share-tools-link-icon {
      width: auto;
      padding: 0 0.25em;
    }
  }

  .locations-header {
    .share-tools {
      @include transition(transform);

      &.show-menu {
        transform: translateX(px-to-em(70px));
      }
    }
  }
}

@include screen($bp-mobile) {
  //questionable css here, hopefully the context is always the same
  //otherwise has to be tied to profile header or insights detail header
  .share-tools-options {
    position: absolute;
    top: 0.2em;
    right: 100%;
    transform: translateX(1em);
    white-space: nowrap;
    z-index: 5;

    .show-menu & {
      transform: translateX(-1em);
    }
  }

  .share-tools-trigger {
    font-size: px-to-em(20px);
    padding-bottom: 0;

    .show-menu & {
      &:before {
        font-size: 0.75em;
      }
    }
  }

  .share-tools-link-icon {
    width: auto;
    font-size: px-to-em(20px);
    padding: 0 0.25em;

    &.ico-email {
      font-size: px-to-em(14px);
    }
  }

  .share-tools {
    .page-header &,
    .page-header-flex &,
    .profile-header &,
    .locations-header &,
    .article-header &,
    .job-header & {
      &:after {
        content: "";
        position: absolute;
        width: 3em;
        height: 150%;
        right: 100%;
        width: px-to-em(250px);
        bottom: 0;
        background-color: transparent;
        z-index: -1;
        transition: background-color 100ms ease-in, z-index $transition-timing;
      }

      &.show-menu {
        &:after {
          background-color: $color-black-background;
          z-index: 1;
          transition: background-color 100ms ease-in;
        }
      }

      .share-tools-item {
        display: inline;
        margin-left: 1.5em;
      }

      .share-tools-link-icon {
        vertical-align: middle;
      }
    }
  }

  .share-tools {
    .page-header &,
    .page-header-flex &,
    .article-header & {
      @include transition(transform);

      &.show-menu {
        transform: translateX(px-to-em(80px));
      }
    }
  }

  .share-tools--horizontal {
    $font-size-trigger: 22px;

    &.show-menu {
      .share-tools-option {
        transform: translateX(0.5em);
      }
    }

    .share-tools-trigger {
      font-size: px-to-em($font-size-trigger);
      min-width: px-to-em(26px, $font-size-trigger);
      height: px-to-em(26px, $font-size-trigger);
    }

    &.show-menu {
      .share-tools-trigger {
        font-size: px-to-em($font-size-trigger);
        min-width: px-to-em(26px, $font-size-trigger);
        height: px-to-em(26px, $font-size-trigger);
      }
    }

    .share-tools-link-icon {
      padding: 0 0.75em;
      vertical-align: middle;
      line-height: 1;
    }
  }
}

$dark-color: $color-brand-black;
.share-tools-dark {
  .share-tools-trigger,
  .share-tools-link-icon,
  .share-tools-links-icon,
  .social-links-icon {
    color: $dark-color;
  }
  .ico-pdf {
    font-size: px-to-em(24px);
  }
}

.share-tools-top-right {
  z-index: 50;
  position: absolute;
  top: 180px;
  right: 100px;

  @media screen and (min-width: $bp-mobile) {
    &.force-195 {
      top: 195px;
    }
  }

  @include screen($bp-mobile) {
    top: 220px;
    right: 40px;

    &.no-in-page-nav {
      top: 165px;
    }
  }

  &.inside-page-wrapper {
    top: 5px;
    right: 5px;
  }
}
