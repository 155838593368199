.sponsoring-organizations-filters {
  padding-bottom: $section-spacing * 1.25;
  background-color: lighten($color-brand-gray, 10%);
}

@include screen($bp-mobile) {
  .impactful-lead-copy {
    font-size: px-to-em($impact-lead-copy-mobile);
    padding: px-to-em(25px, $impact-lead-copy-mobile) 0;
  }
}
