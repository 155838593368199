$insight-grid-spacing: 1px;

.insights-grid-list {
  columns: 2 auto;
  column-gap: $insight-grid-spacing;
}

.insights-grid-list-item {
  margin-bottom: 0;
  padding-bottom: $insight-grid-spacing;
  break-inside: avoid;
  overflow: hidden;
  vertical-align: top;
  transform: translateZ(0);

  &:nth-child(3n + 1) {
    .insight-tile {
      background-color: $color-nero-98;

      &:hover {
        background-color: $color-opaque-red;
      }
    }

    div.insight-tile {
      &:hover {
        background-color: $color-nero-98;
      }
    }
  }
}

.insights-grid-view-more {
  padding-top: $section-spacing;
  text-align: center;

  .btn {
    @include ico-icon-after($icon-plus) {
      @include transition(transform);

      font-size: 0.65em;
      margin-left: 0.5em;
      vertical-align: middle;
    }
  }
}

@include screen($bp-mobile-landscape) {
  .insights-grid-list {
    columns: auto;
  }

  .insights-grid-view-more {
    padding-top: $section-spacing-mobile;
  }
}
