.listing-organization-state {
  position: relative;
  padding-bottom: px-to-em($listing-padding);

  & + & {
    padding-top: px-to-em($listing-padding);
  }
}

.listing-organization-state-title {
  @extend .section-heading;
}

.listing-organization-city {
  & + & {
    padding-top: px-to-em($listing-padding);
  }
}

.listing-organization-city-title {
  $font-size: 35px;

  @include font-univers-condensed-light($font-size);

  line-height: 1.1em;

  color: $color-gray-steel;

  @include design-mark(14px, $font-size, $color-brand-gray);
}

.listing-organization-sponsors-list {
  font-family: $font-family-copy;
  font-size: px-to-em($font-size-copy);
}

.listing-organization-sponsor-img {
  max-height: px-to-em(23px);
  margin-right: 0.5em;
  vertical-align: middle;
}

.listing-organization-sponsor {
  position: relative;
  padding-left: 1.15em;

  &:before {
    content: "■";
    color: $color-link;
    font-size: 0.6em;
    line-height: 2.25;
    margin-left: -1.75em;
    position: absolute;
  }
}

@include screen($bp-mobile) {
  .listing-organization-state {
    padding-bottom: px-to-em($listing-padding-mobile);

    & + & {
      padding-top: px-to-em($listing-padding-mobile);
    }
  }

  .listing-organization-city {
    & + & {
      padding-top: px-to-em($listing-padding-mobile);
    }
  }

  .listing-organization-city-title {
    $font-size: 32px;

    font-size: px-to-em($font-size);

    @include design-mark(16px, $font-size, $color-brand-gray);
  }

  .listing-organization-sponsors-list {
    font-size: px-to-em($font-size-copy-mobile);
  }
}
