.entity-search-control {
  background-color: $color-search-filters;
  padding: $section-spacing-large 0;
}

@include screen($bp-tablet) {
  .entity-search-control {
    padding: $section-spacing-large-mobile 0;
  }
}
