$advanced-search-button-height: px-to-em(
  $element-spacing-px,
  $font-size-buttons
);

.advanced-search {
  position: relative;
  margin-top: $section-spacing;
  padding-bottom: $advanced-search-button-height;

  &.is-expanded {
    .advanced-search-fields {
      display: block;
    }

    .advanced-search-toggle {
      &:after {
        transform: rotateX(180deg);
      }
    }
  }
}

.advanced-search-fields {
  display: none;
}

.advanced-search-toggle {
  @include font-size-buttons();
  @include center(horizontal);

  color: $color-brand-red;
  text-transform: uppercase;
  bottom: 0;

  &:hover {
    color: $color-link-hover;
  }

  @include ico-icon-after($icon-chevron-down-alt) {
    @include transition(transform);

    color: inherit;
    font-size: px-to-em($arrow-down-icon-size, $font-size-buttons);
    margin-left: 1em;
    vertical-align: middle;
  }
}

@include screen($bp-mobile) {
  .advanced-search {
    margin-top: $section-spacing-mobile;
  }
}
