@use "sass:math";

$header-font-size: 63px;
$header-font-size-mobile: 45px;

$header-padding: px-to-em(70px);
$header-padding-mobile: math.div($header-padding, 2);

.page-header {
  background-color: $color-black-background;
  padding: $header-padding 0;
  text-align: center;

  .page-wrapper {
    position: relative;
    z-index: 5;
  }
}

.page-header-title {
  @include font-univers-condensed-bold($header-font-size);

  line-height: 1.1;
  color: $color-brand-white;
}

.page-header-title--interior {
  color: $color-brand-black;
  font-family: $font-family-univers-condensed-light;
  margin-bottom: px-to-em($section-spacing-px, $header-font-size);
}

.page-header--banner {
  @include bg-cover;

  position: relative;

  &:before {
    @include absolute-full;
    @include gradient($color-nero 0%, $color-nero-25, 0deg);

    content: "";
  }

  .page-header-title {
    display: inline-block;
    border-bottom: 1px solid $color-gray-steel;
  }
}

.page-header-user-actions {
  //cursor: pointer;
  margin-top: $section-spacing-small;

  a.page-header-user-action {
    cursor: pointer;
  }
}

.page-header-user-action {
  @include font-size-entity-title();

  display: inline-block;
  color: $color-link;

  &:hover {
    color: $color-text-gray-soft;
  }

  & + & {
    border-left: 1px solid $color-gray-steel;
    padding-left: 0.7em;
    margin-left: 0.5em;
  }
}

.page-header--fellowship {
  margin-top: px-to-em($site-header-fellowshipt-height - $site-header-height);
  padding-top: $section-spacing-small;

  &:before {
    background-image: linear-gradient(
      to top,
      $color-nero 10.92%,
      $color-nero-60 61.34%
    );
  }

  .breadcrumbs {
    text-align: left;
    padding-bottom: $section-spacing;
  }

  .breadcrumbs-item {
    color: $color-brand-white;
  }

  .breadcrumbs-title {
    @include font-univers-condensed-bold($font-size-site-header-utility);
    color: $color-brand-white;
  }

  a.breadcrumbs-title {
    color: $color-link;
  }
}

//social media
.social-links {
  .page-header & {
    $spacing: 26px;

    position: absolute;
    top: 0;
    right: $site-padding;

    .social-links-item {
      display: block;
      line-height: 1;
      margin-bottom: 1em;
    }

    .social-links-icon {
      font-size: px-to-em(24px);
    }

    .ico-pdf {
      font-size: px-to-em(28px);
    }

    .ico-share {
      font-size: px-to-em(23px);
    }
  }

  .page-header--fellowship & {
    top: $site-padding;
  }
}

@include screen($bp-scrollspy) {
  .page-header {
    background-color: $color-black-background;
    position: relative;
    z-index: $zindex-scrollspy-nav + 5;
  }
}

@include screen($bp-mobile + 1) {
  .page-header {
    padding: $header-padding-mobile 0;
  }

  .page-header-title {
    font-size: px-to-em($header-font-size-mobile);
    text-align: center;
  }

  .page-header-user-actions {
    margin-top: 1em;
  }

  .page-header-title--interior {
    color: $color-brand-black;
    margin-bottom: px-to-em(
      $section-spacing-mobile-px,
      $header-font-size-mobile
    );
  }

  .page-header--fellowship {
    padding-top: math.div($header-padding-mobile, 2);

    .breadcrumbs {
      padding-bottom: math.div($header-padding-mobile, 2);
    }

    .breadcrumbs-title {
      font-size: 1em;
    }
  }

  .social-links {
    .page-header & {
      $spacing: 30px;

      position: relative;
      top: auto;
      right: auto;
      display: inline-block;
      margin-top: 1em;
      vertical-align: middle;

      .social-links-item {
        display: inline-block;
        margin-bottom: 0;
        width: px-to-em(45px);
        text-align: left;
      }

      .ico-pdf {
        font-size: px-to-em(20px);
      }

      .ico-share {
        font-size: px-to-em(20px);
      }
    }
  }
}

@include screen($bp-mobile-landscape) {
  .page-header--fellowship {
    margin-top: 0;
  }
}
