.search-landing-listing {
  margin-bottom: $section-spacing-large;
}

.search-landing-listing-view-more {
  display: block;
  margin: $section-spacing auto 0;
  display: block;
  text-align: center;

  &--nomargin {
    margin-top: 0;
  }
}

@include screen($bp-mobile) {
  .search-landing-listing {
    margin-bottom: $section-spacing-large-mobile;
  }

  .search-landing-listing-view-more {
    margin-top: $section-spacing-mobile;

    &--nomargin {
      margin-top: 0;
    }
  }
}
