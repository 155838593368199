@use "sass:math";

// section styles

.section-wrapper {
  padding: $section-spacing $section-spacing math.div($section-spacing, 2);
}

.section-wrapper--dark {
  background-color: $color-nero-90;
  color: $color-brand-white;
}

.section-wrapper--centered {
  $cross-link-wrapper-padding-em: px-to-em(45px);

  text-align: center;
  padding: $cross-link-wrapper-padding-em $cross-link-wrapper-padding-em
    math.div($cross-link-wrapper-padding-em, 2);

  @include screen($bp-tablet) {
    $tablet-padding: $element-spacing * 1.1;

    padding: $tablet-padding * 1.25;
    padding-bottom: math.div($tablet-padding, 2);
  }

  @include screen($bp-mobile) {
    padding: $element-spacing;
    padding-bottom: math.div($element-spacing, 2);
  }
}

.section-heading--bordered {
  border-bottom: 1px solid $color-brand-white;
  padding: 0 0.25em px-to-em($section-spacing-small-px, $section-heading-size);
  margin-bottom: px-to-em($section-spacing-small-px, $section-heading-size);
  display: inline-block;
}

.section-intro {
  padding-bottom: px-to-em($section-spacing-px, $font-size-copy);
}

.section-cta-links {
  margin-bottom: $element-spacing;
}

.btn-text-link--light {
  color: $color-brand-white;

  &:hover {
    color: $color-text-gray-soft;
  }
}

.section-capabilities {
  margin-bottom: $section-spacing-large;

  .filters {
    text-align: center;

    @include screen($bp-mobile-landscape, min) {
      text-align: right;
    }

    @include screen($bp-mobile-small) {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 6px;

      .filters__title {
        width: 100%;
      }

      .filters__option {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .filters__title {
    margin-right: 4px;
    text-transform: uppercase;
  }

  .filters__option {
    display: inline-block;
    margin-right: 4px;
  }
}

.capability-list {
  @include columns(1);

  @include screen($bp-mobile-landscape, min) {
    @include columns(2);
  }
}

.capability-list-item {
  @include column-item();
  @include clearfix;

  .accordion-node-trigger {
    font-size: px-to-em(16px);
    padding-right: 0;

    &:before {
      font-size: 12px;
      z-index: 99;
      color: $color-link;
    }

    &:hover {
      &:before {
        color: $color-link;
      }
    }
  }
}

.capability-list-item-title {
  $padding: px-to-em(19px, $font-size-entity-title)
    px-to-em(16px, $font-size-entity-title);

  @include font-size-entity-title();

  position: relative;
  line-height: 1.2;
  background-color: $color-gray-light;
  padding: $padding;
  display: block;
  transition: $transition-default;
  padding-right: $arrow-padding;
  display: block;

  &:hover {
    &:after {
      color: $color-link-hover;
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    font-size: px-to-em(
      math.div($arrow-icon-size, 1.25),
      $font-size-entity-title
    );
    right: px-to-em($arrow-listing-offset, math.div($arrow-icon-size, 1.25));
    color: $color-link;
    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }
}

.capability-accordion-node-title {
  $padding: px-to-em(19px, $font-size-entity-title)
    px-to-em(16px, $font-size-entity-title);

  @include font-size-entity-title();
  position: relative;
  line-height: 1.2;
  background-color: $color-gray-light;
  padding: $padding;
  display: block;
  transition: $transition-default;
  padding-right: 0.5em;
  display: block;

  &:not(.no-hover) {
    &:hover {
      color: $color-link-hover;

      &:after {
        color: $color-link-hover;
        transform: translateX(100%) translateY(-50%);
      }
    }
  }

  .accordion-node-trigger {
    color: $color-black;
    font-size: $font-size-entity-title;
    position: static;
    height: 30px;
    float: right;
    position: absolute;
    right: 0;
    padding-right: $arrow-padding;

    &:before {
      color: $color-link;
      font-size: 12px;
      line-height: 0;
    }
  }
}

.capability-list-item-link {
  color: $color-black;
}

.capability-list-subitem {
  padding: 1em;
}

.capability-list-subitem-link {
  color: $color-link;

  font-size: px-to-em(17.5px);
}

.section-probono {
  @include page-wrapper;
  @include bg-cover;

  background-position: center top;
  margin-top: $section-spacing-largest;
  margin-bottom: $section-spacing-largest;
  padding: $section-spacing;

  header {
    text-align: center;
  }
}
