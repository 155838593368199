@use "sass:math";

.sponsoring-organizations {
  margin-bottom: $section-spacing-large;
}

.sponsoring-organizations-filter-by {
  margin-bottom: $section-spacing;

  .select-dropdown {
    vertical-align: middle;
    display: inline-block;
    max-width: px-to-em(300px);
    width: 100%;
  }

  .select-dropdown-toggle {
    border-width: 0 0 1px;
    padding: $element-spacing-small 0;

    &:after {
      right: 0;
    }
  }

  .select-dropdown-label {
    &:after {
      content: ":";
      margin-right: 1em;
    }
  }
}

.sponsoring-organizations-filter-all {
  display: block;
  margin-top: math.div($section-spacing, 2);
}

@include screen($bp-mobile) {
  .sponsoring-organizations {
    margin-bottom: $section-spacing-large-mobile;
  }

  .sponsoring-organizations-filter-by {
    max-width: px-to-em(320px);
    margin-bottom: $section-spacing-mobile;
  }
}
