$searchbox-padding: px-to-em(26px);

.searchbox {
  position: relative;
  border: 1px solid $color-border;
  padding: 0 $searchbox-padding;
}

.searchbox-input {
  $border-padding: 0.2em;
  $icon-padding: 1em;

  @include font-univers-condensed-light(42px);

  line-height: 1.1;
  width: 100%;
  border-width: 0 0 1px;
  padding: 0 $icon-padding $border-padding 0;
  border-bottom-color: $color-brand-turquoise;
  color: $color-brand-black;

  @include placeholder {
    color: $color-brand-black;
    opacity: 1;
  }
}

.searchbox-breaker {
  height: 32px;
}

.searchbox-btn {
  position: absolute;
  right: $searchbox-padding;
  top: 0;
  height: 100%;
  width: 3em;
  text-align: right;

  @include ico-icon-before($icon-search) {
    transform: rotate(90deg);
    font-size: px-to-em(31px);
  }
}

// .searchbox-btn-label {
// 	@extend %hide-label;
// }

//modifieers
.searchbox--large {
  padding-top: px-to-em(35px);
  padding-bottom: px-to-em(26px);
}

@include screen($bp-tablet) {
  .searchbox-breaker {
    height: 0px;
  }

  .searchbox-input {
    font-size: px-to-em(26px);
  }

  .searchbox-btn {
    &:before {
      font-size: px-to-em(21px);
    }
  }
}

@include screen($bp-mobile) {
  .searchbox-breaker {
    height: 0px;
  }
  .searchbox--large {
    padding: $section-spacing-small;
  }
}
