@use "sass:math";

.twitter-feed {
  @include page-wrapper;
  @include bg-cover;

  background-position: center top;
  margin-top: $section-spacing-largest;
  margin-bottom: $section-spacing-largest;
  padding: $section-spacing;

  header {
    text-align: center;
  }
}

.twitter-feed-wrapper {
  background-color: $color-nero-90;

  color: $color-brand-white;
  padding: $section-spacing $section-spacing math.div($section-spacing, 2);
}

.twitter-feed-heading {
  $heading-size: 42px;

  display: inline-block;

  @include font-univers-condensed-light($heading-size);
  line-height: 1.1;

  border-bottom: 1px solid $color-brand-white;
  padding-bottom: px-to-em($section-spacing-small-px, $heading-size);
  margin-bottom: px-to-em($section-spacing-px, $heading-size);
}

.twitter-feed-handle {
  display: block;
  max-width: px-to-em(438px);
  text-align: left;
  font: 700 $font-size-h3 $font-family-copy;
  line-height: 1.1;

  @include ico-icon-before($icon-twitter) {
    margin-right: 0.25em;
    font-size: 1.2em;
    vertical-align: bottom;
  }
}

.twitter-feed-tweet {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.8;
  padding: px-to-em($element-spacing-px, $font-size-entity-title) 0;

  & + & {
    border-top: 1px solid $color-white-50;
  }
}

.twitter-feed-tweet-title {
  display: block;
  line-height: 1.2;
  a {
    color: $color-link;
    @include transition(color);
    &:hover {
      color: $color-link-hover;
    }
  }
}

@include screen($bp-tablet) {
  .twitter-feed {
    margin-top: $section-spacing-large-mobile;
    margin-bottom: $section-spacing-large-mobile;
  }

  .twitter-feed,
  .twitter-feed-wrapper {
    padding: $section-spacing * 0.75;
  }

  .twitter-feed-wrapper {
    padding-bottom: math.div($section-spacing, 2);
  }
}

@include screen($bp-mobile) {
  .twitter-feed,
  .twitter-feed-wrapper {
    padding: $element-spacing * 0.75;
  }

  .twitter-feed-wrapper {
    padding-bottom: math.div($element-spacing, 2);
  }

  .twitter-feed-heading {
    $heading-size: 34px;

    font-size: px-to-em($heading-size);
  }

  .twitter-feed-handle {
    font-size: px-to-em($font-size-h3-mobile);
  }

  .twitter-feed-tweet {
    font-size: px-to-em($font-size-copy-mobile);
  }
}
