@use "sass:math";

$listing-articles-video-padding: 40px;
$listing-articles-video-padding-mobile: 30px;
$listing-articles-video-icon: 26px;

$listing-articles-alumni-year-padding: 61px;
$listing-articles-alumni-year-dimen: 48px;
$listing-articles-alumni-year-font-size: 26px;

$listing-articles-spotlight-max-width: 175px;

.listing-articles {
  & + & {
    margin-top: $section-spacing-large;
  }

  .ng-scope {
    display: block;
  }

  .spacing-small {
    .meta-source {
      padding-top: 0 !important;
    }

    .meta-authors {
      padding-top: px-to-em(
        math.div($section-spacing-small-px, 2),
        $font-size-entity-title
      );
    }
  }

  .newsletter-results-load-more {
    margin: $section-spacing-small auto 0;
    display: block;
  }
}

.listing-articles-heading {
  $font-size: 35px;
  @include font-univers-condensed-light($font-size);

  line-height: 1.1em;
  color: $color-gray-steel;

  @include design-mark(14px, $font-size, $color-brand-gray);
}

.listing-articles-subscribe {
  margin-bottom: px-to-em($section-spacing-px, $font-size-buttons);
}

.listing-articles-item {
  @include clearfix;

  position: relative;
  padding-bottom: px-to-em($listing-padding);
  padding-right: $arrow-padding * 2;
  border-bottom: $border-dark;

  & + & {
    padding-top: px-to-em($listing-padding);
  }

  //first item in the list need to have the arrow readjusted to be visually centered,
  &:first-child {
    .listing-articles-title {
      &:after {
        transform: translateY(-125%);
      }

      &:hover {
        &:after {
          transform: translateX(100%) translateY(-125%);
        }
      }
    }

    .ng-repeat & {
      .listing-articles-title {
        &:after {
          transform: translateY(-50%);
        }

        &:hover {
          &:after {
            transform: translateX(100%) translateY(-50%);
          }
        }
      }
    }
  }
}

.listing-articles-item--video {
  padding-left: px-to-em($listing-articles-video-padding);
}

.listing-articles-title-nolink {
  @include font-size-banner();

  display: block;
}

.listing-articles-title {
  @include font-size-banner();

  display: block;

  &:hover {
    color: $color-link-hover;

    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }

    .listing-articles-alumni-year {
      background-color: $color-black-background;
    }
  }

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    font-size: px-to-em($arrow-icon-size, $font-size-banner);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }

  .listing-articles-item--video & {
    @include ico-icon-before($icon-video) {
      position: absolute;
      font-size: px-to-em($listing-articles-video-icon, $font-size-banner);
      left: 0;
      margin-top: 0.2em;
    }
  }
}

div.listing-articles-title {
  &:after {
    content: "";
  }

  &:hover {
    color: $color-brand-black;
  }
}

.listing-articles-meta {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
  margin-top: px-to-em(
    math.div($section-spacing-small-px, 2),
    $font-size-entity-title
  );

  color: $color-gray-steel;

  &.spacing-small {
    padding-top: 0 !important;
  }

  .meta-category + .meta-date {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }

  .meta-location + .meta-date {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }
}
.listing-articles-abstract {
  margin-top: px-to-em(math.div($section-spacing-small-px, 2), $font-size-copy);

  & + .listing-articles-meta {
    margin-top: px-to-em($section-spacing-small-px, $font-size-entity-title);
  }
}

.listing-articles-figure {
  float: left;
  max-width: px-to-em(330px);
  margin-right: 1em;

  > img {
    max-width: 100%;
  }
}

.listing-articles-title-label {
  display: table;
  font-size: inherit;
}

.listing-articles-see-all {
  text-align: center;
  margin-top: $section-spacing;

  .btn-text-link {
    @include ico-icon-after($icon-chevron-right) {
      font-size: 0.7em;

      margin-top: 0.2em;
      margin-left: 1em;
      vertical-align: top;
    }
  }
}

//alumni specific options
.listing-articles-item--alumni-move {
  padding-left: px-to-em($listing-articles-alumni-year-padding);
}

.listing-articles-alumni-year {
  @include transition(background-color);

  position: absolute;
  left: 0;
  background: $color-brand-red;
  font-size: px-to-em(
    $listing-articles-alumni-year-font-size,
    $font-size-banner
  );
  line-height: 1;
  padding-top: 0.45em;
  padding-right: 0.2em; //accounts for apostrophe and visual centering
  width: px-to-em(
    $listing-articles-alumni-year-dimen,
    $listing-articles-alumni-year-font-size
  );
  height: px-to-em(
    $listing-articles-alumni-year-dimen,
    $listing-articles-alumni-year-font-size
  );
  text-align: center;
  display: inline-block;
  color: $color-brand-white;
  margin-top: 0.25em;

  &:before {
    content: "’";
  }
}

.listing-articles-alumni-facts {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
  margin-top: px-to-em(
    math.div($section-spacing-small-px, 2),
    $font-size-entity-title
  );
  display: table;

  a {
    color: $color-text-red;

    &:hover {
      color: $color-link-hover;
    }

    &:focus {
      outline: 1px dotted $color-brand-red;
    }
  }

  .meta-facts {
    color: $color-gray-steel;

    & + .meta-summary {
      &:before {
        content: "-";
        display: inline-block;
        margin-right: 0.25em;
        margin-left: 0.25em;
      }
    }
  }

  .meta-summary {
    display: inline;
  }
}

.listing-articles-item--alumni-spotlight {
  .listing-articles-figure {
    max-width: px-to-em(
      $listing-articles-spotlight-max-width,
      $font-size-banner
    );
  }
}

@include screen($bp-mobile) {
  .listing-articles {
    & + & {
      margin-top: $section-spacing-large-mobile;
    }

    //takes care of spacing
    .ng-scope {
      & + .ng-scope {
        padding-top: px-to-em($listing-padding-mobile);
      }
    }
  }

  .listing-articles-item {
    padding: px-to-em($listing-padding-mobile) $arrow-padding
      px-to-em($listing-padding-mobile) 0;

    &:first-child {
      .listing-articles-title {
        &:after {
          transform: translateY(0);
        }
      }

      .ng-scope & {
        .listing-articles-title {
          &:after {
            transform: translateY(0);
          }

          &:hover {
            &:after {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    & + & {
      padding-top: px-to-em($listing-padding-mobile);
    }
  }

  .listing-articles-item--video {
    //make sure to update page specific files, like page-alumni-cle-library.scss
    padding-left: px-to-em($listing-articles-video-padding-mobile);

    .listing-articles-title {
      &:before {
        margin-top: 0;
      }
    }
  }

  .listing-articles-heading {
    $font-size: 32px;

    font-size: px-to-em($font-size);

    @include design-mark(16px, $font-size, $color-brand-gray);
  }

  .listing-articles-figure {
    max-width: px-to-em(120px, $font-size-banner-mobile);
  }

  .listing-articles-title {
    //chevron
    &:after {
      bottom: px-to-em($listing-padding);
      top: auto;
      right: 0;
      transform: translateY(0);
    }

    &:hover {
      &:after {
        transform: translateX(100%);
      }
    }
  }

  .listing-articles-meta {
    font-size: px-to-em($font-size-entity-title-mobile);
    margin-top: px-to-em(
      math.div($section-spacing-small-px, 2),
      $font-size-entity-title-mobile
    );

    &.spacing-small {
      padding-top: 0 !important;
    }
  }

  //alumin related fields
  $listing-articles-alumni-year-padding: 45px;
  $listing-articles-alumni-year-dimen: 32px;
  $listing-articles-alumni-year-font-size: 20px;

  .listing-articles-item--alumni-move {
    padding-left: px-to-em($listing-articles-alumni-year-padding);
  }

  .listing-articles-alumni-year {
    font-size: px-to-em(
      $listing-articles-alumni-year-font-size,
      $font-size-banner-mobile
    );
    width: px-to-em(
      $listing-articles-alumni-year-dimen,
      $listing-articles-alumni-year-font-size
    );
    height: px-to-em(
      $listing-articles-alumni-year-dimen,
      $listing-articles-alumni-year-font-size
    );
    margin-top: 0.2em;
  }

  .listing-articles-alumni-facts {
    font-size: px-to-em($font-size-entity-title-mobile);
    margin-top: px-to-em(
      math.div($section-spacing-small-px, 2),
      $font-size-entity-title-mobile
    );
  }

  .listing-articles-item--alumni-spotlight {
    .listing-articles-figure {
      max-width: px-to-em(95px, $font-size-banner-mobile);
    }
  }
}

.listing-articles-icon {
  &--headphones {
    @include ico-icon-before($icon-headphones);

    &:before {
      margin-right: 0.5em;
    }
  }
}

@include screen($bp-mobile-small) {
  .listing-articles-figure {
    text-align: center;
    max-width: none;
    margin-bottom: 0.5em;
    width: 100%;
  }

  .listing-articles-item--alumni-spotlight {
    .listing-articles-figure {
      max-width: none;
    }
  }
}
